import React from 'react';
import { ResponsiveBump } from '@nivo/bump';

// Year over year report
const YoY = ({ data, activeSerieIds }) => {
	return (
		<ResponsiveBump
			data={data}
			colors={{ scheme: 'spectral' }}
			lineWidth={3}
			activeLineWidth={6}
			inactiveLineWidth={3}
			inactiveOpacity={0.3}
			pointSize={10}
			activePointSize={16}
			inactivePointSize={0}
			pointColor={{ theme: 'background' }}
			pointBorderWidth={3}
			activePointBorderWidth={3}
			pointBorderColor={{ from: 'serie.color' }}
			// defaultActiveSerieIds={activeSerieIds || []} // this is not working correctly. Doesn't update when data changes
			endLabel={d => {
				// console.log(d);
				// return activeSerieIds.includes(d.id) ? d.id : '';
				return d.id;
			}}
			axisTop={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			axisBottom={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
			}}
			axisLeft={{
				tickSize: 5,
				tickPadding: 5,
				tickRotation: 0,
				format: d => Math.abs(d),
			}}
			margin={{ top: 40, right: 150, bottom: 40, left: 50 }}
		/>
	);
};

export default YoY;
