import Box from '@mui/material/Box';
import React from 'react';
import { addOption, getOptionFromAllById } from '../../../helpers/attributUtils';
import AttributeTabs from './AttributeTabs.';
import LinkAttributePanel from './LinkAttributePanel';
import NewAttributePanel from './NewAttributePanel';
import TabPanels from './TabPanels';

const Attributes = ({
	attributes,
	handleAttributeChange,
	handleAttributeBulkChange,
	handleAttributeOptionChange,
	saving,
}) => {
	const [selectedTab, setSelectedTab] = React.useState('new');
	const [activeAttribute, setActiveAttribute] = React.useState({});
	const currentTabRef = React.useRef(null);

	// set the active attribute when the selected tab changes
	React.useEffect(() => {
		var cancelled = false;

		if (cancelled) return;

		if (currentTabRef.current !== selectedTab) {
			currentTabRef.current = selectedTab;
			if (selectedTab === 'new') {
				setActiveAttribute({});
			} else {
				setActiveAttribute(attributes.find(h => h.uuid === selectedTab) || {});
			}
		}

		return () => (cancelled = true);
	}, [selectedTab, attributes]);

	// #region handlers
	const handleChangeTab = (_, attribute) => {
		setSelectedTab(attribute);
	};

	// add a new attribute
	const handleNewAttribute = attribute => {
		handleAttributeChange(attribute);
		setSelectedTab(attribute.uuid);
	};

	// remove an entire attribute
	const handleRemoveAttribute = attribute => {
		// soft delete the attribute
		const deletedAttribute = {
			...attribute,
			deleted: true,
		};
		handleAttributeChange(deletedAttribute, 'delete');
		setActiveAttribute(deletedAttribute);
	};

	// restore a deleted attribute
	const handleRestoreAttribute = attribute => {
		// soft delete the attribute
		const restoredAttribute = {
			...attribute,
			deleted: false,
		};
		handleAttributeChange(restoredAttribute, 'restore');
		setActiveAttribute(restoredAttribute);
	};

	// reorder attributes
	const handleReorderAttributes = newOrder => {
		if (!newOrder) return;
		handleAttributeBulkChange(newOrder);
	};

	// add a new option to an existing attribute
	const handleAddOption = option => {
		const newOption = addOption(option);

		handleAttributeOptionChange(activeAttribute.uuid, newOption);
		setActiveAttribute({
			...activeAttribute,
			options: [newOption, ...(activeAttribute.options || [])],
		});
	};

	// remove an option from an existing attribute
	const handleRemoveOption = option => {
		// soft delete the attribute
		handleToggleSoftDelete(option, true);
	};

	// restore a deleted option
	const handleRestoreOption = option => {
		handleToggleSoftDelete(option, false);
	};

	const handleToggleSoftDelete = (option, deleted) => {
		const updateddAttribute = {
			...option,
			deleted: deleted,
		};
		handleAttributeOptionChange(activeAttribute.uuid, updateddAttribute, 'restore');
		setActiveAttribute({
			...activeAttribute,
			options: [
				...activeAttribute.options.filter(o => o.uuid !== option.uuid),
				updateddAttribute,
			],
		});
	};

	// handle linking attribute options (one to many)
	const handleLinkAttributes = (attributeId, link) => {
		const option = getOptionFromAllById(attributes, link.optionId);
		const updatedOption = {
			uuid: option.uuid,
			value: option.value,
			links: link.links || [],
		};

		handleAttributeOptionChange(attributeId, updatedOption, 'link');
	};

	// #endregion

	// console.log(attributes);
	return (
		<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
			<AttributeTabs
				selectedTab={selectedTab}
				handleChangeTab={handleChangeTab}
				attributes={attributes}
				activeAttribute={activeAttribute}
				handleReorderAttributes={handleReorderAttributes}
			/>
			<NewAttributePanel
				selectedTab={selectedTab}
				attributes={attributes}
				handleNewAttribute={handleNewAttribute}
			/>
			<LinkAttributePanel
				selectedTab={selectedTab}
				attributes={attributes}
				handleLinkAttributes={handleLinkAttributes}
				saving={saving}
			/>
			<TabPanels
				activeAttribute={activeAttribute}
				selectedTab={selectedTab}
				handleRemoveAttribute={handleRemoveAttribute}
				handleRestoreAttribute={handleRestoreAttribute}
				handleAddOption={handleAddOption}
				handleRemoveOption={handleRemoveOption}
				handleRestoreOption={handleRestoreOption}
			/>
		</Box>
	);
};

export default Attributes;
