import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Node from './Node';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';
// import { RefreshOutlined } from '@mui/icons-material';

const TabPanel = ({ children, value, current, ...other }) => (
	<div
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		{...other}
	>
		{value === current && (
			<Box sx={{ p: 3 }}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</div>
);

const TabPanels = ({
	hierarchies,
	attributes,
	selectedTab,
	handleAddNode,
	handleRemoveNode,
	activeHierarchy,
	handleRemoveHierarchy,
	// handleResetNodeTree,
}) => {
	const [showNewNodeControls, setShowNewNodeControls] = React.useState('');
	const [confirmDeleteHierarchy, setConfirmDeleteHierarchy] = React.useState(false);

	const ConfirmDeleteHierarchy = () => (
		<Dialog open={confirmDeleteHierarchy} onClose={() => setConfirmDeleteHierarchy(false)}>
			<DialogTitle color='error'>
				<strong>Confirm</strong>
			</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to proceed with deleting this hierarchy? This will delete
					the hierarchy and ALL of its nodes. Are you sure you're not wanting to delete a
					node instead?
				</DialogContentText>
				<DialogContentText color='error'>
					Deleting Hierarchy: {activeHierarchy.name}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setConfirmDeleteHierarchy(false)}>Cancel</Button>
				<Button
					onClick={() => {
						handleRemoveHierarchy(activeHierarchy);
						setConfirmDeleteHierarchy(false);
					}}
				>
					Delete Hiearchy
				</Button>
			</DialogActions>
		</Dialog>
	);

	const readonly = activeHierarchy.deleted;

	return (
		<>
			{confirmDeleteHierarchy && <ConfirmDeleteHierarchy open={confirmDeleteHierarchy} />}
			{hierarchies.map(h => (
				<TabPanel key={h.uuid} value={h.uuid} current={selectedTab}>
					<Box display='flex' justifyContent='flex-start' alignItems='center' p={1}>
						<Typography variant='h6'>{convertCamelCaseToTitleCase(h.name)}</Typography>
						<Box component='span' display={readonly ? 'none' : 'block'}>
							<RemoveIcon
								sx={{ cursor: 'pointer' }}
								onClick={() => setConfirmDeleteHierarchy(true)}
							/>
						</Box>
						{/* <Box component='span' display={readonly ? 'none' : 'block'}>
							<RefreshOutlined
								sx={{ cursor: 'pointer' }}
								onClick={handleResetNodeTree}
							/>
						</Box> */}
						{readonly && (
							<Typography variant='caption' color='error' mx={1}>
								{' '}
								Deleted - This hierarchy is in a read-only state as it was deleted.{' '}
							</Typography>
						)}
					</Box>
					{h.nodes
						.filter(n => n.left > -1)
						.map((n, i, nodes) => {
							return (
								<Node
									tree={nodes}
									node={n}
									index={i}
									handleAddNode={handleAddNode}
									showNewNodeControls={showNewNodeControls}
									setShowNewNodeControls={setShowNewNodeControls}
									handleRemoveNode={handleRemoveNode}
									activeHierarchy={activeHierarchy}
									attributes={
										(
											attributes.find(
												attr => attr.uuid === h.linkedAttributeKeyId
											) || {}
										).options || []
									}
								/>
							);
						})}
				</TabPanel>
			))}
		</>
	);
};

export default TabPanels;
