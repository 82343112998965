// Modified Preorder Tree Traversal (Nested set model) javascript implementation
// Utils for managing a MPTT tree structure
// http://en.wikipedia.org/wiki/Tree_traversal
// http://en.wikipedia.org/wiki/MPTT

import { v4 as uuidv4 } from 'uuid';
import { getOptionFromAllById } from './attributUtils';

// object structure
// {
// 	uuid: 'uuid',
//  value: 'value',
// 	left: number,
// 	right: number,
// }

// node data structure
// {
//     uuid: uuidv4(),
//     value: 'Child 2.1',
//     left: 9,
//     right: 10,
// },

// #region Local functions
// filter out deleted nodes
const filterDeletedNodes = n => n.left > 0;

// recursive function to update bounds of all nodes after a new node is inserted. sort first
const updateBounds = (tree, node, offset) => {
	// update bounds of all children
	tree = sorted(tree)
		.filter(n => !n.deleted)
		.map(n => {
			if (n.left >= node.left) {
				n.left += offset;
			}
			if (n.right >= node.left) {
				n.right += offset;
			}
			return n;
		});

	return tree;
};

// recursive function to move a node to a new parent
const moveNode = (tree, node, newParent) => {
	// if the node is a leaf node, move it
	if (node.left + 1 === node.right) {
		// update bounds of all children
		tree = updateBounds(tree, node, -2);
		tree = updateBounds(tree, newParent, 2);

		// move node
		node.left = newParent.left + 1;
		node.right = newParent.left + 2;

		return tree;
	}

	// find the first child of the node
	for (let i = 0; i < tree.length; i++) {
		if (tree[i].left === node.left + 1) {
			return moveNode(tree, tree[i], newParent);
		}
	}
};

// #endregion

// sort tree by left value
export const sorted = tree => {
	return tree.sort((a, b) => a.left - b.left);
};

// insert a new node into the tree
// could be;
// 1. a new root node
// 2. the first child of a parent node
// 3. a sibling of a parent node
// This is sensitive as fuck so be careful when modifying
// As of 2023-02-27 this is working as expected
export const insert = (tree, node, parent) => {
	// if no parent, insert as root node
	if (!parent) {
		// update bounds of all children
		tree = updateBounds(tree, node, 2);

		// insert node
		node.left = 1;
		node.right = 2;
		node.uuid = uuidv4();
		tree.push(node);
	} else {
		// check if first child
		const firstChild = parent.left + 1 === parent.right;
		if (firstChild) {
			const myRight = parent.right;
			const affectedRightNodes = tree.filter(n => n.right > myRight);
			const affectedLeftNodes = tree.filter(n => n.left > myRight);

			if (affectedRightNodes.length > 0) {
				affectedRightNodes.forEach(n => {
					n.right += 2;
				});
			}

			if (affectedLeftNodes.length > 0) {
				affectedLeftNodes.forEach(n => {
					n.left += 2;
				});
			}

			// update bounds of parent
			parent.right += 2;

			// insert node
			node.left = myRight;
			node.right = myRight + 1;
			node.uuid = uuidv4();
			tree.push(node);
		} else {
			const myLeft = parent.left;

			const affectedRightNodes = tree.filter(n => n.right > myLeft);
			const affectedLeftNodes = tree.filter(n => n.left > myLeft);

			if (affectedRightNodes.length > 0) {
				affectedRightNodes.forEach(n => {
					n.right += 2;
				});
			}

			if (affectedLeftNodes.length > 0) {
				affectedLeftNodes.forEach(n => {
					n.left += 2;
				});
			}

			// insert node
			node.left = myLeft + 1;
			node.right = myLeft + 2;
			node.uuid = uuidv4();
			tree.push(node);
		}
	}

	return sorted(tree);
};

// soft delete node and adjust bounds accordingly
export const deleteNode = (tree, nodeId) => {
	// find node by id
	const node = findNode(tree, nodeId);
	const myLeft = node.left;
	const myRight = node.right;
	const myWidth = myRight - myLeft + 1;

	// update bounds of all children
	tree = updateBounds(tree, node, -myWidth);

	// delete node
	node.left = -1;
	node.right = -1;

	return tree;
};

// delete a node from the tree
export const remove = (tree, node) => {
	// find the node
	for (let i = 0; i < tree.length; i++) {
		if (tree[i].uuid === node.uuid) {
			return deleteNode(tree, tree[i]);
		}
	}
};

// move a node to a new parent
export const move = (tree, node, newParent) => {
	// find the node
	for (let i = 0; i < tree.length; i++) {
		if (tree[i].uuid === node.uuid) {
			return moveNode(tree, tree[i], newParent);
		}
	}
};

// find depth of a node
export const depth = (tree, node) => {
	try {
		let depth = 0;

		// find the node
		for (let i = 0; i < tree.length; i++) {
			if (tree[i].uuid === node.uuid) {
				// find the parent
				for (let j = 0; j < tree.length; j++) {
					if (tree[j].left < tree[i].left && tree[j].right > tree[i].right) {
						depth++;
					}
				}
			}
		}

		return depth;
	} catch (error) {
		console.log(error);
		return 0;
	}
};

// find the parent of a node
export const findParent = (tree, node) => {
	// find node
	return tree.find(n => n.uuid === node.uuid);
};

// find width of a node
export const width = node => {
	return node.right - node.left + 1;
};

export const findNode = (tree, uuid) => {
	try {
		return tree.filter(filterDeletedNodes).find(n => n.uuid === uuid);
	} catch (error) {
		console.log(error);
		return null;
	}
};

export const findNodeChildren = (tree, parentId) => {
	try {
		// find node
		const parent = tree.filter(filterDeletedNodes).find(n => n.uuid === parentId);
		const children = tree.filter(n => n.left > parent.left && n.right < parent.right);
		return children || [];
	} catch (error) {
		console.log(error);
		return [];
	}
};

export const isChildOf = (tree, parentId, childId) => {
	try {
		if (parentId === childId) return true; // this is to prevent access, but a node should be able to access itself
		const parent = findNodeChildren(tree, parentId);
		// console.log(parent);
		const child = findNode(tree, childId);
		// console.log(child);
		return parent.findIndex(p => p.uuid === child.uuid) > -1;
	} catch (error) {
		console.log(error);
	}
};

// similar to above, but performs a deeper search
export const isChildOfDeepCheck = (nodes, attributes, key, filter) => {
	try {
		const node = findNodeFromAttributeOption(nodes, filter.nodeOptionId);
		if (!node) {
			const nodeOption = getOptionFromAllById(attributes, filter.nodeOptionId);
			if (nodeOption && nodeOption.links) {
				for (const id of nodeOption.links) {
					const linkedOptionNode = findNodeFromAttributeOption(nodes, id);
					const isChild = isChildOf(nodes, linkedOptionNode.uuid, key);
					if (isChild) return true;
				}
				return false;
			} else {
				// no linked attributes, last attempt is to try and find a survey tag with the hier node id
				return true; // for now, assuming its an hierarchy report and not an individual report
			}
		}
		return isChildOf(nodes, node.uuid, key);
	} catch (e) {
		console.log(e);
		return false;
	}

	// TODO: this is a duplicate of the function in other wrappers, need to move this to a utils file
	// const checkIsChildOfSelectedOrg = (nodes, attributes, key) => {
	// 	try {
	// 		const { leader, org } = filters;
	// 		const filterToUse = leader?.nodeOptionId ? leader : org;
	// 		const node = findNodeFromAttributeOption(nodes, filterToUse.nodeOptionId);
	// 		// const orgNode = findNodeFromAttributeOption(nodes, leader.nodeOptionId);
	// 		if (!node) {
	// 			const orgOption = getOptionFromAllById(attributes, filterToUse.nodeOptionId);
	// 			if (orgOption && orgOption.links) {
	// 				for (const id of orgOption.links) {
	// 					const linkedOrgNode = findNodeFromAttributeOption(nodes, id);
	// 					const isChild = isChildOf(nodes, linkedOrgNode.uuid, key);
	// 					if (isChild) return true;
	// 				}
	// 				return false;
	// 			} else {
	// 				// no linked attributes, last attempt is to try and find a survey tag with the hier node id
	// 				return true; // for now, assuming its an hierarchy report and not an individual report
	// 			}
	// 		}
	// 		return isChildOf(nodes, node.uuid, key);
	// 	} catch (e) {
	// 		console.log(e);
	// 		return false;
	// 	}
	// };
};
// find the path of a node (from node to root) Example SQL query below
export const findPath = (tree, node) => {
	try {
		return tree.filter(t => node.left >= t.left && node.left <= t.right && node.left > 0);
	} catch (error) {
		console.log(error);
	}
};

// find path and uuids of the path stringified
export const findPathString = (tree, node) => {
	try {
		// find node path
		const path = tree.filter(t => node.left > 0 && node.left >= t.left && node.left <= t.right);
		const pathString = path
			.filter(a => a.attributeOptionId) // filter out root node
			.sort((a, b) => a.left - b.left)
			.map(p => p.uuid)
			.join('>');
		return pathString;
	} catch (error) {
		console(error);
		return null;
	}
};

// find attribute option uuid from path string (the last uuid in the path), and return the attribute option uuid, not the hierarchy node uuid
export const findAOIdFromPathString = (hierarchies, pathString) => {
	try {
		const nodeId = pathString.split('>').pop();
		const hierarchy = getHierarchyFromNodeId(hierarchies, nodeId);
		const optionId = findNode(hierarchy.nodes, nodeId).attributeOptionId || nodeId;

		return optionId;
	} catch (error) {
		console.log(error);
		return null;
	}
};

// find all children of a node, including the parent)
export const findSubTree = (tree, parentId) => {
	// find node
	const parent = tree.find(n => n.uuid === parentId);
	const nodeTree = tree.filter(n => n.left >= parent.left && n.right <= parent.right);
	return nodeTree;
};

// find only the leaf nodes of a node, (i.e. only nodes without children)
export const findLeafNodes = (tree, parentId) => {
	// find node
	const parent = tree.find(n => n.uuid === parentId);
	const nodeChildren = tree.filter(
		n => n.left > parent.left && n.right < parent.right && n.left + 1 === n.right
	);
	return nodeChildren;
};

// find attribute associated with a tree
export const findNodeFromAttributeOption = (tree, optionId) => {
	try {
		// find node
		const node = tree.filter(filterDeletedNodes).find(n => n.attributeOptionId === optionId);
		return node;
	} catch (error) {
		console.log(error);
		return null;
	}
};

// get the hierarchy that a tree node belongs to
export const getHierarchyFromNodeId = (hierarchies, nodeId) => {
	try {
		// find node
		const hierarchy = hierarchies.find(h => {
			const node = h.nodes.find(n => n.uuid === nodeId);
			return node;
		});
		return hierarchy;
	} catch (error) {
		console.log(error);
		return null;
	}
};

// get hierarchy from Id
export const getHierarchyFromId = (hierarchies, hierarchyId) => {
	try {
		// find node
		const hierarchy = hierarchies.find(h => h.uuid === hierarchyId);
		return hierarchy;
	} catch (error) {
		console.log(error);
		return null;
	}
};
