import React from 'react';
import { useNavigate } from 'react-router-dom';

const QR = ({ code, color, bgColor }) => {
	const navigate = useNavigate();
	const url = `${window.location.origin}/survey/${code}`;
	// we dont want to re-generate the url on every render
	const qrGenUrl = React.useCallback(() => {
		const qrGenUrl = `https://api.qrserver.com/v1/create-qr-code/?color=${(
			color || '#000000'
		).replace('#', '')}&bgcolor=${(bgColor || '#FFFFFF').replace(
			'#',
			''
		)}&margin=10&size=100x100&data=${url}`;
		return qrGenUrl;
	}, [color, bgColor, url]);

	return React.useMemo(
		() => (
			<a
				onClick={() => navigate(`/survey/${code}`)}
				style={{
					cursor: 'pointer',
				}}
			>
				<img src={qrGenUrl()} alt='QR Code' />
			</a>
		),
		[qrGenUrl, navigate, code]
	);
};

export default QR;
