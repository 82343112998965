import React from 'react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import LeaderRollup from './servant-leadership/LeaderRollup';
import { ButtonGroup, Grid, Typography, IconButton } from '@mui/material';
import { convertCamelCaseToTitleCase } from '../../helpers/stringUtils';
import { ReportProvider, ReportContext } from '../../contexts/ReportContext';
import TenantBoxAndWhisker from './servant-leadership/TenantBoxAndWhisker';
import OrgRollup from './engagement/OrgRollup';
import DumbLoading from '../shared/DumbLoading';
import Counts from './servant-leadership/Counts';
import CommentWrapper from './wrappers/CommentWrapper';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@mui/icons-material';

const globallySupportedReports = {
	servantLeadership: {
		globalSurveyId: 'd354457e-a9e1-4e42-9d00-783707a96258',
		config: {
			deltaThreshold: 1.25,
			thresholdGroups: [
				{
					id: 'quad1',
					label: 'Needs Improvement (0 - 1.39)',
					color: '#4caf50',
					min: 0,
					max: 1.3999,
				},
				{
					id: 'quad2',
					label: 'Moderate (1.4 - 2.99)',
					color: '#ffeb3b',
					min: 1.4,
					max: 2.9999,
				},
				{
					id: 'quad3',
					label: 'Above Average (3 - 4.59)',
					color: '#ff9800',
					min: 3,
					max: 4.5999,
				},
				{
					id: 'quad4',
					label: 'Outstanding Servant Leader (4.6 - 5)',
					color: '#f44336',
					min: 4.6,
					max: 5,
				},
			],
			surveyTypes: {
				hourly: ['Hourly to Supervisor'],
				salary: [
					'Supervisor to Dept. Manager',
					'Supervisor to Manager',
					'Supervisor to Dept Manager',
					'Dept Manager to VSM',
				],
				self: ['Self Assessment'],
			},
		},
		reports: [
			{
				id: 'leaderRollup',
				label: 'Leader Rollup',
				component: LeaderRollup,
			},
			{
				id: 'Comments',
				label: 'Comments',
				component: CommentWrapper,
			},
			{
				id: 'Counts',
				label: 'Counts',
				component: Counts,
			},
			{
				id: 'boxAndWhisker',
				label: 'Box and Whisker',
				component: TenantBoxAndWhisker,
			},
		],
	},
	engagement: {
		globalSurveyId: 'c548eeff-55b5-4c04-bce2-c55e89c73911',
		config: {
			thresholdGroups: [
				{
					id: 'quad1',
					label: 'Disengaged (0 - 1.39)',
					color: '#4caf50',
					min: 0,
					max: 1.39,
				},
				{
					id: 'quad2',
					label: 'Under Engaged (1.4 - 2.99)',
					color: '#ffeb3b',
					min: 1.4,
					max: 2.99,
				},
				{
					id: 'quad3',
					label: 'Moderately Engaged (3 - 4.59)',
					color: '#ff9800',
					min: 3,
					max: 4.59,
				},
				{
					id: 'quad4',
					label: 'Highly Engaged (4.6 - 5)',
					color: '#f44336',
					min: 4.6,
					max: 5,
				},
			],
		},
		reports: [
			{
				id: 'orgRollup',
				label: 'Org Rollup',
				component: OrgRollup,
			},
			{
				id: 'Counts',
				label: 'Counts',
				component: Counts,
			},
		],
	},
};

const getRandomMessage = () => {
	// while loading, provide some random blurb about patience
	const blurbs = ['good things come to those who wait'];

	const randomIndex = Math.floor(Math.random() * blurbs.length);
	return `Loading report data... ${blurbs[randomIndex]}`;
};

const Reports = () => (
	<ReportProvider>
		<ConnectedReports />
	</ReportProvider>
);

const ConnectedReports = () => {
	const { config, groupedData, fetchResults, historicalData } = React.useContext(ReportContext);
	const { shared } = config || {};
	const { global, tenant } = shared || {};
	const { customerManaged } = tenant || {};
	const { surveys, attributes, hierarchies } = customerManaged || {};
	const allSurveys = [...(surveys || []), ...(global?.surveys ?? [])];

	const [selectedReportCat, setSelectedReportCat] = React.useState('servantLeadership');
	const [reportOptions, setReportOptions] = React.useState(
		globallySupportedReports[selectedReportCat]
	);
	const [selectedReport, setSelectedReport] = React.useState(null);
	const [fetching, setFetching] = React.useState(false);
	const printRef = React.useRef();

	const handleFetchResults = React.useCallback(async () => {
		setFetching(true);
		await fetchResults();
		setFetching(false);
	}, [fetchResults]);

	// handle printing
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={4} my={2} display='flex' justifyContent='center'></Grid>
				<Grid item xs={4} my={2} display='flex' justifyContent='center'>
					<Typography variant='h5'>Reports</Typography>
				</Grid>
				<Grid item xs={1}>
					<IconButton onClick={handlePrint} sx={{ displayPrint: 'none' }}>
						<Print />
					</IconButton>
				</Grid>
				<Grid item xs={3} my={2} display='flex' justifyContent='flex-end'>
					<Button
						onClick={handleFetchResults}
						variant='contained'
						size='small'
						disabled={fetching}
					>
						Refresh Data
					</Button>
				</Grid>
				{fetching ? (
					<Grid item xs={12} my={2} display='flex' justifyContent='center'>
						<DumbLoading text={getRandomMessage()} />
					</Grid>
				) : (
					groupedData && (
						<Container
							maxWidth={false}
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<ButtonGroup variant='contained' size='small'>
								{Object.keys(globallySupportedReports).map(cat => (
									<Button
										key={cat}
										onClick={() => {
											setSelectedReportCat(cat);
											setReportOptions(globallySupportedReports[cat]);
											setSelectedReport(null);
										}}
										sx={{
											backgroundColor:
												selectedReportCat === cat
													? 'primary.main'
													: 'primary.light',
										}}
									>
										{convertCamelCaseToTitleCase(cat)}
									</Button>
								))}
							</ButtonGroup>

							<ButtonGroup
								variant='contained'
								size='small'
								sx={{
									my: 2,
								}}
							>
								{((reportOptions || {}).reports || []).map(opt => {
									return (
										<Button
											key={opt.id}
											onClick={() => {
												setSelectedReport(opt);
											}}
											sx={{
												backgroundColor:
													selectedReport && selectedReport.id === opt.id
														? 'primary.main'
														: 'primary.light',
											}}
										>
											{convertCamelCaseToTitleCase(opt.label)}
										</Button>
									);
								})}
							</ButtonGroup>

							<Container ref={printRef} maxWidth={false}>
								{selectedReport &&
									React.createElement(selectedReport.component, {
										globalSurveyId: reportOptions.globalSurveyId,
										data: groupedData,
										historicalData,
										surveys: allSurveys,
										attributes,
										hierarchies,
										config: reportOptions.config,
									})}
							</Container>
						</Container>
					)
				)}
			</Grid>
		</>
	);
};

export default Reports;
