import React from 'react';
import YoY from '../servant-leadership/YoY';
import { Box, Container, Typography } from '@mui/material';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import { calcAvgFromCounts } from '../../../helpers/surveyUtils';

// Year over year wrapper
const YoYWrapper = ({ data, lookupData, label, activeSeries }) => {
	const [formattedData, setFormattedData] = React.useState([]);

	// Format the data (should align with the commented out data above)
	React.useEffect(() => {
		if (data) {
			const formattedData = [];
			data.forEach(d => {
				const id =
					getOptionFromAllById(lookupData?.attributes, d.tagValueId)?.value ||
					d.tagValueId;
				const index = formattedData.findIndex(fd => fd.id === id);
				if (index === -1) {
					formattedData.push({
						id: id,
						data: [
							{
								x: d.year,
								y: -calcAvgFromCounts(d.values), // inverting so up reflects an improvement
							},
						],
					});
				} else {
					formattedData[index].data.push({
						x: d.year,
						y: -calcAvgFromCounts(d.values), // inverting so up reflects an improvement
					});
				}
			});
			setFormattedData(formattedData);
		}
	}, [data, activeSeries]);

	const calcedHeight = formattedData.length * 35;
	const height = calcedHeight > 250 ? calcedHeight : 250;
	return (
		<Container maxWidth={false} disableGutters>
			<Typography variant='h6' align='center' py={2}>
				{label} by Year
			</Typography>
			<Box height={height} width={'100%'}>
				<YoY data={formattedData} activeSerieIds={activeSeries} />
			</Box>
		</Container>
	);
};

export default YoYWrapper;
