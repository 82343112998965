import { Refresh } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import React from 'react';
import { getOptionById } from '../../../helpers/attributUtils';
import { scrubBrackets } from '../../../helpers/stringUtils';
import { findNode } from '../../../helpers/treeUtils';
import CodePagination from './CodePagination';

const Codes = ({
	codeColumnIndex,
	columnCount,
	viewClaimed,
	viewPrinted,
	// readOnly,
	hierarchies,
	attributes,
	allSurveys,
	codesPerPage,
	pages,
	ticketHeight,
	fetchCodes,
	codes,
}) => {
	const [survey, setSurvey] = React.useState(null);
	const [tagFilters, setTagFilters] = React.useState([]);
	const [freeTextFilter, setFreeTextFilter] = React.useState('');
	const [filteredCodes, setFilteredCodes] = React.useState([]);
	const [loadingBackground, setLoadingBackground] = React.useState(false);

	// filter codes when tagFilters change
	React.useEffect(() => {
		const filterCodes = tagFilters => {
			const surveyId = survey ? survey.value : null;
			if (codes && surveyId && !codes[surveyId]) return;

			// if there is a tagfilter of 'Code: XXXXXXXX', then just filter on that, since its a unique code
			const codeFilter = tagFilters.find(t => t.startsWith('Code: ') && t.length === 14);
			if (codeFilter) {
				const filtered = codes[surveyId].filter(c => c.code === codeFilter.substring(6));
				setFilteredCodes(filtered);
				return;
			}

			const humanReadableTags = ((codes && codes[surveyId]) || []).map(c => {
				return {
					...c,
					tags: tagValues(c.tags),
				};
			});

			var filtered = humanReadableTags || [];
			// first, filter on showClaimed/showPrinted
			filtered = filtered
				.filter(t => (viewClaimed ? t.claimed : !t.claimed))
				.filter(t => (viewPrinted ? !!t.printedOn : !t.printedOn));

			filtered =
				filtered.filter(t =>
					tagFilters.every(v => t.tags.some(tag => tag.search.trim().includes(v)))
				) || [];

			setFilteredCodes(filtered);
		};

		filterCodes(tagFilters);
	}, [tagFilters, codes, viewClaimed, viewPrinted]);

	React.useEffect(() => {}, [survey]);

	const handleFetchCodes = async survey => {
		if (!survey) return;
		const surveyId = survey ? survey.value : null;
		setSurvey(survey);
		if (!codes || (codes && !codes[surveyId])) {
			setLoadingBackground(true);
			await fetchCodes(surveyId, 250);
			setLoadingBackground(false);
		} else {
			setFilteredCodes(codes[surveyId]);
		}
	};

	const tagValues = tags => {
		try {
			const search = tags.map(tag => {
				if (tag.key === 'surveyId') {
					const survey = allSurveys.find(s => s.uuid === tag.value);
					return {
						...tag,
						search: survey.metadata.name,
					};
				} else if (tag.hierarchyId) {
					const hierarchy = hierarchies.find(h => h.uuid === tag.hierarchyId);
					const node = findNode(hierarchy.nodes, tag.value);
					const search = `${scrubBrackets(hierarchy.name)}: ${node.value || 'N/A'}`;
					return { ...tag, search: search };
				} else if (tag.attributeId) {
					const attribute = attributes.find(a => a.uuid === tag.attributeId);
					const option = getOptionById(attribute, tag.value);
					const search = `${scrubBrackets(attribute.key)}: ${option.value || 'N/A'}`;
					return {
						...tag,
						search: search,
					};
				} else {
					return tag;
				}
			});
			return search;
		} catch (error) {
			console.log(error);
			return tags;
		}
	};

	const handleFreeTextFilterChange = value => {
		const surveyId = survey ? survey.value : null;
		if (!value) {
			setFreeTextFilter('');
			return setFilteredCodes(tagFilters.length ? filteredCodes : codes[surveyId] || []);
		}
		const codesToFilter = tagFilters.length ? filteredCodes : codes[surveyId] || [];
		// stringify tags, and if any of the string contents match any of the words in the free text filter, return true
		const filtered = codesToFilter.filter(c => {
			const tags = tagValues(c.tags);
			const tagString = tags.map(t => t.search).join(' ');
			const words = value.split(' ');
			return words.every(w => tagString.toLowerCase().includes(w.toLowerCase()));
		});

		setFreeTextFilter(value);
		setFilteredCodes(filtered);
	};

	const handleSetFiltersOnTagClick = tagValue => {
		const newTagFilters = [...tagFilters, tagValue];
		setTagFilters(newTagFilters);
	};

	const handleReset = async () => {
		setTagFilters([]);
		setFreeTextFilter('');
	};

	const surveyId = survey ? survey.value : null;

	if (codes) {
		const sc = codes[surveyId] || [];
		const printed = sc.filter(c => !!c.printedOn);
		// console.log(`codes: ${sc.length}, printed: ${printed.length}`);
	}
	return (
		<Grid item xs={12 / columnCount}>
			<Box display='flex' justifyContent='center'>
				<Box width='100%'>
					<Paper
						sx={{
							p: 2,
							height: '30vh',
							elevation: 3,
							mb: 2,
							overflowY: 'scroll',
							// hide scrollbar
							'&::-webkit-scrollbar': {
								display: 'none',
							},
							// hide on print
							'@media print': {
								display: 'none',
							},
						}}
					>
						<Typography variant='h6' sx={{ pb: 1, textAlign: 'center' }}>
							Code Column {codeColumnIndex + 1}
							<IconButton onClick={handleReset}>
								<Refresh />
							</IconButton>
						</Typography>
						<Autocomplete
							label='Survey'
							options={(allSurveys || []).map(s => {
								return {
									value: s.uuid,
									label: s.metadata.name,
								};
							})}
							value={survey}
							onChange={(_, option) => {
								handleFetchCodes(option);
							}}
							size='small'
							renderInput={params => (
								<TextField
									{...params}
									label='Survey'
									variant='outlined'
									size='small'
								/>
							)}
							disabled={loadingBackground}
							sx={{ pb: 1 }}
						/>
						<TextField
							variant='outlined'
							size='small'
							fullWidth
							value={freeTextFilter}
							onChange={e => handleFreeTextFilterChange(e.target.value)}
							placeholder='Filter this column of codes'
							disabled={loadingBackground || !survey}
						/>
						<Box
							display='flex'
							justifyContent='center'
							alignItems='center'
							flexWrap='wrap'
							pt={1}
						>
							{tagFilters.map((tf, index) => {
								return (
									<Chip
										key={index}
										label={tf}
										onDelete={() => {
											const newTagFilters = [...tagFilters];
											newTagFilters.splice(index, 1);
											setTagFilters(newTagFilters);
										}}
										sx={{ m: 0.5 }}
									/>
								);
							})}
						</Box>
						{!loadingBackground && surveyId && codes && codes[surveyId] && (
							<Typography variant='subtitle1' align='center'>
								{`Filtered ${filteredCodes?.length} of ${
									codes[surveyId]?.length || 0
								} codes`}
							</Typography>
						)}
						{loadingBackground && (
							<Typography variant='subtitle2' align='center'>
								<Box>
									<em>Loading codes...</em>
								</Box>
								<Box mt={1}>
									<CircularProgress size={20} />
								</Box>
							</Typography>
						)}
					</Paper>
					<Box display='flex' flexDirection='column'>
						<CodePagination
							filteredCodes={filteredCodes || []}
							hierarchies={hierarchies}
							attributes={attributes}
							allSurveys={allSurveys}
							handleSetFiltersOnTagClick={handleSetFiltersOnTagClick}
							ticketHeight={ticketHeight}
							codesPerPage={codesPerPage}
							pages={pages}
							loadingBackground={loadingBackground}
						/>
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};

export default Codes;
