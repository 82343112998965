// React Context that is shared between all components

import React, { createContext, useState } from 'react';
import { callApiGWLambdaGet, callApiGWLambda } from '../apis/api-gw';
import { supportedQuestionTypes } from './supportedQuestionTypes';
import { parseCodeResponse } from '../helpers/lambdaResponseUtils';

export const SharedContext = createContext();

export const SharedProvider = props => {
    const [shared, setShared] = useState({
        codes: undefined,
        tenant: undefined,
        global: undefined,
    });
    const [codes, setCodes] = useState({});
    const [tenant, setTenant] = useState(undefined);
    const [global, setGlobal] = useState({
        supportedQuestionTypes: supportedQuestionTypes,
        questionVars: undefined,
        surveys: undefined,
    });

    // load certain admin/tenant config into shared context
    React.useEffect(() => {
        let canceled = false;

        callApiGWLambdaGet('config')
            .then(response => {
                if (response.statusCode !== 200) {
                    if (response.statusCode === 425) return; // 425 = Too Early, experimental - https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/425
                    if (!canceled) setTenant({ attributes: [], hierarchies: [] });
                    return;
                }

                const data = JSON.parse(response.body);
                if (!canceled) setTenant(data);
            })
            .catch(err => {
                console.log(err);
                if (!canceled) setTenant({ attributes: [], hierarchies: [] });
                return;
            });

        return () => (canceled = true);
    }, []);

    // load codes into shared context
    const fetchCodes = React.useCallback(async (surveyId, batchSize = 500) => {
        if (!surveyId) return;
        const subdomain = window.location.hostname.split('.')[0];

        // changed to post request to pass body in instead of trying to parse json into a properly formatted query string
        const getSurveyData = async (surveyId, lastEvaluatedKey, batchSize, retryAttempts = 0, data = []) => {
            try {
                const queryBody = {
                    groupId: subdomain,
                    surveyId: surveyId,
                    pageSize: batchSize,
                    lastEvaluatedKey,
                };
                const response = await callApiGWLambda('post', 'codes/pages', queryBody);

                const bodyJson = JSON.parse(response.data.body);
                if (bodyJson) {
                    const newData = bodyJson.filter(item => item.Tags).map(parseCodeResponse);
                    data.push(...newData);
                    const newLastEvaluatedKey = response.data.headers.LastEvaluatedKey;

                    if (newLastEvaluatedKey) {
                        const parsedHeader = JSON.parse(newLastEvaluatedKey);

                        if (Object.keys(parsedHeader).length > 0) {
                            const newQueryBody = {
                                groupId: subdomain,
                                surveyId: surveyId,
                                pageSize: batchSize,
                                lastEvaluatedKey: JSON.stringify({
                                    SurveyId: { S: parsedHeader.SurveyId['s'] },
                                    Id: { S: parsedHeader.Id['s'] },
                                }),
                            };

                            await getSurveyData(
                                surveyId,
                                newQueryBody.lastEvaluatedKey,
                                batchSize,
                                retryAttempts,
                                data
                            );
                        } else {
                            return Promise.resolve(data);
                        }
                    } else {
                        return Promise.resolve(data);
                    }
                }
                return Promise.resolve(data);
            } catch (error) {
                console.log(error);
                console.log(`Error Record. SurveyId: ${surveyId} - LastEvaluatedKey: ${lastEvaluatedKey}`);
            }
        };

        const data = await getSurveyData(surveyId, null, batchSize, null, []);
        setCodes({ [surveyId]: data });
        return Promise.resolve(data);
    }, []);

    // set codes as printed in shared context
    const setCodesPrinted = React.useCallback(
        async printedCodes => {
            const updateCodes = async codesToMark => {
                const subdomain = window.location.hostname.split('.')[0];
                const final = {
                    groupId: subdomain,
                    codes: codesToMark,
                };
                const res = await callApiGWLambda('put', 'codes/print', final);

                if (res.status !== 200 || res.data.statusCode !== 200) {
                    console.log('Error updating codes');
                    return;
                }

                // if api call is successful, update codes in shared context
                const updatedCodes = {};
                for (const [key, value] of Object.entries(shared.codes)) {
                    // filter printedCodes array to only include codes for current survey
                    const codesForSurvey = printedCodes
                        .filter(code => code.split('|')[1] === key)
                        .map(code => code.split('|')[0]);

                    // if no codes for current survey, return original value
                    if (codesForSurvey.length === 0) {
                        updatedCodes[key] = value;
                        continue;
                    }

                    // otherwise, update codes for current survey
                    const updatedCodesForSurvey = value.map(code => {
                        return codesForSurvey.indexOf(code.id) > -1
                            ? { ...code, printedOn: new Date().toISOString() }
                            : code;
                    });
                    updatedCodes[key] = updatedCodesForSurvey;
                }

                return Promise.resolve(updatedCodes);
            };

            if (!printedCodes) return;
            const updatedCodes = await updateCodes(printedCodes);
            setCodes(updatedCodes);
        },
        [shared.codes]
    );

    // get global config
    React.useEffect(() => {
        let canceled = false;
        callApiGWLambdaGet('config', '', 'global')
            .then(response => {
                if (response.statusCode !== 200) {
                    if (response.statusCode === 425) return; // 425 = Too Early, experimental - https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/425
                    if (!canceled)
                        setGlobal({
                            ...global,
                            questionVars: [],
                            surveys: [],
                        });
                    return;
                }

                const data = JSON.parse(response.body);
                if (!canceled) setGlobal({ ...global, ...data.global });
            })
            .catch(err => {
                console.log(err);
                if (!canceled)
                    setGlobal({
                        ...global,
                        questionVars: [],
                        surveys: [],
                    });
                return;
            });

        return () => (canceled = true);
    }, []);

    // combine all into shared context
    React.useEffect(() => {
        let canceled = false;
        if (!canceled) {
            setShared({
                tenant,
                global,
            });
        }

        return () => (canceled = true);
    }, [tenant, global]);

    // handle codes a bit differently
    React.useEffect(() => {
        let canceled = false;
        if (!canceled) {
            const updatedCodes = shared.codes ? { ...shared.codes, ...codes } : codes;

            setShared({
                ...shared,
                codes: updatedCodes,
            });
        }
        return () => (canceled = true);
    }, [codes]);

    // console.log(shared);
    return (
        <SharedContext.Provider value={{ shared, setShared, fetchCodes, setCodesPrinted }}>
            {props.children}
        </SharedContext.Provider>
    );
};

// Path: src\contexts\SharedContext.js
// React Context that is shared between all components
