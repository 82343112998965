import React from 'react';
import { calcAvgFromCounts } from '../../../helpers/surveyUtils';
import { findAOIdFromPathString } from '../../../helpers/treeUtils';
import CountsTable from '../generics/CountsTable';
import { Typography } from '@mui/material';

const tempCalcCountsFn = (responses, match) => {
	try {
		return responses.filter(r => r.response === match).length;
	} catch (error) {
		console.log(error);
		return -1;
	}
};

const tempSumCountsFn = counts => {
	try {
		return counts.reduce((a, b) => a + b, 0);
	} catch (error) {
		console.log(error);
		return 0;
	}
};

const tempCountFn = (submissions, sId, taId = '*', tvId = '*') => {
	try {
		const year = 2023;
		const tenant = 'vsto-ammo-cci';
		const surveyId = sId;
		return {
			Id: `${year}|${tenant}|${surveyId}|${taId}|${tvId}`,
			TenantId: tenant,
			SurveyId: surveyId,
			TagAttributeId: taId,
			TagValueId: tvId,
			Code: `CURRYEAR`,
			Year: 2023,
			Values: {
				Opt1s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 1))),
				Opt1Weight: 1,
				Opt2s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 2))),
				Opt2Weight: 2,
				Opt3s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 3))),
				Opt3Weight: 3,
				Opt4s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 4))),
				Opt4Weight: 4,
				Opt5s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 5))),
				Opt5Weight: 5,
				Opt6s: tempSumCountsFn(submissions.map(s => tempCalcCountsFn(s.responses, 'NONE'))),
				Opt6Weight: 6,
			},
		};
	} catch (error) {
		console.log(error);
		return [];
	}
};

const Counts = ({ globalSurveyId, data, historicalData, surveys, attributes, hierarchies }) => {
	const [formattedData, setFormattedData] = React.useState([]);

	// #region function for generating historical count data for existing surveys.
	// This can be used when necessary, and the output can be copied over to the
	// Python script for sending the data to sqs.
	const manuallyGenerateFromRawData = React.useCallback(() => {
		if (data) {
			// 1. find the surveyId, and use only the data for that survey
			// 2. loop through each whitelisted key in the survey data object
			// 3. for each key, send the responses to the helper function to get the counts
			// 4. add the counts to the formattedData array

			const surveyId = surveys.find(s => s.uuid === globalSurveyId)?.uuid;
			const surveyData = data[surveyId];
			const include = {
				year: true,
				shift: true,
				leader: false,
				surveyType: false,
				organization: true,
			};
			const fd = [];

			if (include.year) {
				// year
				const yearData = surveyData?.year['2023'];
				const yearCounts = tempCountFn(yearData, globalSurveyId);
				const yearAvg = calcAvgFromCounts(yearCounts.values); // just for testing
				console.log(yearCounts);
				fd.push(yearCounts);
			}

			if (include.shift) {
				// shift
				const shiftData = surveyData?.shift;
				const shiftCounts = Object.keys(shiftData).map(shift => {
					const shiftData = surveyData?.shift[shift];
					const shiftCounts = tempCountFn(
						shiftData,
						globalSurveyId,
						'70da249e-3840-498a-bcf0-d9813609f307',
						shift
					);
					return shiftCounts;
				});
				console.log(shiftCounts);
				fd.push(...shiftCounts);
			}

			if (include.leader) {
				// leader (this is a hierarchy, so its a bit different)
				const leaderData = surveyData?.leader;
				const leaderCounts = Object.entries(leaderData).map(([leader, leaderData]) => {
					const leaderId = findAOIdFromPathString(hierarchies, leader);
					const leaderCounts = tempCountFn(
						leaderData,
						globalSurveyId,
						'95912442-8d09-4c23-a1c8-50a1f26c4c82',
						leaderId
					);
					return leaderCounts;
				});
				console.log(leaderCounts);
				fd.push(...leaderCounts);
			}

			if (include.surveyType) {
				// survey type
				const surveyTypeData = surveyData?.surveyType;
				const surveyTypeCounts = Object.entries(surveyTypeData).map(
					([surveyType, surveyTypeData]) => {
						const surveyTypeCounts = tempCountFn(
							surveyTypeData,
							globalSurveyId,
							'a86148bb-9ff5-4626-ab41-a3f0067210af',
							surveyType
						);
						return surveyTypeCounts;
					}
				);
				console.log(surveyTypeCounts);
				fd.push(...surveyTypeCounts);
			}

			if (include.organization) {
				// org (same logic as leader)
				const orgData = surveyData?.organization;
				const orgCounts = Object.entries(orgData).map(([org, orgData]) => {
					const orgId = findAOIdFromPathString(hierarchies, org);
					const orgCounts = tempCountFn(
						orgData,
						globalSurveyId,
						'b28b11e6-1c07-48e2-a9d7-e85514ae9d58',
						orgId
					);
					return orgCounts;
				});
				console.log(orgCounts);
				fd.push(...orgCounts);
			}

			console.log(fd);
			setFormattedData(fd);
		}
	}, [data]);
	// #endregion

	React.useEffect(() => {
		manuallyGenerateFromRawData();
	}, [manuallyGenerateFromRawData]);

	React.useEffect(() => {
		if (historicalData) {
			const fd = historicalData
				.filter(hd => hd.surveyId === globalSurveyId)
				.map(hd => {
					const survey = surveys.find(s => s.uuid === hd.surveyId);
					const attribute = attributes.find(a => a.uuid === hd.tagAttributeId);
					const option = attribute?.options.find(o => o.uuid === hd.tagValueId);
					return {
						id: hd.id,
						surveyName: survey?.metadata.name,
						tagGroup: attribute?.key,
						tagValue: option?.value || hd.tagValueId,
						year: hd.year,
						opt1s: hd.values.opt1s,
						opt1sWeighted: hd.values.opt1s * hd.values.opt1Weight,
						opt2s: hd.values.opt2s,
						opt2sWeighted: hd.values.opt2s * hd.values.opt2Weight,
						opt3s: hd.values.opt3s,
						opt3sWeighted: hd.values.opt3s * hd.values.opt3Weight,
						opt4s: hd.values.opt4s,
						opt4sWeighted: hd.values.opt4s * hd.values.opt4Weight,
						opt5s: hd.values.opt5s,
						opt5sWeighted: hd.values.opt5s * hd.values.opt5Weight,
						opt6s: hd.values.opt6s,
						opt6sWeighted: hd.values.opt6s * hd.values.opt6Weight,
						avg: calcAvgFromCounts(hd.values),
					};
				});

			const sorted = fd.sort((a, b) => {
				if (a.surveyName != b.surveyName) {
					return a.surveyName.localeCompare(b.surveyName);
				} else if (a?.tagGroup != b?.tagGroup) {
					return a?.tagGroup?.localeCompare(b?.tagGroup);
				} else if (a?.tagValue != b?.tagValue) {
					return a?.tagValue.localeCompare(b?.tagValue);
				} else {
					return a.year - b.year;
				}
			});

			setFormattedData(sorted);
		}
	}, [historicalData]);

	return (
		<div>
			<Typography variant='h4'>Raw Count History</Typography>
			<CountsTable data={formattedData} />
		</div>
	);
};

export default Counts;
