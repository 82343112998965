import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import React from 'react';

const Responses = ({ seenQuestions, errors, onJumpToIndex }) => (
	<List sx={{ display: 'flex', flexDirection: 'column-reverse', m: 0, p: 0 }}>
		{seenQuestions.map((question, index) => {
			const isError = errors.indexOf(question.id) !== -1;
			const qanda = `${index + 1}) ${question.questionText} - ${
				question.response || 'no response'
			}`;
			return (
				<ListItem
					key={index}
					sx={{
						flex: 'item',
						m: 0,
						p: 0,
						color: isError ? 'error.main' : 'inherit',
						textDecoration: isError ? 'underline' : 'none',
						cursor: isError ? 'pointer' : 'default',
					}}
				>
					{isError ? (
						<span onClick={() => onJumpToIndex(index)}>{qanda}</span>
					) : (
						<span>{qanda}</span>
					)}
				</ListItem>
			);
		})}
	</List>
);

export default Responses;
