import { Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import QR from './QR';
import Tags from './Tags';

// claimed Watermark
const Watermark = ({ text, color }) => (
	<Box
		id='claimed'
		position='absolute'
		fontSize='4rem'
		letterSpacing='1rem'
		color={color || 'error.main'}
		sx={{
			opacity: 0.2,
			zIndex: 100,
		}}
	>
		{text}
	</Box>
);

const Code = ({
	code,
	hierarchies,
	attributes,
	surveys,
	preview,
	handleSetFiltersOnTagClick,
	loadingBackground,
}) => {
	const { id, tags, color, bgColor, claimed, printedOn, surveyId, ...restCode } = code;

	const fakeCodeId = preview ? Math.random().toString(36).substr(2, 8).toUpperCase() : null;

	return (
		<>
			{claimed && <Watermark text='CLAIMED' color='error.main' />}
			{preview && <Watermark text='Preview  !' color='secondary.main' />}
			<Box id='left' flex={`5 0 ${50 / tags.length}%`} fontSize='.8rem'>
				<Tags
					tags={tags}
					surveys={surveys}
					attributes={attributes}
					hierarchies={hierarchies}
					handleSetFiltersOnTagClick={handleSetFiltersOnTagClick}
				/>
			</Box>

			<Box
				id='middle'
				flex='1 0 20%'
				fontSize='1.4rem'
				sx={{
					cursor: 'pointer',
				}}
			>
				{preview ? (
					fakeCodeId
				) : (
					<>
						<a
							surveyId={surveyId}
							onClick={() => handleSetFiltersOnTagClick(`Code: ${code.id}`)}
						>
							{code.id}
						</a>
					</>
				)}
			</Box>
			<Box id='right' flex='1 0 20%' display='flex'>
				{React.useMemo(
					() =>
						!loadingBackground && (
							<QR
								code={code.id}
								color={!claimed ? color : 'inheit'}
								bgColor={!claimed ? bgColor : 'inheit'}
							/>
						),
					[loadingBackground]
				)}
			</Box>

			{printedOn && (
				<Box
					sx={{
						fontSize: '.8rem',
						position: 'absolute',
						mt: '7rem',
						zIndex: 100,
						displayPrint: 'none',
					}}
				>
					<Tooltip title='Re-printing will expose the risk of someone receiving a duplicate physical code.'>
						<Typography variant='subtitle2' color='error.light'>
							This code was printed on {new Date(printedOn).toLocaleDateString()}
						</Typography>
					</Tooltip>
				</Box>
			)}
		</>
	);
};

export default Code;
