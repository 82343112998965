import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { depth, findNode, findNodeChildren, findParent } from '../../../helpers/treeUtils';

const Node = ({
	tree,
	node,
	index,
	attributes,
	handleAddNode,
	showNewNodeControls,
	setShowNewNodeControls,
	handleRemoveNode,
	activeHierarchy,
}) => {
	const [newNode, setNewNode] = React.useState({
		uuid: null,
		value: '',
		attributeOptionId: null,
	});
	const [confirmDeleteNode, setConfirmDeleteNode] = React.useState(false);

	// accept key down on Enter key as a submit
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			handleAddNode(newNode);
		}
	};
	const ConfirmDeleteNode = ({ nodeId }) => (
		<Dialog open={!!confirmDeleteNode} onClose={() => setConfirmDeleteNode(false)}>
			<DialogTitle>Confirm</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Are you sure you want to delete this node? If this node has children, they will
					be deleted as well.
				</DialogContentText>
				<DialogContentText color='error'>
					Deleting node: {findNode(activeHierarchy.nodes, nodeId).value}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setConfirmDeleteNode(false)}>Cancel</Button>
				<Button
					onClick={() => {
						handleRemoveNode(nodeId);
						setConfirmDeleteNode(false);
					}}
				>
					Delete Node
				</Button>
			</DialogActions>
		</Dialog>
	);

	const readonly = activeHierarchy.deleted;

	return (
		<Box
			key={node.uuid}
			display='flex'
			flexDirection='column'
			pl={`${depth(tree, node) * 2}rem`}
			sx={{ cursor: 'pointer' }}
		>
			{confirmDeleteNode && <ConfirmDeleteNode nodeId={confirmDeleteNode} />}
			<Box display='flex'>
				<Typography variant='subtitle2'>{node.value}</Typography>
				<Box component='span' display={readonly ? 'none' : 'block'}>
					{index !== 0 && <RemoveIcon onClick={() => setConfirmDeleteNode(node.uuid)} />}
					<AddIcon onClick={() => setShowNewNodeControls(node.uuid)} />
				</Box>
			</Box>
			<Box display='flex' justifyContent='flex-start' alignItems='center' p={1} width='40vw'>
				{tree.length > 0 && (
					<Autocomplete
						id={`new-${node.uuid}`}
						options={attributes
							.filter(o => !o.deleted)
							.sort((a, b) => a.value.localeCompare(b.value))
							.map(o => {
								return {
									value: o.value,
									attributeOptionId: o.uuid,
									parent: findParent(tree, node) || {},
								};
							})}
						filterOptions={(options, _) => {
							const children = findNodeChildren(tree, node.uuid).map(
								c => c.attributeOptionId
							);
							// TODO: check if attributeOptionId needs to replace o.attributeId
							return options.filter(o => children.indexOf(o.attributeId) === -1);
						}}
						getOptionLabel={option => option.value}
						renderInput={params => (
							<Box display='flex' m={0} p={0}>
								<TextField
									{...params}
									label={`Add node under ${node.value}`}
									variant='outlined'
									size='small'
								></TextField>
								<Button size='small' onClick={() => handleAddNode(newNode)}>
									Save
								</Button>
								<Button size='small' onClick={() => setShowNewNodeControls()}>
									Cancel
								</Button>
							</Box>
						)}
						onChange={(e, value) => {
							setNewNode({
								...newNode,
								...value,
							});
						}}
						onKeyDown={e => handleKeyDown(e, true)}
						fullWidth
						hidden={showNewNodeControls !== node.uuid}
					/>
				)}
			</Box>
		</Box>
	);
};

export default Node;
