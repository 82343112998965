import Box from '@mui/material/Box';
import React from 'react';
import { avgAllResponses } from '../../../helpers/surveyUtils';
// import { colorSchemes } from '@nivo/colors';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import HierarchyBar from '../generics/HierarchyBar';

const initConfig = {
	keys: ['attribute'], // keys to group by
	defs: [],
	fill: [],
	legends: [
		{
			dataFrom: 'keys',
			anchor: 'bottom-right',
			direction: 'column',
			justify: false,
			translateX: 120,
			translateY: 0,
			itemsSpacing: 2,
			itemWidth: 100,
			itemHeight: 20,
			itemDirection: 'left-to-right',
			itemOpacity: 0.85,
			symbolSize: 20,
			effects: [
				{
					on: 'hover',
					style: {
						itemOpacity: 1,
					},
				},
			],
		},
	],
	margins: { mt: 50, mr: 200, mb: 120, ml: 50 },
	indexBy: 'name',
	// groupMode: 'grouped', // 'stacked' or 'grouped'
};

const AttributeWrapper = ({ data, filters, lookupData, config: eConfig }) => {
	const [config] = React.useState({
		...initConfig,
		keys: eConfig?.thresholdGroups.map(group => {
			return group.label;
		}),
	});
	const [formattedData, setFormattedData] = React.useState([]);
	const [nodeMap, setNodeMap] = React.useState(new Map());
	// const colorScheme = colorSchemes.nivo;

	React.useEffect(() => {
		var map = new Map();
		Object.entries(data).forEach(([aoId, submissions]) => {
			if (!map.has(aoId)) {
				map.set(aoId, []);
			}
			map.get(aoId).push(...submissions);
		});

		setNodeMap(map);
	}, [data]);

	React.useEffect(() => {
		const { attributes } = lookupData;
		const { thresholdGroups } = eConfig || {};

		// now calc the avgs of all the submissions within each node in the map
		// also need to lookup the name of the node (key)
		const formattedData = [];
		nodeMap.forEach((submissions, key) => {
			const option = getOptionFromAllById(attributes, key);
			const name = option.value || 'n/a';
			const avg = avgAllResponses(submissions);

			// find which threshold group this avg belongs to
			const thresholdGroup = thresholdGroups.find(group => {
				const { min, max } = group;
				return avg >= min && avg <= max;
			});
			const color = thresholdGroup?.color || '#000000';

			formattedData.push({
				name: name,
				[thresholdGroup.label]: isNaN(avg) ? 0 : avg,
				[`${thresholdGroup.label}Color`]: color,
				sortValue: isNaN(avg) ? 0 : avg,
			});
		});

		// sort the data by avg
		const sorted = formattedData.sort((a, b) => b.sortValue - a.sortValue);
		setFormattedData(sorted);
	}, [nodeMap, filters, lookupData]);

	// console.log(formattedData);
	return (
		<Box height={'90%'} width={'100%'}>
			<HierarchyBar data={formattedData} config={config} />
		</Box>
	);
};

export default AttributeWrapper;
