import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import React from 'react';
import { getAttributeByKey, getOptionFromAllById } from '../../../helpers/attributUtils';
import { depth, findAOIdFromPathString } from '../../../helpers/treeUtils';
import HeatMapWrapper from '../wrappers/HeatMapWrapper';
import HierarchyBarWrapper from '../wrappers/HierarchyBarWrapper';
import Competencies from './Competencies';
import LeaderComments from './LeaderComments';
import LeaderDetailByQuestion from './LeaderDetailByQuestion';
import YoYWrapper from '../wrappers/YoYWrapper';
import { findDistinctTags } from '../../../helpers/surveyUtils';
import MarimekkoWrapper from '../wrappers/MarimekkoWrapper';

const initConfig = {
	defs: [],
	fill: [],
	id: 'leader',
	value: 'participation',
	// margins: { mt: 0, mr: 0, mb: 0, ml: 0 },
	// legends: [
	// 	{
	// 		anchor: 'top',
	// 		direction: 'row',
	// 		justify: false,
	// 		translateX: 0,
	// 		translateY: 0,
	// 		itemsSpacing: 0,
	// 		itemWidth: 140,
	// 		itemHeight: 18,
	// 		itemTextColor: '#999',
	// 		itemDirection: 'right-to-left',
	// 		itemOpacity: 1,
	// 		symbolSize: 18,
	// 		symbolShape: 'square',
	// 		effects: [
	// 			{
	// 				on: 'hover',
	// 				style: {
	// 					itemTextColor: '#000',
	// 				},
	// 			},
	// 		],
	// 	},
	// ],
};

const LeaderRollup = ({
	globalSurveyId,
	data,
	historicalData,
	surveys,
	attributes,
	hierarchies,
	config: slConfig,
}) => {
	const [slData, setSlData] = React.useState({});
	const [surveyHistory, setSurveyHistory] = React.useState([]);
	const [bumpChartCfg, setBumpChartCfg] = React.useState({});
	const [years, setYears] = React.useState([]);
	const [leaders, setLeaders] = React.useState([]);
	const [filters, setFilters] = React.useState({
		surveyId: null,
		years: [dayjs().year()],
		leader: null,
		hierarchy: 'leader',
		surveyTypes: { ...(slConfig?.surveyTypes ?? {}) },
	});
	const [visibility, setVisibility] = React.useState({
		showYoY: true,
		showLeaderByQuestion: true,
		showComments: true,
		showCompetencies: true,
		showLeaderRank: true,
		showLeaderHierarchyRank: true,
		showOrgRank: true,
		showOrgHierarchyRank: true,
		showBreakdownByParticipation: true,
	});

	React.useEffect(() => {
		if (surveys && data) {
			const filteredData = data[globalSurveyId] || [];
			setSlData(filteredData);
			setSurveyHistory(historicalData?.filter(d => d.surveyId === globalSurveyId) || []);
			setFilters({ ...filters, surveyId: globalSurveyId });
		}
	}, []);

	React.useEffect(() => {
		if (slData && slData.year) {
			const years = Object.keys(slData.year).map(year => parseInt(year));
			setYears(years);
		}
	}, [slData]);

	React.useEffect(() => {
		if (slData && slData.leader) {
			const leaderHier = hierarchies?.find(o => o.name === 'Leader');
			if (!leaderHier) return;

			let leaderList = Object.keys(slData.leader).flatMap(key => {
				const leaderId = findAOIdFromPathString(hierarchies, key);
				if (!leaderId) return [];

				return leaderHier?.nodes
					?.sort((a, z) => a.left - z.left)
					.reduce((accumulator, node) => {
						if (node.attributeOptionId === leaderId) {
							accumulator.push({
								value: key,
								label: node.value,
								leaderId: node.attributeOptionId, // eventually refactor to use nodeOptionId
								nodeOptionId: node.attributeOptionId,
								depth: depth(leaderHier.nodes, node),
							});
						}

						return accumulator;
					}, []);
			});

			const filtered = leaderList.filter(leader => leader !== null);
			const sorted = filtered.sort((a, b) =>
				(a?.label ?? 'a').localeCompare(b?.label ?? 'b')
			);
			setLeaders(sorted);
		}
	}, [slData]);

	React.useEffect(() => {
		const shiftAttributeId = getAttributeByKey(attributes, 'Shift')?.uuid;
		const orgAttributeId = getAttributeByKey(attributes, 'Organization')?.uuid;
		const leaderTagIds = findDistinctTags(slData.leader?.[filters?.leader?.value] ?? []);
		const leaderTags = leaderTagIds.map(id => getOptionFromAllById(attributes, id)?.value);
		const bumpChartActiveSerie = leaderTags.filter(Boolean);

		setBumpChartCfg({
			shiftAttributeId,
			orgAttributeId,
			bumpChartActiveSerie: [...bumpChartActiveSerie, '*'],
		});
	}, [filters]);

	const lookupData = {
		surveys,
		attributes,
		hierarchies,
	};

	const ToggleVisibilityButton = ({ visibilityKey, label }) => (
		<Button
			key={visibilityKey}
			onClick={() =>
				setVisibility({
					...visibility,
					[visibilityKey]: !visibility[visibilityKey],
				})
			}
			variant='outlined'
			fullWidth
			size='small'
			sx={{ my: 1, displayPrint: 'none' }}
		>
			{visibility[visibilityKey] ? `Hide ${label}` : `View ${label}`}
		</Button>
	);

	const {
		showYoY,
		showLeaderByQuestion,
		showComments,
		showCompetencies,
		showLeaderRank,
		showLeaderHierarchyRank,
		showOrgRank,
		showOrgHierarchyRank,
		showBreakdownByParticipation,
	} = visibility;

	const config = {
		...initConfig,
		...slConfig,
		dimensions: slConfig?.thresholdGroups.map(group => {
			return {
				id: group.label,
				value: group.id,
			};
		}),
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={3}></Grid>
			<Grid item xs={3}>
				<Autocomplete
					multiple
					options={years}
					value={filters.years}
					getOptionLabel={option => option}
					renderInput={params => <TextField {...params} label='Year(s)' />}
					onChange={(event, value) => setFilters({ ...filters, years: value })}
					size='small'
				/>
			</Grid>
			<Grid item xs={3}>
				<Autocomplete
					options={leaders}
					value={filters.leader}
					getOptionLabel={option => option.label}
					renderInput={params => <TextField {...params} label='Leader' />}
					onChange={(event, value) => setFilters({ ...filters, leader: value })}
					size='small'
				/>
			</Grid>
			<Grid container m={3}>
				<Grid item xs={12} id='header'>
					<Typography variant='h4' align='center'>
						Leader Rollup
					</Typography>
					<Typography variant='h6' align='center'>
						{filters.leader ? filters.leader.label : 'None Selected'}
					</Typography>
				</Grid>
				{filters.leader && filters.surveyId && (
					<>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakAfter: showYoY ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showYoY'
								label='Year Over Year History'
							/>
							{showYoY && surveyHistory && (
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d =>
															d.tagValueId ===
															filters?.leader?.nodeOptionId
													)}
													lookupData={lookupData}
													label='Your History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d => d.tagAttributeId === '*'
													)}
													lookupData={lookupData}
													label='Site History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d =>
															d.tagAttributeId ===
															bumpChartCfg?.shiftAttributeId
													)}
													lookupData={lookupData}
													label='Shift History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d =>
															d.tagAttributeId ===
															bumpChartCfg?.orgAttributeId
													)}
													lookupData={lookupData}
													label='Department History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>

						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showLeaderByQuestion'
								label='Leader Average by Question'
							/>
							{showLeaderByQuestion && (
								<LeaderDetailByQuestion
									data={slData}
									filters={filters}
									lookupData={lookupData}
									config={config}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showComments ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showComments'
								label='Leader Comments'
							/>
							{showComments && (
								<LeaderComments
									data={slData}
									filters={filters}
									lookupData={lookupData}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showCompetencies ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showCompetencies'
								label='Competencies'
							/>

							{showCompetencies && (
								<Competencies
									data={slData}
									filters={filters}
									lookupData={lookupData}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore:
									showLeaderRank || showLeaderHierarchyRank ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showLeaderRank'
								label='Leader Rank'
							/>
							{showLeaderRank && (
								<HierarchyBarWrapper
									data={slData['leader'] || []}
									filters={filters}
									lookupData={lookupData}
									title={`${filters?.leader?.label} SL Rank`}
								/>
							)}
						</Grid>

						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showLeaderHierarchyRank'
								label='Leader Rank (Aggregated)'
							/>
							{showLeaderHierarchyRank && (
								<HierarchyBarWrapper
									data={slData['leader'] || []}
									filters={filters}
									lookupData={lookupData}
									aggregate={true}
									title={`Accumulation of ${filters?.leader?.label} Org. SL Rank`}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore:
									showOrgRank || showOrgHierarchyRank ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showOrgRank'
								label='Organization Rank'
							/>
							{showOrgRank && (
								<HierarchyBarWrapper
									data={slData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									title={`Organization SL Rank`}
								/>
							)}
						</Grid>

						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showOrgHierarchyRank'
								label='Organization Rank (Aggregated)'
							/>
							{showOrgHierarchyRank && (
								<HierarchyBarWrapper
									data={slData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									aggregate={true}
									title={`Accumulation of Organization SL Rank`}
								/>
							)}
						</Grid>

						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showBreakdownByParticipation ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showBreakdownByParticipation'
								label='SL Breakdown by Participation'
							/>
							{showBreakdownByParticipation && (
								<MarimekkoWrapper
									data={slData['leader'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={undefined} // needs to be undefined for leader reports
									alwaysShowLabels={undefined} // needs to be undefined for leader reports
									title='SL Breakdown by Participation'
								/>
							)}
						</Grid>

						<Grid item xs={12}>
							{showBreakdownByParticipation && (
								<MarimekkoWrapper
									data={slData['leader'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={undefined} // needs to be undefined for leader reports
									alwaysShowLabels={undefined} // needs to be undefined for leader reports
									subgroup={true}
									title='SL Breakdown by Participation & Shift'
								/>
							)}
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default LeaderRollup;
