import Box from '@mui/material/Box';
import React from 'react';
import { deleteNode, insert, width } from '../../../helpers/treeUtils';
import HierarchyTabs from './HierarchyTabs';
import NewHierarchyPanel from './NewHierarchyPanel';
import TabPanels from './TabPanels';

const Hierarchies = ({
	hierarchies,
	handleHierarchyChange,
	handleHierarchyNodeChange,
	attributes,
}) => {
	const [selectedTab, setSelectedTab] = React.useState('new');
	const [activeHierarchy, setActiveHierarchy] = React.useState({});

	// set the active hierarchy when the selected tab changes
	React.useEffect(() => {
		if (selectedTab === 'new') {
			setActiveHierarchy({});
		} else {
			setActiveHierarchy(hierarchies.find(h => h.uuid === selectedTab) || {});
		}
	}, [selectedTab, hierarchies]);

	// #region handlers
	const handleChangeTab = (event, newNode) => {
		setSelectedTab(newNode);
	};

	// add a new hierarchy
	const handleNewHierarchy = async hierarchy => {
		if (!hierarchy.name) {
			return;
		}
		const addedHierarchy = await handleHierarchyChange(hierarchy);

		// create root node for new hierarchy
		const rootNodeValue = {
			value: `${hierarchy.name} Root Node`,
		};
		const updatedTree = insert([], rootNodeValue);
		handleHierarchyNodeChange(updatedTree, addedHierarchy);

		// switch to new tab for new hierarchy
		if (addedHierarchy) setSelectedTab(addedHierarchy.uuid);
	};

	// remove an entire hierarchy
	const handleRemoveHierarchy = hierarchy => {
		// soft delete the hierarchy
		hierarchy.deleted = true;
		console.log(hierarchy);
		handleHierarchyChange(hierarchy);
	};

	// add a new root node to a hierarchy
	const handleAddNode = newNode => {
		console.log(newNode);
		if (!newNode.value) {
			return;
		}

		// get the current hierarchy
		const tree = activeHierarchy.nodes;
		if (!tree) {
			alert('No tree found');
			return;
		}

		const { parent, ...node } = newNode;

		// create a new node
		const updatedTree = insert(tree, node, parent);
		console.log(updatedTree);
		handleHierarchyNodeChange(updatedTree, activeHierarchy);
	};

	// remove an node from an existing hierarchy node
	const handleRemoveNode = node => {
		const tree = activeHierarchy.nodes;
		if (!tree) {
			alert('No tree found');
			return;
		}

		// soft delete the node
		const updatedTree = deleteNode(tree, node);
		console.log(updatedTree);
		handleHierarchyNodeChange(updatedTree, activeHierarchy);
	};

	// const handleResetNodeTree = () => {
	// 	const tree = activeHierarchy.nodes;
	// 	if (!tree) {
	// 		alert('No tree found');
	// 		return;
	// 	}

	// 	// something has gone wrong with the tree, so we need to "reset" it.
	// 	// first, filter out all deleted nodes
	// 	const filteredTree = tree.filter(n => n.left > 0);
	// 	// then, sort the nodes by left value
	// 	const sortedTree = filteredTree.sort((a, b) => a.left - b.left);
	// 	console.log(sortedTree);
	// 	// then, renumber the left and right values
	// 	const updatedTree = sortedTree.map((n, i) => {
	// 		n.left = i + 1;
	// 		n.right = i + 2;
	// 		return n;
	// 	});

	// 	console.log(tree);
	// 	console.log(updatedTree);
	// };
	// #endregion

	activeHierarchy &&
		activeHierarchy.nodes &&
		activeHierarchy.nodes
			.filter(n => n.right - n.left === 1)
			.forEach(n => {
				console.log(n.value, n.left, n.right);
			});
	return (
		<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
			<HierarchyTabs
				selectedTab={selectedTab}
				handleChangeTab={handleChangeTab}
				hierarchies={hierarchies}
			/>
			<NewHierarchyPanel
				selectedTab={selectedTab}
				attributes={attributes}
				handleNewHierarchy={handleNewHierarchy}
			/>
			<TabPanels
				hierarchies={hierarchies}
				activeHierarchy={activeHierarchy}
				attributes={attributes}
				selectedTab={selectedTab}
				handleAddNode={handleAddNode}
				handleRemoveNode={handleRemoveNode}
				handleRemoveHierarchy={handleRemoveHierarchy}
				// handleResetNodeTree={handleResetNodeTree}
			/>
		</Box>
	);
};

export default Hierarchies;
