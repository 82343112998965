import React from 'react';
// import Pricing from './Pricing';
import TakeSurvey from './TakeSurvey';
import TenantForm from './tenant/TenantForm';

const Landing = () => {
    const [hasSubdomain, setHasSubdomain] = React.useState(false);

    React.useEffect(() => {
        const subdomain = window.location.hostname.split('.');

        // if more than 2 parts or localhost, then it has a subdomain
        if (subdomain.length > 2 || subdomain[1] === 'localhost') {
            setHasSubdomain(true);
        }
    }, []);

    const handleNewTenantForm = () => {
        setHasSubdomain(false);
    };

    return hasSubdomain ? (
        <TakeSurvey />
    ) : (
        <TenantForm plan='Pro' showNewTenantForm={true} setShowNewTenantForm={handleNewTenantForm} />
    );
};

export default Landing;
