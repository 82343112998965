import React from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

const SurveyTabs = ({ selectedTab, handleChangeTab, surveys }) => {
	return (
		<Tabs
			orientation='vertical'
			value={selectedTab}
			onChange={handleChangeTab}
			variant='scrollable'
			scrollButtons
			allowScrollButtonsMobile
			aria-label='scrollable auto tabs example'
			sx={{
				borderRight: 1,
				borderColor: 'divider',
				height: '50rem',
				width: '13rem',
				wordWrap: 'break-word',
			}}
		>
			<Tab
				label={`${
					(surveys || []).length > 0 ? 'Create New Survey' : 'Create Your First Survey!'
				}`}
				value='new'
				key='new'
			/>
			<Divider />
			{surveys &&
				surveys.length > 0 &&
				surveys
					.sort((a, b) => a.global - b.global || a.deleted - b.deleted)
					.map(s => (
						<Tab
							key={s.uuid}
							value={s.uuid}
							label={
								<Box
									key={s.uuid}
									display='flex'
									alignItems='center'
									justifyContent='space-between'
								>
									{s.global && <LanguageIcon fontSize='small' />}
									<Typography
										variant='subtitle2'
										sx={{
											textDecorationLine: s.deleted ? 'line-through' : 'none',
											fontStyle: s.global ? 'italic' : 'normal',
										}}
									>
										{s.metadata.name}
									</Typography>
									<Divider />
								</Box>
							}
							sx={{ alignItems: 'flex-end', px: 0 }}
						/>
					))}
		</Tabs>
	);
};

export default SurveyTabs;
