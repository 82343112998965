import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const NewAttributeOption = ({ handleAdd }) => {
	const [option, setOption] = React.useState('');

	const handleAddOption = () => {
		handleAdd(option);
		setOption('');
	};

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            handleAddOption();
        }
    };

	return (
		<Box display='flex' alignItems='center' justifyContent='space-between' width='100%' my={2}>
			<TextField
				value={option}
				onChange={e => setOption(e.target.value)}
                onKeyDown={handleKeyDown}
				label='Add Option'
				variant='outlined'
				size='small'
			/>
			<Button variant='contained' color='primary' onClick={handleAddOption} sx={{ ml: 2 }}>
				Add
			</Button>
		</Box>
	);
};

export default NewAttributeOption;
