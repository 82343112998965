import pluralize from 'pluralize';

export const convertCamelCaseToTitleCase = str => {
	return str.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

export const convertTitleCaseToCamelCase = str => {
	return str
		.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
			return index === 0 ? word.toLowerCase() : word.toUpperCase();
		})
		.replace(/\s+/g, '');
};

// format a name string that assume the format is either:
// 1. Last Name, First Name
// 2. First Name Last Name
export const formatTemplateStringVariable = str => {
	// if string is falsy, return it
	if (!str) return '';
	// if all one word, return it
	if (str.indexOf(' ') === -1) return str;
	// check for comma
	const commaIndex = str.indexOf(',');
	if (commaIndex > -1) {
		const firstName = str.substring(commaIndex + 1).trim();
		return firstName;
	} else {
		// format: First Name Last Name
		// const split = str.split(' ');
		// const firstName = split[0];
		// return firstName;
		return str; // return the whole string. the above doesnt work for strings like "Vista Outdoor",
		//  which is a company name that we want the whole string for
	}
};

// check if a string is in ALL CAPS
export const isAllCaps = str => {
	return str === str.toUpperCase();
};

// convert ALL CAPS to Sentence case
// e.g. "THIS IS A TEST" => "This is a test";
export const convertAllCapsToSentenceCase = (str, indicator = '*') => {
	if (!str || str === '') return '';
	// indicator could be a React component
	// return the indcator and the string in a component that can be rendered
	return (
		<>
			{indicator} {str.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
		</>
	);

	//return <span>{indicator}{str.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</span>;
	// if (typeof indicator === 'object') {

	// } else if (typeof indicator === 'string') {
	// 	return <span>{indicator}{str.toLowerCase().replace(/^\w/, c => c.toUpperCase())}</span>;
	// 	// return `${indicator}${str.toLowerCase().replace(/^\w/, c => c.toUpperCase())}`;
	// }
};

export const scrubBrackets = str => {
	if (!str) return '';
	// remove all curly brackets and capitalize the first letter
	return str.replace(/[{}]/g, '').replace(/^./, function (str) {
		return str.toUpperCase();
	});
};

// export the pluralize function as is
export { pluralize };
