import { Autocomplete, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import React from 'react';
import BoxPlotWrapper from '../wrappers/BoxPlotWrapper';

const TenantBoxAndWhisker = ({ globalSurveyId, data, surveys, attributes, hierarchies }) => {
	const [slData, setSlData] = React.useState({});
	const [years, setYears] = React.useState([]);
	const [filters, setFilters] = React.useState({
		surveyId: null,
		years: [dayjs().year()],
		leader: null,
	});

	React.useEffect(() => {
		if (surveys && data) {
			const filteredData = data[globalSurveyId] || [];
			setSlData(filteredData);
			setFilters({ ...filters, surveyId: globalSurveyId });
		}
	}, []);

	React.useEffect(() => {
		if (slData && slData.year) {
			const years = Object.keys(slData.year).map(year => parseInt(year));
			setYears(years);
		}
	}, [slData]);

	const lookupData = {
		surveys,
		attributes,
		hierarchies,
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={3}></Grid>
			<Grid item xs={3}>
				<Autocomplete
					multiple
					options={years}
					value={filters.years}
					getOptionLabel={option => option}
					renderInput={params => <TextField {...params} label='Year(s)' />}
					onChange={(event, value) => setFilters({ ...filters, years: value })}
					size='small'
				/>
			</Grid>
			<Grid item xs={3}></Grid>
			<Grid item xs={12} id='header'>
				<Typography variant='h4' align='center'>
					Box and Whisker
				</Typography>
			</Grid>
			{slData && (
				<>
					<Grid item xs={12}>
						<BoxPlotWrapper
							data={slData['organization'] || []}
							filters={filters}
							lookupData={lookupData}
						/>
					</Grid>
					<Grid item xs={12}>
						<BoxPlotWrapper
							data={slData['shift'] || []}
							filters={filters}
							lookupData={lookupData}
						/>
					</Grid>
					<Grid item xs={12}>
						<BoxPlotWrapper
							data={slData['surveyType'] || []}
							filters={filters}
							lookupData={lookupData}
						/>
					</Grid>
					<Grid item xs={12}>
						<BoxPlotWrapper
							data={slData['leader'] || []}
							filters={filters}
							lookupData={lookupData}
						/>
					</Grid>
				</>
			)}
		</Grid>
	);
};

export default TenantBoxAndWhisker;
