// Description: This file is used to get the current environment from the url.
export default function getTenantEnv() {
	try {
		// if the subdomain ends with 'dev' or 'test', return that
		// otherwise, return 'prod'
		const env = window.location.href.split('.')[0].split('//')[1].split('-').pop();
		if (env === 'dev' || env === 'test') {
			// || process.env.NODE_ENV === 'development') {
			return env;
		}
		return 'prod';
	} catch (error) {
		console.log(error);
		return 'dev';
	}
}
