import { v4 as uuidv4 } from 'uuid';

export const initQuestion = () => {
	try {
		const newQuestion = {
			uuid: uuidv4(),
			questionText: '',
			responseTypeId: null,
			required: false,
		};
		return newQuestion;
	} catch (e) {
		console.log(e);
		return null;
	}
};

export const initSurvey = () => {
	try {
		const newSurvey = {
			uuid: uuidv4(),
			metadata: {
				name: '',
				description: '',
				instructions: '',
			},
			questions: [],
		};
		return newSurvey;
	} catch (e) {
		console.log(e);
		return null;
	}
};

// codes are 8 digit alphanumeric captialized strings with no vowels, no 0s, and no 1s
export const generateCodes = numCodes => {
	try {
		const codes = [];
		const letters = 'ABCDEFGHJKMNPQRSTVWXYZ';
		const numbers = '23456789';
		for (let i = 0; i < numCodes; i++) {
			let code = '';
			for (let j = 0; j < 8; j++) {
				if (j % 2 === 0) {
					code += letters[Math.floor(Math.random() * letters.length)];
				} else {
					code += numbers[Math.floor(Math.random() * numbers.length)];
				}
			}
			codes.push(code);
		}
		return codes;
	} catch (e) {
		console.log(e);
		return null;
	}
};

// #region Arithmetic Functions
export const questionAvg = (groups, questionId, keyFilters) => {
	try {
		const responses = Object.entries(groups)
			.filter(([key, group]) => (!keyFilters ? true : keyFilters.includes(key)))
			.map(([key, group]) => {
				return group
					.flatMap(sub => sub.responses)
					.filter(response => response.questionId === questionId)
					.filter(na => !isNaN(na.response));
			})
			.filter(Boolean);
		const totalSubmissions = Object.values(responses).flatMap(res => res).length;
		const total = Object.values(responses)
			.flatMap(res => res)
			.reduce((acc, curr) => acc + curr.response, 0);

		return total / totalSubmissions;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// selfEvalSTId is the survey type id for the self-evaluation survey
export const findLeaderSelfEval = (selfEvals, leaderId) => {
	try {
		// find any self-evaluation submissions and return the most recent one.
		// the submission will have a pitTag of surveyType that is equal to any of the ids in the selfEvalSTIds array
		const leaderSE = selfEvals
			.filter(sub => {
				const stTag = sub.pitTags.find(tag => tag.key === 'leader');
				return stTag && stTag.value === leaderId;
			})
			.sort((a, b) => new Date(b.submittedOn) - new Date(a.submittedOn))[0];

		return leaderSE || {};
	} catch (e) {
		console.log(e);
		return {};
	}
};

// filter surveys by tag key and value
export const filterSurveysByTag = (submissions, key, values, include = true) => {
	try {
		const filtered = submissions.filter(sub => {
			const tag = sub.pitTags.find(tag => tag.key === key);
			return tag && include ? values.includes(tag.value) : !values.includes(tag.value);
		});
		return filtered;
	} catch (e) {
		// console.log(e);
		return [];
	}
};

// filter comments out of submission responses.
// Anything that is not a number or NONE is considered a comment
export const filterComments = submissions => {
	try {
		const comments = submissions
			.map(sub => {
				const filteredResponses = sub.responses
					.filter(r => r.response) // filter out null responses
					.filter(r => r.response !== 'NONE') // filter out NONE responses
					.filter(r => isNaN(r.response)); // filter out number responses
				return { ...sub, responses: filteredResponses };
			})
			.filter(sub => sub.responses.length > 0);
		return comments;
	} catch (e) {
		console.log(e);
		return [];
	}
};

// responseValue being a number or NONE (e.g. 1, 2, 3, 4, 5, 'NONE')
// return the number of responses with that value
export const questionCountsByResponse = (responses, questionId, responseValue) => {
	try {
		const total = responses
			.flatMap(sub => sub.responses)
			.filter(response => response.questionId === questionId)
			.filter(response => response.response === responseValue).length;

		return total;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// checks to see every response in a submission is NONE (No Opinion)
export const allNONEs = (responses, startIndex = 0, stopIndex) => {
	try {
		if (!stopIndex) stopIndex = responses.length;
		// last 4 responses are the comment questions
		return responses.slice(startIndex, stopIndex).every(sr => sr.response === 'NONE');
	} catch (e) {
		console.log(e);
		return false;
	}
};

export const questionAvgNoSE = (submissions, questionId) => {
	try {
		const responses = submissions
			// .filter(sub => (excludeSelfEvals ? sub.pitTags.length > 0 : true))
			.map(sub => sub.responses.find(r => r.questionId === questionId));
		const total = responses.reduce((acc, curr) => acc + curr.response, 0);
		return total / responses.length;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// attempt to convert a string to a number, and compare it, return boolean
export const compareResponse = (a, b, operator) => {
	try {
		const aNum = Number(a);
		const bNum = Number(b);
		switch (operator) {
			case '==':
			case '===':
				return aNum === bNum;
			case '!=':
			case '!==':
				return aNum !== bNum;
			case '>':
				return aNum > bNum;
			case '<':
				return aNum < bNum;
			case '>=':
				return aNum >= bNum;
			case '<=':
				return aNum <= bNum;
			default:
				return false;
		}
	} catch (e) {
		console.log(e);
		return false;
	}
};

export const sortResults = (a, b, sort = 'DESC') => {
	try {
		switch (sort) {
			case 'ASC':
				return a - b;
			case 'DESC':
				return b - a;
			default:
				return 0;
		}
	} catch (e) {
		console.log(e);
		return 0;
	}
};

// calc diff between two numbers that might come in as strings
export const absDiff = (a, b) => {
	try {
		const aNum = Number(a);
		const bNum = Number(b);
		return Math.abs(aNum - bNum);
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// avg responses grouped by question competency
export const avgByCompetency = (submissions, competencies) => {
	try {
		const avgByComp = competencies.map(comp => {
			const responses = submissions
				.flatMap(sub => sub.responses)
				.filter(response => comp.questions.includes(response.questionId))
				.filter(nan => !isNaN(nan.response));
			const total = responses.reduce((acc, curr) => acc + curr.response, 0);

			return {
				name: comp.name,
				avg: total / responses.length,
			};
		});
		return avgByComp;
	} catch (e) {
		console.log(e);
		return [];
	}
};

// calc avg for any submission with any of the values included in the tag
export const avgByTag = (submissions, tag, values = []) => {
	try {
		const filtered = filterSurveysByTag(submissions, tag, values);
		const avg = avgAllResponses(filtered);
		return avg;
	} catch (e) {
		console.log(e);
		return [];
	}
};

// avg of all responses within the submissions passed in
export const avgAllResponses = submissions => {
	try {
		const responses = submissions
			.flatMap(sub => sub.responses)
			.filter(res => typeof res.response === 'number');

		if (responses.length === 0) return NaN;

		const total = responses.reduce((acc, curr) => acc + curr.response, 0);
		return total / responses.length;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// clac avg of all responses (single survey)
export const calcAvg = responses => {
	try {
		// console.log(responses);
		const numbers = responses
			.filter(res => typeof res.response === 'number')
			.map(res => res.response);

		if (numbers.length === 0) return NaN;

		const avg = numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;

		return avg;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// clac standard deviation of all responses
export const calcStdDev = responses => {
	try {
		const numbers = responses
			.filter(res => typeof res.response === 'number')
			.map(res => res.response);

		if (numbers.length === 0) return NaN;

		const avg = numbers.reduce((acc, curr) => acc + curr, 0) / numbers.length;
		const stdDev = Math.sqrt(
			numbers.map(x => Math.pow(x - avg, 2)).reduce((acc, curr) => acc + curr, 0) /
				numbers.length
		);

		return stdDev;
	} catch (e) {
		console.log(e);
		return NaN;
	}
};

// calc avg from counts of responses
export const calcAvgFromCounts = weightMap => {
	try {
		return (
			(weightMap.opt1s * weightMap.opt1Weight +
				weightMap.opt2s * weightMap.opt2Weight +
				weightMap.opt3s * weightMap.opt3Weight +
				weightMap.opt4s * weightMap.opt4Weight +
				weightMap.opt5s * weightMap.opt5Weight) /
			(weightMap.opt1s +
				weightMap.opt2s +
				weightMap.opt3s +
				weightMap.opt4s +
				weightMap.opt5s)
		).toFixed(2);
	} catch (e) {
		console.log(e);
		return NaN;
	}
};
// #endregion

// #region misc
// find distinct tags from all submissions
export const findDistinctTags = submissions => {
	try {
		const tags = submissions.flatMap(sub => sub.pitTags);
		const distinctTags = [...new Set(tags.map(t => t.value))];
		return distinctTags;
	} catch (e) {
		console.log(e);
		return [];
	}
};
// # endregion
