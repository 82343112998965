import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, TextField, Typography } from '@mui/material';
import Code from './Code';
import DumbSaving from '../../shared/DumbSaving';
import { callApiGWLambda } from '../../../apis/api-gw';
import Snack from '../../shared/Snack';

const Confirm = ({ tags, linkedTags, options, groupId, surveys, setShowConfirm, hierarchies, attributes }) => {
    const [codeConfig, setCodeConfig] = React.useState([]);
    const [numCodes, setNumCodes] = React.useState(1);
    const [color, setColor] = React.useState('#000000');
    const [bgColor, setBgColor] = React.useState('#FFFFFF');
    const [saving, setSaving] = React.useState(false);
    const [snack, setSnack] = React.useState({ open: false, message: '', severity: 'success' });

    React.useEffect(() => {
        let canclled = false;
        var codeConfig = [];
        Object.entries(tags).forEach(([key, value]) => {
            codeConfig.push({
                key: key,
                value: value.value,
                hierarchyId: value.hierarchyId || null,
                attributeId: value.attributeId || null,
            });
        });
        Object.entries(linkedTags).forEach(([key, value]) => {
            codeConfig.push({
                key: key,
                value: value.value,
                hierarchyId: value.hierarchyId || null,
                attributeId: value.attributeId || null,
                linked: true,
            });
        });

        const { hierarchyId, name, nodesToGenerate } = options.hierarchyOptions;

        var codes = [];
        if (nodesToGenerate && nodesToGenerate.length) {
            nodesToGenerate.forEach(node => {
                const tagToReplace = codeConfig.find(t => t.hierarchyId === hierarchyId);
                codes.push({
                    surveyId: tags.surveyId.value,
                    tags: [
                        ...codeConfig.filter(t => t.hierarchyId !== hierarchyId),
                        {
                            key: tagToReplace.key,
                            value: node.uuid,
                            hierarchyId: tagToReplace.hierarchyId,
                            attributeId: tagToReplace.attributeId,
                        },
                    ],
                    color: color,
                    bgColor: bgColor,
                    codesToGenerate: parseInt(numCodes),
                });
            });
        } else {
            codes.push({
                surveyId: tags.surveyId.value,
                tags: codeConfig,
                color: color,
                bgColor: bgColor,
                codesToGenerate: parseInt(numCodes),
            });
        }

        if (!canclled) setCodeConfig(codes);

        return () => (canclled = true);
    }, [tags, linkedTags, options, numCodes, color, bgColor]);

    const calculateTotalCodes = nodesToGenerate => {
        // calc total comination between the option array lengths and the numCodes
        return nodesToGenerate && nodesToGenerate.length ? numCodes * nodesToGenerate.length : numCodes;
    };

    const calculateTotalCodesString = () => {
        const { nodesToGenerate, name } = options.hierarchyOptions;

        const total = calculateTotalCodes(nodesToGenerate);
        const str =
            nodesToGenerate && nodesToGenerate.length
                ? `${nodesToGenerate.length} ${name} codes x ${numCodes}`
                : `${numCodes}`;
        return (
            <Box>
                <strong>{`${total} total codes: `}</strong>
                <span>{str}</span>
            </Box>
        );
    };

    const handleGenerateCodes = async () => {
        try {
            setSaving(true);

            const final = {
                groupId: groupId,
                codes: codeConfig,
            };
            console.log(final);

            const result = await callApiGWLambda('post', 'codes', final);
            // const result = {
            // 	status: 200,
            // 	data: { statusCode: 200, body: 'Faked it successfully' },
            // };
            setSaving(false);
            console.log(result);

            if (result.status === 200) {
                if (result.data.statusCode === 200) {
                    setSnack({
                        open: true,
                        message: result.data.body || 'Codes generated successfully',
                        severity: 'success',
                    });
                } else {
                    setSnack({
                        open: true,
                        message: result.data.body || result.data.errorMessage,
                        severity: 'error',
                    });
                }
            } else {
                setSnack({
                    open: true,
                    message: result.data.body || result.data.errorMessage,
                    severity: 'error',
                });
            }
        } catch (error) {
            console.log(error);
            setSaving(false);
        }
    };

    console.log(bgColor);
    console.log(color);
    return (
        <Dialog maxWidth='md' open={true} onClose={() => setShowConfirm(false)}>
            <Snack
                open={snack.open}
                message={snack.message}
                severity={snack.severity}
                handleClose={() => {
                    const severity = snack.severity;
                    setSnack({ open: false, message: '', severity: 'success' });
                    severity === 'success' && setShowConfirm(false);
                }}
            />

            <DialogTitle>Confirm</DialogTitle>
            <DialogContent
                sx={{
                    overflowY: 'hidden',
                }}
            >
                {saving && <DumbSaving text='Generating Codes...' />}
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        Review & Generate
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='# of Codes'
                            type='number'
                            value={Number(numCodes)}
                            size='small'
                            min={1}
                            max={1000}
                            fullWidth
                            onChange={e => setNumCodes(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='Background Color'
                            type='color'
                            value={bgColor}
                            size='small'
                            fullWidth
                            onChange={e => setBgColor(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label='Foreground Color'
                            type='color'
                            value={color}
                            size='small'
                            fullWidth
                            onChange={e => setColor(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='subtitle1'>{calculateTotalCodesString()}</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        Sample Codes
                    </Grid>
                    <Grid item xs={12} display='flex'>
                        <Typography
                            variant='overline'
                            sx={{
                                opacity: 0.3,
                                zIndex: 100,
                                color: 'error.dark',
                                position: 'absolute',
                                fontSize: '6rem',
                                letterSpacing: '1rem',
                                transform: 'rotate(-30deg)',
                                top: '40%',
                                left: '20%',
                            }}
                        >
                            Preview
                        </Typography>
                        <Grid item xs={6}>
                            {codeConfig
                                .filter((_, i) => i % 2 == 0)
                                .map(code => (
                                    <Code
                                        key={code.id}
                                        code={code}
                                        hierarchies={hierarchies}
                                        attributes={attributes}
                                        surveys={surveys}
                                    />
                                ))}
                        </Grid>
                        <Grid item xs={6}>
                            {codeConfig
                                .filter((_, i) => i % 2 == 1)
                                .map(code => (
                                    <Code
                                        key={code.id}
                                        code={code}
                                        hierarchies={hierarchies}
                                        attributes={attributes}
                                        surveys={surveys}
                                    />
                                ))}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <Button variant='outlined' size='small' fullWidth onClick={() => setShowConfirm(false)}>
                    Cancel
                </Button>
                <Button variant='contained' size='small' fullWidth onClick={handleGenerateCodes}>
                    Confirm & Generate
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Confirm;
