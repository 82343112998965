import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { callApiGWLambda, callApiGWLambdaGet } from '../../../apis/api-gw';
import DumbSaving from '../../shared/DumbSaving';
import Snack from '../../shared/Snack';

const TenantForm = ({ plan, showNewTenantForm, setShowNewTenantForm }) => {
    const [aliasParts, setAliasParts] = React.useState({
        company: '',
        division: '',
        site: '',
    });
    const [alias, setAlias] = React.useState('');
    const [tenant, setTenant] = React.useState({});
    const [existingTenants, setExistingTenants] = React.useState(null);
    const [nameTaken, setNameTaken] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [snack, setSnack] = React.useState({ open: false, message: '', severity: 'success' });
    const [creationStatuses, setCreationStatuses] = React.useState({
        prod: false,
        test: false,
    });

    // load new tenant template from s3
    React.useEffect(() => {
        let canceled = false;
        const loadTenant = async () => {
            const newTenantTemplate = await callApiGWLambdaGet('config', '', 'new-tenant');

            if (newTenantTemplate.statusCode !== 200) {
                if (newTenantTemplate.statusCode === 425) return; // 425 = Too Early, experimental - https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/425
                if (!canceled) setTenant({ error: newTenantTemplate.body });
                return;
            }

            const data = JSON.parse(newTenantTemplate.body);

            if (!canceled)
                setTenant({
                    ...tenant,
                    ...data,
                    readOnly: {
                        ...data.readOnly,
                        plan: plan,
                    },
                });
        };
        loadTenant();

        return () => {
            canceled = true;
        };
    }, []);

    // load existing tenants from s3
    React.useEffect(() => {
        let canceled = false;
        const loadExistingTenants = async () => {
            const existingTenants = await callApiGWLambdaGet('tenants');
            if (existingTenants.statusCode !== 200) {
                return;
            }

            const data = JSON.parse(existingTenants.body);
            if (!canceled && data) {
                setExistingTenants(data);
            }
        };
        loadExistingTenants();

        return () => {
            canceled = true;
        };
    }, []);

    React.useEffect(() => {
        if (creationStatuses.prod && creationStatuses.test) {
            // redirect to the same page, but with the new tenant alias as the subdomain in the host
            // e.g. from https://survently.com/ to https://mycompany.survently.com/manage/config
            // or locally, from http://localhost:3000/ to http://mycompany.localhost:3000/manage/config

            window.location.href =
                window.location.hostname === 'localhost'
                    ? `http://${alias}.localhost:3000/manage/config`
                    : `https://${alias}.survently.com/manage/config`;
        }
    }, [alias, creationStatuses]);

    React.useEffect(() => {
        const subdomainString = `${aliasParts.company}-${aliasParts.division}-${aliasParts.site}`;
        // remove double dashes
        var subdomain = subdomainString.replace(/--/g, '-');
        // remove trailing dash
        subdomain = subdomain.replace(/-$/, '');
        // remove spaces, and special characters
        subdomain = subdomain.replace(/[^a-zA-Z0-9-]/g, '');

        if (existingTenants && existingTenants.findIndex(t => t.name === subdomain.toLowerCase()) > -1) {
            setNameTaken(true);
        } else {
            setNameTaken(false);
        }
        setAlias(subdomain);
    }, [aliasParts, existingTenants]);

    const buildSubdomainTenant = e => {
        try {
            e.preventDefault();
            const createTenants = async () => {
                setSaving(true);
                const prod = await saveTenant(alias);
                // automatically create a test tenant for the customer
                const test = await saveTenant(`${alias}-test`);
                setCreationStatuses({ prod: prod, test: test });
                setSaving(false);
            };
            createTenants();
        } catch (error) {
            console.log(error);
            setSaving(false);
            setSnack({
                open: true,
                message: `There was an error creating your tenant. Please contact support. ${error}`,
                severity: 'error',
            });
        }
    };

    const saveTenant = async subdomain => {
        try {
            const newTenant = {
                ...tenant,
                readOnly: {
                    ...tenant.readOnly,
                    subdomain: subdomain,
                    tableAlias: subdomain,
                },
                // add two attributes by default
                customerManaged: {
                    ...tenant.customerManaged,
                    attributes: [
                        {
                            uuid: uuidv4(),
                            key: '{leader}',
                            type: 'templateString',
                            options: [],
                        },
                        {
                            uuid: uuidv4(),
                            key: '{company}',
                            type: 'templateString',
                            options: [
                                {
                                    uuid: uuidv4(),
                                    value:
                                        tenant.customerManaged.site ||
                                        tenant.customerManaged.division ||
                                        tenant.customerManaged.company,
                                },
                            ],
                        },
                    ],
                },
            };
            // check to ensure valid JSON
            const JSONBody = JSON.parse(JSON.stringify(newTenant));

            // set id on newTenant if it doesn't exist
            if (!JSONBody.id) JSONBody.id = uuidv4();

            const payload = {
                bucketName: 'survently-configs', //s3Config.configFileBucket,
                key: `${subdomain}-config.json`, //s3Config.configFileKey,
                contentBody: JSON.stringify(JSONBody),
            };

            // post to api
            const result = await callApiGWLambda('post', 'config', payload);
            console.log(result);
            if (result.data) {
                switch (result.data.statusCode) {
                    case 200:
                        return true;
                    default:
                        setSnack({
                            open: true,
                            message: `There was an error creating your ${subdomain} tenant. Please contact support.`,
                            severity: 'error',
                        });
                        return false;
                }
            }
        } catch (error) {
            console.log(error);
            setSnack({
                open: true,
                message: `There was an error creating your ${subdomain} tenant. Please contact support. ${error}`,
                severity: 'error',
            });
        }
    };

    const handleChange = (e, cfgKey) => {
        const { name, value } = e.target;

        if (name === 'companyAlias') {
            // set aliasParts company to the first letter of each word in name, remove spaces and make lowercase
            const companyAlias = value.toLowerCase();

            setAliasParts({
                ...aliasParts,
                company: companyAlias,
            });
        } else if (name === 'division') {
            // if multiple words, set aliasParts division to the first letter of each word in name, remove spaces and make lowercase
            // if single word, set aliasParts division to the first 4 letters of the word, make lowercase
            if (value.split(' ').length === 1) {
                const divisionAlias = value.substring(0, 4).toLowerCase();
                setAliasParts({
                    ...aliasParts,
                    division: divisionAlias,
                });
            } else {
                const divisionAlias = value
                    .split(' ')
                    .map(word => word.charAt(0))
                    .join('')
                    .toLowerCase();
                setAliasParts({
                    ...aliasParts,
                    division: divisionAlias,
                });
            }
        } else if (name === 'site') {
            // same logic as division
            if (value.split(' ').length === 1) {
                const siteAlias = value.substring(0, 4).toLowerCase();
                setAliasParts({
                    ...aliasParts,
                    site: siteAlias,
                });
            } else {
                const siteAlias = value
                    .split(' ')
                    .map(word => word.charAt(0))
                    .join('')
                    .toLowerCase();
                setAliasParts({
                    ...aliasParts,
                    site: siteAlias,
                });
            }
        }
        setTenant({
            ...tenant,
            [cfgKey]: {
                ...tenant[cfgKey],
                [name]: value,
            },
        });
    };

    // console.log(existingTenants);
    return (
        <>
            {showConfirm && (alert('showConfirm'), (<div>showConfirm</div>))}
            {saving && <DumbSaving text='Creating your account and tenant...' />}
            <Snack
                open={snack.open}
                message={snack.message}
                severity={snack.severity}
                handleClose={() => {
                    setSnack({ open: false, message: '', severity: 'success' });
                }}
            />
            <Dialog maxWidth='xs' open={showNewTenantForm} onClose={() => setShowConfirm(false)}>
                <DialogTitle>
                    <Typography variant='h5'>Create Your New Company Account</Typography>
                    <Typography variant='h6'>
                        Your Selected Plan: <strong>{plan}</strong>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={buildSubdomainTenant}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} display='flex'>
                                <TextField
                                    name='name'
                                    fullWidth
                                    size='small'
                                    label='Company Name'
                                    value={(tenant.customerManaged || {}).name}
                                    onChange={e => handleChange(e, 'customerManaged')}
                                    required
                                    error={tenant.name === ''}
                                    helperText='Company Name is Required'
                                    sx={{ m: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} display='flex'>
                                <TextField
                                    name='companyAlias'
                                    fullWidth
                                    size='small'
                                    label='Company Alias'
                                    value={(tenant.customerManaged || {}).companyAlias}
                                    onChange={e => handleChange(e, 'customerManaged')}
                                    required
                                    error={tenant.companyAlias === ''}
                                    helperText='Company Alias is Required'
                                    sx={{ m: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} display='flex'>
                                <TextField
                                    name='division'
                                    fullWidth
                                    size='small'
                                    label='Division'
                                    value={(tenant.customerManaged || {}).division}
                                    onChange={e => handleChange(e, 'customerManaged')}
                                    sx={{ m: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} display='flex'>
                                <TextField
                                    name='site'
                                    fullWidth
                                    size='small'
                                    label='Site'
                                    value={(tenant.customerManaged || {}).site}
                                    onChange={e => handleChange(e, 'customerManaged')}
                                    sx={{ m: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant='subtitle1' sx={{ mx: 1 }}>
                                    Your site will be:
                                </Typography>
                                <Typography variant='subtitle1' sx={{ mx: 1 }}>
                                    <strong>{`www.${alias}.survently.com`}</strong>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} display='flex' justifyContent='flex-start'>
                                {nameTaken && (
                                    <Typography variant='subtitle2' color='error.main' sx={{ mx: 1 }}>
                                        <em>
                                            "{alias}" is taken, please enter a different combination of company
                                            alias/division/site.
                                        </em>
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={12} display='flex'>
                                <TextField
                                    name='logoUrl'
                                    fullWidth
                                    size='small'
                                    label='Logo URL'
                                    value={(tenant.customerManaged || {}).logoUrl}
                                    onChange={e => handleChange(e, 'customerManaged')}
                                    sx={{ m: 1 }}
                                />
                            </Grid>
                            <Grid item xs={12} display='flex' justifyContent='center'>
                                {tenant.customerManaged && tenant.customerManaged.logoUrl && (
                                    <Box height='5rem' width='15rem' display='flex' justifyContent='center'>
                                        <img
                                            src={(tenant.customerManaged || {}).logoUrl}
                                            alt='logo'
                                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                                        />
                                    </Box>
                                )}
                            </Grid>

                            <Grid item xs={12} display='flex' justifyContent='space-around'>
                                <Button
                                    variant='outlined'
                                    onClick={() => setShowNewTenantForm(false)}
                                    fullWidth
                                    sx={{ mx: 1 }}
                                >
                                    Cancel
                                </Button>
                                <Button variant='contained' type='submit' fullWidth sx={{ mx: 1 }} disabled={nameTaken}>
                                    Create Account
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                {/* <div>{JSON.stringify(tenant)}</div> */}
            </Dialog>
        </>
    );
};

export default TenantForm;
