import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
// Components
import Landing from '../landing/Landing';
import Survey from '../survey/Survey';
import ErrorPage from '../errors/ErrorPage';
import Loading from '../shared/Loading';
import ThankYou from '../survey/ThankYou';
import AdminHome from '../admin/AdminHome';
// apis
import { AdminProvider } from '../../contexts/AdminContext';

const Routes = () => {
    const router = createBrowserRouter([
        {
            path: '/',
            element: <Landing />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'thank-you',
            element: <ThankYou />,
            errorElement: <ErrorPage />,
        },
        {
            path: 'survey',
            children: [
                {
                    path: 'begin',
                    element: <Survey />,
                },
                {
                    path: ':code',
                    element: <Loading />,
                    loader: async ({ request, params }) => {
                        sessionStorage.setItem('surveyCode', params.code);
                        return {
                            to: '/survey/begin',
                            replace: true,
                        };
                    },
                },
            ],
            errorElement: <ErrorPage />,
        },
        // Admin routes
        {
            path: 'manage',
            children: [
                {
                    path: ':managing',
                    element: <Admin />,
                },
                {
                    path: ':managing/:action',
                    element: <Admin />,
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

const Admin = () => (
    <AdminProvider>
        <AdminHome />
    </AdminProvider>
);

export default Routes;
