import React from 'react';
import { Box, Typography } from '@mui/material';
import {
	convertAllCapsToSentenceCase,
	convertCamelCaseToTitleCase,
	isAllCaps,
} from '../../../helpers/stringUtils';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import AllCapsIcon from '../../shared/icons/AllCapsSmall';
import { findTagValues } from '../../../helpers/tagUtils';
import CommentsTable from '../servant-leadership/CommentsTable';

const EXCLUDED_RESPONSES = [
	'n/a',
	'na',
	'null',
	'none',
	'no opinion',
	'no comment',
	'no comments',
	'no response',
	'no answer',
	'not applicable',
	'',
	' ',
	'...',
];

const possibleAppendices = {
	removed: {
		icon: <PlaylistRemoveIcon />,
		text: `Excluded Responses: ${EXCLUDED_RESPONSES.map(r =>
			r === ' ' ? '" "' : r === '' ? '""' : r
		).join(', ')}`,
	},
	allCapsConv: {
		icon: <AllCapsIcon size='medium' />,
		text: `Converted all caps to sentence case`,
	},
};

const ensureValue = response => {
	if (!response) return false;
	// make sure value can have the toLowerCase method called on it
	if (typeof response !== 'string') return false;
	// make sure response isn't one of the excluded responses
	if (EXCLUDED_RESPONSES.includes(response.trim().toLowerCase())) return false;

	return true;
};

const MetadataComponent = ({ metadata }) => {
	return (
		<Box>
			{Object.entries(metadata).map(([key, value]) => (
				<Typography key={key} variant='body2' color='text.secondary'>
					{key !== 'surveyName' && <strong>{convertCamelCaseToTitleCase(key)}: </strong>}
					{value}
				</Typography>
			))}
		</Box>
	);
};

const initialAppendixState = {
	removed: true,
	allCapsConv: false,
};

const CommentWrapper = ({ globalSurveyId, data, surveys, attributes }) => {
	const [formattedData, setFormattedData] = React.useState([]);
	const [appendix, setAppendix] = React.useState(initialAppendixState);

	// Format comment data hook
	React.useEffect(() => {
		const survey = surveys.find(s => s.uuid === globalSurveyId);
		const yearData = data[survey.uuid].year['2023']; // TODO: make this dynamic
		if (data && survey && yearData) {
			// build array of objects with start, keep, stop, comments
			// these are the last 4 questions in the survey, so we can just grab the last 4 responses
			const formattedData = yearData.map(s => {
				// need an element for the metadata as well
				const tags = findTagValues(s.pitTags, attributes);
				return [
					{
						metadata: {
							surveyName: survey.metadata.name,
							year: '2023',
							code: s.code,
							...tags,
						},
					},
					...s.responses.slice(-4).map(qr => qr),
				];
			});

			if (formattedData && survey) {
				const rows = formattedData
					.filter(fdRow => fdRow.some(fb => ensureValue(fb.response)))
					.map(fdRow => {
						const formattedRow = {
							metadata: <MetadataComponent metadata={fdRow[0].metadata} />,
							start: ensureValue(fdRow[1].response) ? fdRow[1]?.response : '',
							stop: ensureValue(fdRow[2].response) ? fdRow[2]?.response : '',
							keep: ensureValue(fdRow[3].response) ? fdRow[3]?.response : '',
							comments: ensureValue(fdRow[4].response) ? fdRow[4]?.response : '',
							sortKey: fdRow[0].metadata.leader,
						};
						return formattedRow;
					})
					.map(fr => {
						const startAllCaps = isAllCaps(fr.start);
						const stopAllCaps = isAllCaps(fr.stop);
						const keepAllCaps = isAllCaps(fr.keep);
						const commentsAllCaps = isAllCaps(fr.comments);
						if (startAllCaps || stopAllCaps || keepAllCaps || commentsAllCaps) {
							setAppendix({ ...appendix, allCapsConv: true });
						}
						return {
							metadata: fr.metadata,
							start: startAllCaps
								? convertAllCapsToSentenceCase(fr.start, <AllCapsIcon />)
								: fr.start,
							stop: stopAllCaps
								? convertAllCapsToSentenceCase(fr.stop, <AllCapsIcon />)
								: fr.stop,
							keep: keepAllCaps
								? convertAllCapsToSentenceCase(fr.keep, <AllCapsIcon />)
								: fr.keep,
							comments: commentsAllCaps
								? convertAllCapsToSentenceCase(fr.comments, <AllCapsIcon />)
								: fr.comments,
							sortKey: fr.sortKey,
						};
					});

				const sorted = rows.sort((a, b) => {
					if (a.sortKey < b.sortKey) {
						return -1;
					}
					if (a.sortKey > b.sortKey) {
						return 1;
					}
					return 0;
				});
				setFormattedData(sorted);
			}
		}
	}, [data]);

	return (
		<>
			<Typography variant='h5'>All Comments for 2023 SL</Typography>

			{appendix && Object.values(appendix).includes(true) && (
				<Box mt={2}>
					<Typography variant='h6'>Appendix</Typography>
					<Typography variant='subtitle2'>
						{Object.keys(appendix).map((key, i) => {
							if (appendix[key]) {
								return (
									<Box display='flex' alignItems='center' mb={1}>
										{possibleAppendices[key].icon}
										{possibleAppendices[key].text}
									</Box>
								);
							}
						})}
					</Typography>
				</Box>
			)}

			<CommentsTable data={formattedData} />
			{/* <Grid rows={formattedData || []} columns={columns}>
				<Table rowComponent={RowComponent} columnExtensions={tableColumnExtensions} />
				<TableColumnResizing
					columnWidths={columnWidths || []}
					onColumnWidthsChange={setColumnWidths}
				/>
				<TableHeaderRow />
			</Grid> */}
		</>
	);
};

export default CommentWrapper;
