import Star from '@mui/icons-material/Star';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Hidden from '@mui/material/Hidden';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import React from 'react';

const Scale = ({ question, handleAnswer, optional }) => {
	const { options, response } = question;
	const { labels } = options;

	const [hover, setHover] = React.useState(-1);
	const [showAnswerKey, setShowAnswerKey] = React.useState(false);

	const getLabelText = value => {
		return labels[value] || 'No Selection Made';
	};

	const AnswerKey = () => (
		<Dialog open={showAnswerKey} onClose={() => setShowAnswerKey(false)}>
			<DialogTitle sx={{ textAlign: 'center' }}>Answer Key</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				{Object.keys(labels).map((key, i) => (
					<Box display='flex' justifyContent='space-between'>
						<Box pr={1}>
							<Rating
								value={parseInt(key)}
								min={parseInt(key)}
								max={parseInt(key)}
								size='small'
								emptyIcon={<Star fontSize='.7rem' opacity={0.55} key={i} />}
							/>
						</Box>
						<Typography fontSize='.7rem'>{labels[key]}</Typography>
					</Box>
				))}
				{optional && (
					<Box display='flex' justifyContent='space-between'>
						<Button variant='outlined' size='small'>
							No Opinion
						</Button>
						<Typography fontSize='.7rem'>No Opinion</Typography>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);

	return (
		<Box display='flex' flexDirection='column' alignItems='center' minWidth='50%'>
			<Rating
				value={response}
				min={options.min || 1}
				max={options.max || 5}
				precision={options.step || 1}
				size='large'
				getLabelText={getLabelText}
				onChange={(event, newValue) => {
					handleAnswer(newValue);
				}}
				onChangeActive={(event, newHover) => {
					setHover(newHover);
				}}
				emptyIcon={<Star style={{ opacity: 0.55 }} fontSize='large' />}
				sx={{
					'& .MuiRating-icon': {
						p: 0.75,
					},
				}}
			/>

			{optional && (
				<Button
					variant='outlined'
					onClick={() => handleAnswer('NONE')}
					sx={{ mt: 1 }}
					size='small'
				>
					No Opinion
				</Button>
			)}

			<Typography variant='subtitle2' mt={2}>
				Your Response: {getLabelText(hover !== -1 ? hover : response)}
			</Typography>

			<Hidden mdUp>
				<Button size='small' onClick={() => setShowAnswerKey(!showAnswerKey)}>
					Show Answer Key
				</Button>
				{showAnswerKey && <AnswerKey />}
			</Hidden>
		</Box>
	);
};

export default Scale;
