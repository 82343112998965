export const supportedQuestionTypes = [
    {
        uuid: '35545142-b3aa-4031-b772-6e799e096e68',
        name: 'scale (1-5)',
        type: 'scale',
        min: 1,
        max: 5,
        step: 1,
        labels: {
            1: 'Strongly Disagree (Lowest)',
            2: 'Disagree',
            3: 'Neutral',
            4: 'Agree',
            5: 'Strongly Agree (Highest)',
        },
    },
    {
        uuid: '654be51d-fca3-4d2e-b4a7-66a40c0f29d7',
        type: 'free',
    },
    {
        uuid: '98f68db1-1eac-4c3c-9556-56eb77a25041',
        name: 'scale (1-5) optional',
        type: 'opt_scale',
        min: 1,
        max: 5,
        step: 1,
        labels: {
            1: 'Strongly Disagree (Lowest)',
            2: 'Disagree',
            3: 'Neutral',
            4: 'Agree',
            5: 'Strongly Agree (Highest)',
        },
    },
    {
        uuid: 'feb3e922-1958-4bce-a7da-6adb56eb76c5',
        type: 'int',
    },
    {
        uuid: '63a9078a-668a-471c-b242-3587c9002b5f',
        name: 'military branch',
        type: 'choice',
        min: 1,
        max: 5,
        step: 1,
        labels: {
            1: 'Army',
            2: 'Navy',
            3: 'Air Force',
            4: 'Marines',
            5: 'Coast Guard',
        },
    },
    {
        uuid: 'be71ba8d-3cf7-4e6c-9960-8a0dbf3569eb',
        name: 'military service time',
        type: 'choice',
        min: 1,
        max: 5,
        step: 1,
        labels: {
            1: '1-4 years',
            2: '5-9 years',
            3: '10-14 years',
            4: '15-19 years',
            5: '20+ years',
        },
    },
    {
        uuid: 'dc15e13a-60d1-4003-84c4-bbdc986fb8fa',
        type: 'yes_no',
    },
    {
        uuid: 'bd419e72-2bff-416d-8976-44bd97e23c7c',
        name: '% rated',
        min: 0,
        max: 100,
        step: 10,
        type: 'choice',
        labels: {
            0: '0%',
            10: '10%',
            20: '20%',
            30: '30%',
            40: '40%',
            50: '50%',
            60: '60%',
            70: '70%',
            80: '80%',
            90: '90%',
            100: '100%',
        },
    },
    {
        uuid: 'c439c325-8ee7-4966-9610-04351718bf80',
        name: 'Preffered contact method',
        type: 'choice',
        labels: {
            1: 'Email',
            2: 'Phone Call',
            3: 'Text Message',
            4: 'No Preference',
        },
    },
    {
        uuid: '00c6a3bf-75d2-469b-8adc-da872868645f',
        name: 'Email',
        type: 'email',
    },
    {
        uuid: 'b4c7f0e4-4f7b-4b8b-8b2f-0f9d6d3a0b3b',
        name: 'Phone Number',
        type: 'phone',
    },
    {
        uuid: '4b147445-91a2-47ce-bc79-a17d3c7848b1',
        name: `Please select all services you're interested in getting help with`,
        type: 'multiple_choice',
        labels: {
            aidAndAttendance: 'Aid and attendance',
            characterOfDischarge: 'Character of discharge upgrade',
            deniedClaim: 'Appealing a denied claim',
            dependantHealthInsr: 'Health insurance for your dependants (CHAMPVA)',
            fedResumes: 'Federal resumes',
            filingClaims: 'Filing a disability claim',
            giBill: 'GI Bill',
            highLevelReviews: 'High level reviews',
            housingAuto: 'Specially adapted housing/auto',
            increaseClaim: 'Increasing a disability claim',
            pactClaims: 'PACT claims',
            spouseResumes: 'Spouse resumes',
            specialMonthlyComp: 'Special monthly compensation',
            vhaHealthcase: 'VHA healthcare',
            vre: 'Vocational rehabilitation and employment (VR&E)',
        },
    },
    {
        uuid: '061b857f-1f7a-4e2e-926e-355e9a78aba1',
        name: 'Which Era did you serve in?',
        type: 'multiple_choice',
        labels: {
			1: 'WWII',
			2: 'Korea',
			3: 'Vietnam',
			4: 'Gulf War',
			5: 'Post 9/11',
        },
    },
];
