import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

const HierarchyTabs = ({ selectedTab, handleChangeTab, hierarchies }) => {
	return (
		<Tabs
			orientation='vertical'
			value={selectedTab}
			onChange={handleChangeTab}
			variant='scrollable'
			scrollButtons
			allowScrollButtonsMobile
			aria-label='scrollable auto tabs example'
			sx={{
				borderRight: 1,
				borderColor: 'divider',
				height: '50rem',
				width: '12rem',
				wordWrap: 'break-word',
			}}
		>
			<Tab
				sx={{ alignItems: 'flex-end' }}
				label={`${hierarchies.length > 0 ? 'Add New' : 'Add Your First Hierarchy!'}`}
				value='new'
				key='new'
			/>
			<Divider />
			{hierarchies.length &&
				hierarchies
					.sort((a, b) => a.deleted - b.deleted)
					.map(h => (
						<Tab
							value={h.uuid}
							key={h.uuid}
							label={
								h.deleted ? (
									<Typography
										variant='subtitle2'
										sx={{ textDecorationLine: 'line-through' }}
									>
										{h.name}
									</Typography>
								) : (
									<Typography variant='subtitle2'>{h.name}</Typography>
								)
							}
							sx={{ alignItems: 'flex-end' }}
						/>
					))}
		</Tabs>
	);
};

export default HierarchyTabs;
