import {
	Grid,
	Table,
	TableColumnResizing,
	TableHeaderRow,
	Toolbar,
	ExportPanel,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import Looks3Icon from '@mui/icons-material/Looks3';
import Looks4Icon from '@mui/icons-material/Looks4';
import Looks5Icon from '@mui/icons-material/Looks5';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Box } from '@mui/material';
import saveAs from 'file-saver';

const WeightedIcon = () => <FitnessCenterIcon fontSize='small' />;
const ResponseIcon = ({ response }) => {
	switch (response) {
		case 1:
			return <LooksOneIcon fontSize='small' />;
		case 2:
			return <LooksTwoIcon fontSize='small' />;
		case 3:
			return <Looks3Icon fontSize='small' />;
		case 4:
			return <Looks4Icon fontSize='small' />;
		case 5:
			return <Looks5Icon fontSize='small' />;
		default:
			return <NotInterestedIcon fontSize='small' />;
	}
};

const onSave = workbook => {
	workbook.xlsx.writeBuffer().then(buffer => {
		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Counts.xlsx');
	});
};

const CountsTable = ({ data }) => {
	const [rows, setRows] = React.useState([]);
	const [columns] = React.useState([
		{ name: 'surveyName', title: 'Survey' },
		{ name: 'tagGroup', title: 'Group' },
		{ name: 'tagValue', title: 'Value' },
		{ name: 'year', title: 'Year' },
		{ name: 'opt1s', title: '1s' },
		{ name: 'opt1sWeighted', title: '1Wgt' },
		{ name: 'opt2s', title: '2s' },
		{ name: 'opt2sWeighted', title: '2Wgt' },
		{ name: 'opt3s', title: '3s' },
		{ name: 'opt3sWeighted', title: '3Wgt' },
		{ name: 'opt4s', title: '4s' },
		{ name: 'opt4sWeighted', title: '4Wgt' },
		{ name: 'opt5s', title: '5s' },
		{ name: 'opt5sWeighted', title: '5Wgt' },
		{ name: 'opt6s', title: 'N/Os' },
		// { name: 'opt6sWeighted', title: 'N/O Wgt' },
		// { name: 'opt1s', title: <ResponseIcon response={1} /> },
		// { name: 'opt1sWeighted', title: <WeightedIcon /> },
		// { name: 'opt2s', title: <ResponseIcon response={2} /> },
		// { name: 'opt2sWeighted', title: <WeightedIcon /> },
		// { name: 'opt3s', title: <ResponseIcon response={3} /> },
		// { name: 'opt3sWeighted', title: <WeightedIcon /> },
		// { name: 'opt4s', title: <ResponseIcon response={4} /> },
		// { name: 'opt4sWeighted', title: <WeightedIcon /> },
		// { name: 'opt5s', title: <ResponseIcon response={5} /> },
		// { name: 'opt5sWeighted', title: <WeightedIcon /> },
		// { name: 'opt6s', title: <ResponseIcon response={6} /> },
		// { name: 'opt6sWeighted', title: <WeightedIcon /> },
		{ name: 'total', title: 'Total' },
		{ name: 'totalWeighted', title: 'Total Wgt' },
		// { name: 'totalWeighted', title: <WeightedIcon /> },
		{ name: 'avg', title: 'Avg' },
	]);

	const [columnWidths, setColumnWidths] = React.useState([
		{ columnName: 'surveyName', width: 225 },
		{ columnName: 'tagGroup', width: 100 },
		{ columnName: 'tagValue', width: 125 },
		{ columnName: 'year', width: 75 },
		{ columnName: 'opt1s', width: 50 },
		{ columnName: 'opt1sWeighted', width: 50 },
		{ columnName: 'opt2s', width: 50 },
		{ columnName: 'opt2sWeighted', width: 50 },
		{ columnName: 'opt3s', width: 50 },
		{ columnName: 'opt3sWeighted', width: 50 },
		{ columnName: 'opt4s', width: 50 },
		{ columnName: 'opt4sWeighted', width: 50 },
		{ columnName: 'opt5s', width: 50 },
		{ columnName: 'opt5sWeighted', width: 50 },
		{ columnName: 'opt6s', width: 50 },
		// { columnName: 'opt6sWeighted', width: 50 },
		{ columnName: 'total', width: 50 },
		{ columnName: 'totalWeighted', width: 75 },
		{ columnName: 'avg', width: 50 },
	]);

	const exporterRef = React.useRef(null);

	React.useEffect(() => {
		if (data) {
			const fd = data.map(d => {
				return {
					...d,
					total: d.opt1s + d.opt2s + d.opt3s + d.opt4s + d.opt5s,
					totalWeighted:
						d.opt1sWeighted +
						d.opt2sWeighted +
						d.opt3sWeighted +
						d.opt4sWeighted +
						d.opt5sWeighted,
				};
			});
			setRows(fd);
		}
	}, [data]);

	const startExport = React.useCallback(
		options => {
			exporterRef.current.exportGrid(options);
		},
		[exporterRef]
	);

	// console.log(rows);
	return (
		<Box>
			<Grid rows={rows} columns={columns} getRowId={row => row.id}>
				<Table />
				<TableColumnResizing
					columnWidths={columnWidths}
					onColumnWidthsChange={setColumnWidths}
				/>
				<TableHeaderRow />
				<Toolbar />
				<ExportPanel startExport={startExport} />
			</Grid>
			<GridExporter ref={exporterRef} rows={rows} columns={columns} onSave={onSave} />
		</Box>
	);
};

export default CountsTable;
