import React from 'react';
import { Button, Grid, TextField } from '@mui/material';

const Numeric = ({ config, question, handleAnswer }) => {
	const inputRef = React.useRef();

	const [value, setValue] = React.useState(question.response || '');

	React.useEffect(() => {
		// focus on input on mount and place cursor at end of text
		inputRef.current.focus();

		return () => {
			return () => {
				// clear ref on unmount
				inputRef.current = null;
			};
		};
	}, []);

	// record answer when user presses tab
	const handleKeyDown = React.useCallback(
		e => {
			if (e.key === 'Tab') {
				e.preventDefault();
				handleAnswer(value);
			}
		},
		[value, handleAnswer]
	);

	return (
		<Grid container display='flex' justifyContent='center'>
			<Grid item xs={12} md={10} display='flex' flexDirection='column' alignItems='center'>
				<TextField
					inputRef={inputRef}
					value={value || ''}
					type='number'
					onChange={e => setValue(e.target.value)}
					variant='outlined'
					fullWidth
					onKeyDown={handleKeyDown}
					helperText='Press tab or continue'
				/>
				<Button onClick={() => handleAnswer(value)}>Continue</Button>
			</Grid>
		</Grid>
	);
};

export default Numeric;
