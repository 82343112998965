import { KeyboardCapslock } from '@mui/icons-material';
import React from 'react';

const AllCapsIcon = ({ size }) => (
	<KeyboardCapslock
		fontSize={size || 'small'}
		sx={{
			verticalAlign: 'middle',
		}}
	/>
);

export default AllCapsIcon;
