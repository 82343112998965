import DeleteIcon from '@mui/icons-material/Delete';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';
import NewQuestion from './NewQuestion';
import Question from './Question';

const Questions = ({ survey, handleSurveyChange, globalConfig }) => {
	const [dragId, setDragId] = React.useState();
	const [questions, setQuestions] = React.useState(
		survey !== undefined
			? survey.questions.map((q, i) => {
					return {
						...q,
						order: i + 1,
					};
			  })
			: []
	);

	React.useEffect(() => {
		let cancelled = false;
		if (!cancelled) {
			handleSurveyChange({
				...survey,
				questions: questions.map(q => {
					return {
						...q,
						uuid: q.uuid,
						order: parseInt(q.order),
					};
				}),
			});
		}
		return () => (cancelled = true);
	}, [questions, dragId]);

	const handleDrag = e => {
		setDragId(e.currentTarget.id);
	};

	const handleDrop = e => {
		const dragBox = questions.find(q => q.uuid === dragId);
		const dropBox = questions.find(q => q.uuid === e.currentTarget.id);

		const dragBoxOrder = dragBox.order;
		const dropBoxOrder = dropBox.order;

		const newBoxState = questions.map(q => {
			if (q.uuid === dragId) {
				q.order = dropBoxOrder;
			}
			if (q.uuid === e.currentTarget.id) {
				q.order = dragBoxOrder;
			}
			return q;
		});

		setQuestions(newBoxState);
	};

	const handleQuestionTextChange = (questionId, text) => {
		// update text of question with id === questionId
		// update questions state
		setQuestions(
			questions.map(q => {
				if (q.uuid === questionId) {
					q.text = text;
				}
				return q;
			})
		);
	};

	const handleQuestionRequiredChange = (e, questionId) => {
		// update required of question with id === e.target.name
		// update questions state
		console.log(`Required: ${e.target.checked}: typeof ${typeof e.target.checked}`);
		setQuestions(
			questions.map(q => {
				if (q.uuid === questionId) {
					q.required = e.target.checked;
				}
				return q;
			})
		);
	};

	const handleQuestionTypeChange = (e, value, questionId) => {
		// update type of question with id === e.target.name
		// update questions state
		setQuestions(
			questions.map(q => {
				if (q.uuid === questionId) {
					q.type = value;
				}
				return q;
			})
		);
	};

	const handleQuestionDelete = uuid => {
		// soft delete the question with id === uuid
		// update questions state, moving deleted question to the end of the list
		setQuestions(
			questions
				.map(q => {
					if (q.uuid === uuid) {
						q.deleted = true;
						q.order = questions.length + 1;
					}
					return q;
				})
				.sort((a, b) => {
					if (a.deleted && !b.deleted) {
						return 1;
					}
					if (!a.deleted && b.deleted) {
						return -1;
					}
					return 0;
				})
		);
	};

	const handleQuestionRestore = uuid => {
		// restore the question with id === uuid
		// update questions state, moving restored question to the end of the list
		setQuestions(
			questions
				.map(q => {
					if (q.uuid === uuid) {
						delete q.deleted;
					}
					return q;
				})
				.sort((a, b) => {
					if (a.deleted && !b.deleted) {
						return 1;
					}
					if (!a.deleted && b.deleted) {
						return -1;
					}
					return 0;
				})
		);
	};

	const handleAddNewQuestion = newQuestion => {
		setQuestions([...questions, newQuestion]);
	};

	const responseTypeOptions = (globalConfig.global.supportedQuestionTypes || []).map(t => ({
		value: t.uuid,
		label: convertCamelCaseToTitleCase(t.type),
	}));

	const readonly = survey.global;

	return (
		<Grid container spacing={0}>
			<Grid item xs={1} textAlign='center'>
				{!readonly && (
					<Box display='flex' alignItems='center' flexWrap='nowrap'>
						<Tooltip title='Change question order by dragging the question row up or down'>
							<IconButton size='small' sx={{ p: 0 }}>
								<MoveUpIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title='Delete question'>
							<IconButton size='small' sx={{ p: 0 }}>
								<DeleteIcon />
							</IconButton>
						</Tooltip>
					</Box>
				)}
			</Grid>
			<Grid item xs={1}>
				<Typography variant='subtitle2'>
					<strong>Require Response</strong>
				</Typography>
			</Grid>
			<Grid item xs={2} textAlign='center'>
				<Typography variant='subtitle2'>
					<strong>Question Type</strong>
				</Typography>
			</Grid>
			<Grid item xs={readonly ? 9 : 8} textAlign='center'>
				<Typography variant='subtitle2'>
					<strong>Question</strong>
				</Typography>
			</Grid>
			<Divider />
			<Grid item xs={12}>
				{!readonly && (
					<NewQuestion
						responseTypeOptions={responseTypeOptions}
						handleAddNewQuestion={handleAddNewQuestion}
					/>
				)}
				{questions
					.sort((a, b) => a.order - b.order)
					.map(q => (
						<Question
							key={`${q.uuid}`}
							boxNumber={`${q.uuid}`}
							question={q}
							readonly={readonly}
							handleDrag={handleDrag}
							handleDrop={handleDrop}
							responseTypeOptions={responseTypeOptions}
							handleQuestionRequiredChange={handleQuestionRequiredChange}
							handleQuestionTypeChange={handleQuestionTypeChange}
							handleQuestionTextChange={handleQuestionTextChange}
							handleQuestionDelete={handleQuestionDelete}
							handleQuestionRestore={handleQuestionRestore}
						/>
					))}
			</Grid>
		</Grid>
	);
};

export default Questions;
