import {
	Grid,
	Table,
	TableColumnResizing,
	TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { Box, FormControlLabel, Switch, Typography } from '@mui/material';
import { convertAllCapsToSentenceCase, isAllCaps } from '../../../helpers/stringUtils';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import ChatGPTIcon from '../../shared/icons/ChatGPT';
import AllCapsIcon from '../../shared/icons/AllCapsSmall';
import { getSecret } from '../../../apis/aws-sdk-apis';

const EXCLUDED_RESPONSES = [
	'n/a',
	'na',
	'null',
	'none',
	'no opinion',
	'no comment',
	'no comments',
	'no response',
	'no answer',
	'not applicable',
	'',
	' ',
	'...',
];

const possibleAppendices = {
	removed: {
		icon: <PlaylistRemoveIcon />,
		text: `Excluded Responses: ${EXCLUDED_RESPONSES.map(r =>
			r === ' ' ? '" "' : r === '' ? '""' : r
		).join(', ')}`,
	},
	ai: {
		icon: (
			<Box pr={0.5}>
				<ChatGPTIcon height={25} width={25} />
			</Box>
		),
		text: `Generated by AI, please take this into account when reviewing`,
	},
	allCapsConv: {
		icon: <AllCapsIcon size='medium' />,
		text: `Converted all caps to sentence case`,
	},
};

const ensureValue = response => {
	if (!response) return false;
	// make sure value can have the toLowerCase method called on it
	if (typeof response !== 'string') return false;
	// make sure response isn't one of the excluded responses
	if (EXCLUDED_RESPONSES.includes(response.trim().toLowerCase())) return false;

	return true;
};

const RowComponent = ({ row, ...restProps }) => (
	<Table.Row
		{...restProps}
		// eslint-disable-next-line no-alert
		style={{
			fontSize: '.8em',
		}}
	/>
);

const CellComponent = ({ row, ...cellProps }) => (
	<Table.Cell
		{...cellProps}
		style={{
			fontSize: '.9em',
			padding: '5px',
		}}
	>
		{row.ai ? (
			<Box display='flex' flexDirection='column' alignItems='center'>
				<Box pb={0.5}>
					<ChatGPTIcon height={25} width={25} />
				</Box>
				{row[cellProps.column.name]}
			</Box>
		) : (
			cellProps.children
		)}
	</Table.Cell>
);

const initialAIState = {
	start: null,
	stop: null,
	keep: null,
	comments: null,
	all: null,
};
const initialAppendixState = {
	removed: true,
	ai: false,
	allCapsConv: false,
};

const LeaderComments = ({ data, filters, lookupData }) => {
	const [formattedData, setFormattedData] = React.useState([]);
	const [aiSummary, setAiSummary] = React.useState(initialAIState);
	const [appendix, setAppendix] = React.useState(initialAppendixState);
	const [showAI, setShowAI] = React.useState(false);
	const [generatingAI, setGeneratingAI] = React.useState(false);
	const [columns] = React.useState([
		{ name: 'start', title: `Start doing...` },
		{ name: 'stop', title: `Stop doing...` },
		{ name: 'keep', title: `Keep doing...` },
		{ name: 'comments', title: `Misc. Comments` },
	]);

	const [columnWidths, setColumnWidths] = React.useState([
		{ columnName: 'start', width: 250 },
		{ columnName: 'stop', width: 250 },
		{ columnName: 'keep', width: 250 },
		{ columnName: 'comments', width: 250 },
	]);

	const [tableColumnExtensions] = React.useState([
		{ columnName: 'start', align: 'left', wordWrapEnabled: true },
		{ columnName: 'stop', align: 'left', wordWrapEnabled: true },
		{ columnName: 'keep', align: 'left', wordWrapEnabled: true },
		{ columnName: 'comments', align: 'left', wordWrapEnabled: true },
	]);

	// Format comment data hook
	React.useEffect(() => {
		if (data && data.leader && filters.leader && filters.leader.value) {
			setAiSummary(initialAIState);
			setShowAI(false);
			const selectedLeader = data.leader[filters.leader.value];
			const survey = lookupData.surveys.find(s => s.uuid === filters.surveyId);
			// build array of objects with start, keep, stop, comments
			// these are the last 4 questions in the survey, so we can just grab the last 4 responses
			const formattedData = selectedLeader.map(s => s.responses.slice(-4).map(qr => qr));

			if (formattedData && survey) {
				const rows = formattedData
					.filter(fbRow => fbRow.some(fb => ensureValue(fb.response)))
					.map(fbRow => {
						const formattedRow = {
							start: ensureValue(fbRow[0].response) ? fbRow[0]?.response : '',
							stop: ensureValue(fbRow[1].response) ? fbRow[1]?.response : '',
							keep: ensureValue(fbRow[2].response) ? fbRow[2]?.response : '',
							comments: ensureValue(fbRow[3].response) ? fbRow[3]?.response : '',
						};
						return formattedRow;
					})
					.map(fr => {
						const startAllCaps = isAllCaps(fr.start);
						const stopAllCaps = isAllCaps(fr.stop);
						const keepAllCaps = isAllCaps(fr.keep);
						const commentsAllCaps = isAllCaps(fr.comments);
						if (startAllCaps || stopAllCaps || keepAllCaps || commentsAllCaps) {
							setAppendix({ ...appendix, allCapsConv: true });
						}
						return {
							start: startAllCaps
								? convertAllCapsToSentenceCase(fr.start, <AllCapsIcon />)
								: fr.start,
							stop: stopAllCaps
								? convertAllCapsToSentenceCase(fr.stop, <AllCapsIcon />)
								: fr.stop,
							keep: keepAllCaps
								? convertAllCapsToSentenceCase(fr.keep, <AllCapsIcon />)
								: fr.keep,
							comments: commentsAllCaps
								? convertAllCapsToSentenceCase(fr.comments, <AllCapsIcon />)
								: fr.comments,
						};
					});

				setFormattedData(rows);
			}
		}
	}, [data, filters]);

	// summarize the notes with chat gpt api
	React.useEffect(() => {
		let cancelled = false;
		const aiResponse = async () => {
			try {
				if (!showAI) return;
				if (aiSummary.all) return;
				if (formattedData && formattedData.length > 0) {
					const formatTypeSummary = (formattedData, type) => {
						const summary = formattedData.reduce((acc, row) => {
							const { props } = row[type];
							const { children } = props || {};
							const stringRes = children ? children[children.length - 1] : row[type];

							ensureValue(stringRes) && acc.push(stringRes);
							return acc;
						}, []);
						return summary;
					};

					const startSummary = formatTypeSummary(formattedData, 'start');
					const stopSummary = formatTypeSummary(formattedData, 'stop');
					const keepSummary = formatTypeSummary(formattedData, 'keep');
					const commentsSummary = formatTypeSummary(formattedData, 'comments');
					const summary = {
						start: `Start doing: ${startSummary.join('\n')}`,
						stop: `Stop doing: ${stopSummary.join('\n')}`,
						keep: `Keep doing: ${keepSummary.join('\n')}`,
						comments: `Additional Comments: ${commentsSummary.join('\n')}`,
					};

					// build the summary string
					const summaryString = Object.values(summary).join('<eom> \n');
					const summaries = {
						start: summary.start,
						stop: summary.stop,
						keep: summary.keep,
						comments: summary.comments,
						all: summaryString,
					};

					setGeneratingAI(true);
					const apiKey = await getSecret('prod/keys/openai');
					if (apiKey?.OPENAI_API_KEY && !cancelled) {
						const endpoint = 'https://api.openai.com/v1/completions';
						const headers = {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${apiKey.OPENAI_API_KEY}`,
						};
						const body = {
							model: 'text-davinci-003',
							prompt: `Summarize these comments excluding any joke related comments:\n\n $TYPE`,
							max_tokens: 256,
							temperature: 0,
							top_p: 1,
							frequency_penalty: 0,
							presence_penalty: 0,
						};

						// Refactored function using Promise.all()
						const fetchSummaries = async (endpoint, headers, body, summaries) => {
							const requests = Object.values(summaries).map(async value => {
								const thisBody = JSON.stringify({
									...body,
									prompt: body.prompt.replace('$TYPE', value),
								});
								const res = await fetch(endpoint, {
									method: 'POST',
									headers,
									body: thisBody,
								});
								if (!res.ok) {
									throw new Error('Failed to fetch AI summary');
								}
								const json = await res.json();
								return json.choices[0].text;
							});
							const aiSummaries = await Promise.all(requests).then(values =>
								Object.fromEntries(
									Object.entries(summaries).map((entry, index) => [
										entry[0],
										values[index],
									])
								)
							);

							if (Object.keys(aiSummaries).length > 0) {
								setAiSummary({
									start: aiSummaries.start,
									stop: aiSummaries.stop,
									keep: aiSummaries.keep,
									comments: aiSummaries.comments,
									all: aiSummaries.all,
								});
								// setGeneratingAI(false); Will wait until the next effect to set this
							} else {
								setGeneratingAI(false);
							}
						};

						fetchSummaries(endpoint, headers, body, summaries);
					}
				}
			} catch (error) {
				console.log(error);
				setGeneratingAI(false);
			}
		};
		aiResponse();

		return () => (cancelled = true);
	}, [formattedData, showAI]);

	React.useEffect(() => {
		let cancelled = false;

		const hasSummaryAlready = formattedData.findIndex(row => row.ai) > -1;

		const addAiSummaryRow = () => {
			if (!hasSummaryAlready && aiSummary.all && formattedData && formattedData.length > 0) {
				const aiSummaryRow = {
					start: aiSummary.start,
					stop: aiSummary.stop,
					keep: aiSummary.keep,
					comments: aiSummary.comments,
					ai: true,
				};
				const newFormattedData = [...formattedData, aiSummaryRow];
				return newFormattedData;
			}
			return formattedData;
		};

		if (!cancelled && showAI) {
			setFormattedData(addAiSummaryRow());
			setGeneratingAI(false);
		}
		return () => {
			cancelled = true;
		};
	}, [aiSummary]);

	React.useEffect(() => {
		if (!showAI && formattedData && formattedData.length > 0) {
			setFormattedData(formattedData.filter(row => !row.ai));
		} else if (showAI && aiSummary.all && formattedData && formattedData.length > 0) {
			const aiSummaryRow = {
				start: aiSummary.start,
				stop: aiSummary.stop,
				keep: aiSummary.keep,
				comments: aiSummary.comments,
				ai: true,
			};
			const newFormattedData = [...formattedData, aiSummaryRow];
			setFormattedData(newFormattedData);
		}
	}, [showAI]);

	return (
		<>
			{showAI && aiSummary.all && (
				<Box
					display='flex'
					justifyContent='space-between'
					alignItems='center'
					flexDirection='column'
				>
					<Typography variant='h5'>AI Feedback Summary</Typography>
					{aiSummary.all && (
						<>
							<Box display='flex' alignItems='center'>
								{possibleAppendices.ai.icon}
								<Typography variant='subtitle2'>
									<em>{possibleAppendices.ai.text}</em>
								</Typography>
							</Box>
							<Typography>{aiSummary.all}</Typography>
						</>
					)}
				</Box>
			)}

			<Box
				display='flex'
				justifyContent='space-between'
				alignItems='center'
				flexDirection='column'
			>
				<Typography variant='h5' mt={1}>
					Feedback Summary
				</Typography>
				<FormControlLabel
					control={
						<Switch
							checked={showAI}
							onChange={e => {
								setShowAI(e.target.checked);
								setAppendix({ ...appendix, ai: e.target.checked });
							}}
							name='showAI'
							color='primary'
						/>
					}
					label='Show AI Summary'
					sx={{
						displayPrint: 'none',
					}}
				/>
				{generatingAI && showAI && (
					<Box
						component='span'
						sx={{
							animation: 'spin 2s linear infinite',
							'@keyframes spin': {
								'0%': { transform: 'rotate(0deg)' },
								'100%': { transform: 'rotate(360deg)' },
							},
						}}
					>
						{possibleAppendices.ai.icon}
					</Box>
				)}
			</Box>

			<Grid rows={formattedData || []} columns={columns}>
				<Table
					rowComponent={RowComponent}
					cellComponent={CellComponent}
					columnExtensions={tableColumnExtensions}
				/>
				<TableColumnResizing
					columnWidths={columnWidths || []}
					onColumnWidthsChange={setColumnWidths}
				/>
				<TableHeaderRow />
			</Grid>
			{appendix && Object.values(appendix).includes(true) && (
				<Box mt={2}>
					<Typography variant='h6'>Appendix</Typography>
					<Typography variant='subtitle2'>
						{Object.keys(appendix).map((key, i) => {
							if (appendix[key]) {
								return (
									<Box display='flex' alignItems='center' mb={1}>
										{possibleAppendices[key].icon}
										{possibleAppendices[key].text}
									</Box>
								);
							}
						})}
					</Typography>
				</Box>
			)}
		</>
	);
};

export default LeaderComments;
