import {
	Grid,
	Table,
	TableColumnResizing,
	TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';
import { supportedQuestionTypes } from '../../../contexts/supportedQuestionTypes';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import {
	compareResponse,
	absDiff,
	findLeaderSelfEval,
	questionAvg,
	questionCountsByResponse,
} from '../../../helpers/surveyUtils';
import { formatTemplateStringVariable } from '../../../helpers/stringUtils';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { findAOIdFromPathString } from '../../../helpers/treeUtils';
import { Container, Typography } from '@mui/material';

const HeaderCellComponent = ({ ...cellProps }) => (
	<Table.Cell {...cellProps}>
		<Box display='flex' justifyContent='center'>
			{cellProps.column.title}
		</Box>
	</Table.Cell>
);

const CellComponent = ({ ...cellProps }) => {
	const { value, row, column } = cellProps;
	const { palette, highlights } = row || {};
	const { blindSpot, hiddenStrength, aboveAvg, belowAvg } = highlights;

	// if (column.name === 'leaderAvg') {
	return (
		<Table.Cell {...cellProps} style={{ padding: 5, margin: 0 }}>
			{column.name === 'leaderAvg' ? (
				<Box
					color={
						blindSpot || hiddenStrength
							? blindSpot
								? palette.error.contrastText
								: palette.success.contrastText
							: aboveAvg
							? palette.success.light
							: belowAvg
							? palette.error.light
							: 'inherit'
					}
					backgroundColor={
						blindSpot
							? palette.error.main
							: hiddenStrength
							? palette.success.main
							: 'transparent'
					}
					borderRadius={blindSpot || hiddenStrength ? 1 : 0}
					fontWeight='bold'
					textAlign='center'
				>
					{value}
				</Box>
			) : (
				<Box textAlign={column.name === 'question' ? 'left' : 'center'}>{value}</Box>
			)}
		</Table.Cell>
	);
};

const LeaderDetailByQuestion = ({ data, filters, lookupData, config }) => {
	const theme = useTheme();

	const [formattedData, setFormattedData] = React.useState([]);
	const [columns] = React.useState([
		{ name: 'question', title: 'Question' },
		{ name: 'plantAvg', title: 'P' },
		{ name: 'hourlyAvg', title: 'H' },
		{ name: 'salaryAvg', title: 'S' },
		{ name: 'leaderAvg', title: 'L' },
		{ name: 'total', title: '#' },
		{ name: 'one', title: `1's` },
		{ name: 'two', title: `2's` },
		{ name: 'three', title: `3's` },
		{ name: 'four', title: `4's` },
		{ name: 'five', title: `5's` },
		{ name: 'nos', title: `N/O's` },
		{ name: 'selfEval', title: `SE` },
	]);

	const [columnWidths, setColumnWidths] = React.useState([
		{ columnName: 'question', width: 500 },
		{ columnName: 'plantAvg', width: 50 },
		{ columnName: 'hourlyAvg', width: 50 },
		{ columnName: 'salaryAvg', width: 50 },
		{ columnName: 'leaderAvg', width: 50 },
		{ columnName: 'total', width: 50 },
		{ columnName: 'one', width: 35 },
		{ columnName: 'two', width: 35 },
		{ columnName: 'three', width: 35 },
		{ columnName: 'four', width: 35 },
		{ columnName: 'five', width: 35 },
		{ columnName: 'nos', width: 60 },
		{ columnName: 'selfEval', width: 50 },
	]);

	const [tableColumnExtensions] = React.useState([
		{ columnName: 'question', wordWrapEnabled: true },
	]);

	React.useEffect(() => {
		const { surveyTypes, leader, surveyId } = filters;
		const { surveys, attributes, hierarchies } = lookupData;
		if (data && data.leader && leader && leader.value) {
			const selectedLeader = data.leader[leader.value];
			const survey = surveys.find(s => s.uuid === surveyId);
			const staos = (attributes.find(a => a.key === 'Survey Type') || {}).options || []; // survey type attribute options
			const sal = staos
				.filter(st => surveyTypes.salary.includes(st.value))
				.map(st => st.uuid);
			const h = staos.filter(st => surveyTypes.hourly.includes(st.value)).map(st => st.uuid);

			const leaderId = findAOIdFromPathString(hierarchies, leader.value);
			// lookup leader attribute
			const leaderAttribute = getOptionFromAllById(attributes, leaderId);

			const { deltaThreshold } = config;

			const leaderScores = selectedLeader; // for readability
			if (survey) {
				const rows = survey.questions
					.filter(q => {
						const responseType = supportedQuestionTypes.find(
							qt => qt.uuid === q.responseTypeId
						);
						return responseType.type !== 'free'; // only get questions that are not free response (comments)
					})
					.map(q => {
						// get avgs
						const pAvg = questionAvg(data.surveyType, q.uuid);
						const hourlyAvg = questionAvg(data.surveyType, q.uuid, h);
						const salAvg = questionAvg(data.surveyType, q.uuid, sal);
						const leaderSE = findLeaderSelfEval(data.selfEvals, leaderAttribute.uuid); // this function cant use the filters.leader.value
						const seValue =
							leaderSE && leaderSE.responses
								? leaderSE.responses.find(r => r.questionId === q.uuid).response
								: 'N/A';
						const leaderAvg = questionAvg(data.leader, q.uuid, [leader.value]);
						const ones = questionCountsByResponse(leaderScores, q.uuid, 1);
						const twos = questionCountsByResponse(leaderScores, q.uuid, 2);
						const threes = questionCountsByResponse(leaderScores, q.uuid, 3);
						const fours = questionCountsByResponse(leaderScores, q.uuid, 4);
						const fives = questionCountsByResponse(leaderScores, q.uuid, 5);
						const nos = questionCountsByResponse(leaderScores, q.uuid, 'NONE');

						// calculated fields
						const highlights = {
							aboveAvg: compareResponse(leaderAvg, pAvg, '>'),
							belowAvg: compareResponse(leaderAvg, pAvg, '<'),
							blindSpot:
								seValue !== 'N/A' &&
								compareResponse(leaderAvg, seValue, '<') &&
								absDiff(leaderAvg, seValue) >= deltaThreshold,
							hiddenStrength:
								seValue !== 'N/A' &&
								compareResponse(leaderAvg, seValue, '>') &&
								absDiff(seValue, leaderAvg) >= deltaThreshold,
						};

						return {
							question: q.questionText.replace(
								'{leader}',
								formatTemplateStringVariable(leader.label) || 'My leader'
							),
							plantAvg: pAvg.toFixed(2),
							hourlyAvg: hourlyAvg.toFixed(2),
							salaryAvg: salAvg.toFixed(2),
							leaderAvg: leaderAvg.toFixed(2),
							total: ones + twos + threes + fours + fives + nos,
							one: ones,
							two: twos,
							three: threes,
							four: fours,
							five: fives,
							nos: nos,
							selfEval: seValue,
							palette: theme.palette,
							highlights,
						};
					});

				setFormattedData(rows);
			}
		}
	}, [data, filters, lookupData]);

	const { palette } = theme;
	return (
		<Container maxWidth={false}>
			<Typography variant='h6' align='center' py={2}>
				Leader Avg by Question
			</Typography>

			<Box id='legend' style={{ display: 'flex', justifyContent: 'space-around' }}>
				<Box px={1} color={palette.success.light}>
					<strong>Above Average</strong>
				</Box>
				<Box px={1} color={palette.error.light}>
					<strong>Below Average</strong>
				</Box>
				<Box
					px={1}
					backgroundColor={palette.error.main}
					color={palette.error.contrastText}
					borderRadius={4}
				>
					Blind Spot
				</Box>
				<Box
					px={1}
					backgroundColor={palette.success.main}
					color={palette.success.contrastText}
					borderRadius={4}
				>
					Hidden Strength
				</Box>
			</Box>
			<Box id='legend-2' style={{ display: 'flex', justifyContent: 'space-around' }}>
				<Box px={1}>P = Plant</Box>
				<Box px={1}>H = Hourly</Box>
				<Box px={1}>S = Salaried</Box>
				<Box px={1}>L = Your Avg</Box>
				<Box px={1}># = Survey Count</Box>
				<Box px={1}>N/O's = No Opinion's</Box>
				<Box px={1}>SE = Self Eval Response</Box>
			</Box>
			<Grid rows={formattedData || []} columns={columns}>
				<Table cellComponent={CellComponent} columnExtensions={tableColumnExtensions} />
				<TableColumnResizing
					columnWidths={columnWidths || []}
					onColumnWidthsChange={setColumnWidths}
				/>
				<TableHeaderRow cellComponent={HeaderCellComponent} />
			</Grid>
		</Container>
	);
};

export default LeaderDetailByQuestion;
