import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { read, utils, write } from 'xlsx';
import { callApiGWLambdaGetS3Item } from '../../../apis/api-gw';
import { getS3File } from '../../../apis/aws-sdk-apis';
import { addAttribute, addOption, getOptionByValue } from '../../../helpers/attributUtils';
import { convertTitleCaseToCamelCase, scrubBrackets } from '../../../helpers/stringUtils';
import { generateCodes } from '../../../helpers/surveyUtils';
import DumbLoading from '../../shared/DumbLoading';
import ImportTable from './ImportTable';

const ERROR_MESSAGES = {
	NO_FILE: 'Please select a file',
	NOT_CSV: 'Please select a CSV file',
	NO_SURVEY_NAME: 'Please enter a survey name',
	NO_SURVEY_FOUND: 'No survey found with the name',
	NO_BG_COLOR: 'Please enter a background color',
	NO_COLOR: 'Please enter a color',
	NO_NUMBER_OF_CODES: 'Please enter a number of codes',
	NO_TAGS: 'Please enter at least one tag',
	INVALID_DATA_FOUND: 'Invalid data found in file',
};

const ImportFromFile = ({
	hierarchies,
	attributes,
	surveys,
	handleBulkImport,
	fetchCodes,
	codes,
}) => {
	const navigate = useNavigate();

	const [data, setData] = React.useState([]);
	const [existingCodes, setExistingCodes] = React.useState(null);
	const [errors, setErrors] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	// When data changes, get codes for this survey that havent already been claimed.
	// we need to do this to avoid duplicate codes (overgeneration)
	React.useEffect(() => {
		const fetchSurveyCodes = async () => {
			if (data.length) {
				const surveyName = data[0].surveyName;
				const survey = surveys.find(survey => survey.metadata.name === surveyName);
				if (survey) {
					try {
						console.log('codes', codes);
						if (codes && codes[survey.uuid]) {
							const surveyCodes = codes[survey.uuid];
							setExistingCodes(surveyCodes.filter(code => !code.claimed));
							return;
						}

						setLoading(true);
						const fetchedCodes = await fetchCodes(survey.uuid, 250);
						console.log(fetchCodes);
						if (fetchedCodes) {
							const surveyCodes = fetchedCodes[survey.uuid];
							setExistingCodes(surveyCodes.filter(code => !code.claimed));
						}
						setLoading(false);
					} catch (err) {
						setExistingCodes([]);
						setLoading(false);
						console.log('error getting codes', err);
					}
				}
			}
		};
		fetchSurveyCodes();
	}, [data]);

	const handleLoadFile = async e => {
		try {
			const file = e.target.files[0];
			const rawData = await file.arrayBuffer();
			/* data is an ArrayBuffer */
			const workbook = read(rawData);
			// only read the first sheet
			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			// ignore the first row
			const json = utils.sheet_to_json(sheet, { header: 1, range: 1 });

			const header = json[0];
			const rows = json.slice(1);
			var tempErrors = [];
			const data = rows
				.filter(row => row.length === header.length)
				.map(row => {
					const rowId = uuidv4();
					if (!surveys.find(survey => survey.metadata.name === row[0])) {
						tempErrors.push({
							rowId: rowId,
							column: 'survey',
							message: ERROR_MESSAGES.NO_SURVEY_FOUND,
						});
					}
					const code = {
						rowId: rowId,
						surveyName: row[0],
						bgColor: row[1],
						color: row[2],
						numberOfCodes: row[3],
						tags: [],
					};
					header.forEach((header, index) => {
						if (index > 3) {
							code.tags.push({ key: header, value: `${row[index]}` }); // convert to string
						}
					});
					return code;
				});

			setErrors(tempErrors);
			setData(data);
		} catch (error) {
			console.log(error);
		}
	};

	const handleRemoveRow = rowId => {
		setData(data.filter(row => row.rowId !== rowId));
		setErrors(errors.filter(error => error.rowId !== rowId));
	};

	const handleImport = async () => {
		var tempAttributes = [...attributes];
		const codes = data.flatMap(code => {
			const tags = code.tags.map(tag => {
				const existingAttribute = tempAttributes.find(
					attribute => attribute.key === tag.key
				);
				if (!existingAttribute) {
					const newAttribute = addAttribute(tag.key, 'string');
					const newOption = addOption(tag.value);
					newAttribute.options.push(newOption);
					tempAttributes.push(newAttribute);
					return {
						key: convertTitleCaseToCamelCase(scrubBrackets(newAttribute.key)),
						value: newOption.uuid,
						hierarchyId: null,
						attributeId: newAttribute.uuid || null,
					};
				} else {
					const existingOption = getOptionByValue(existingAttribute, tag.value);
					if (!existingOption) {
						const newOption = addOption(tag.value);
						existingAttribute.options.push(newOption);
						return {
							key: convertTitleCaseToCamelCase(scrubBrackets(existingAttribute.key)),
							value: newOption.uuid,
							hierarchyId: null,
							attributeId: existingAttribute.uuid || null,
						};
					}
					// if the option already exists, check if the option is marked as deleted.
					// if it is, we need to restore it (set deleted to false) and update the tempAttributes array
					if (existingOption.deleted) {
						existingOption.deleted = false;
						const existingAttributeIndex = tempAttributes.findIndex(
							attribute => attribute.uuid === existingAttribute.uuid
						);

						(tempAttributes[existingAttributeIndex].options || []).map(option => {
							return option.uuid === existingOption.uuid ? existingOption : option;
						});
					}

					return {
						key: convertTitleCaseToCamelCase(scrubBrackets(existingAttribute.key)),
						value: existingOption.uuid,
						hierarchyId: null,
						attributeId: existingAttribute.uuid || null,
					};
				}
			});

			const survey = surveys.find(survey => survey.metadata.name === code.surveyName);
			if (!survey) {
				return;
			}
			if (!tags || (tags && tags.length === 0)) {
				return;
			}

			// check if this survey recipe already exists, and if so, how many codes are out there already.
			// if the number of codes to generate is greater than the number of codes that already exist, then we need to generate the difference
			// a recipe is a combination of surveyId and tags
			var foundCodeCount = 0;
			(existingCodes || []).forEach(existingCode => {
				if (existingCode.surveyId === survey.uuid) {
					if (
						tags.every(tag =>
							existingCode.tags.some(t => t.key === tag.key && t.value === tag.value)
						)
					) {
						foundCodeCount++;
					}
				}
			}, []);
			console.log('foundDupCount', foundCodeCount);
			const importCodeCount = parseInt(code.numberOfCodes);
			const codeCount =
				importCodeCount < foundCodeCount ? 0 : importCodeCount - foundCodeCount;

			const ids = generateCodes(codeCount);

			if (ids.length !== codeCount) {
				console.log(`Code Count mismatch. Expected ${codeCount}, got ${ids.length}`);
				return;
			}

			const ammendedTags = [
				{
					key: 'surveyId',
					value: survey.uuid,
					hierarchyId: null,
					attributeId: null,
				},
				...tags,
			];

			var generatedCodes = [];
			for (let i = 0; i < ids.length; i++) {
				const id = ids[i];
				const newCode = {
					id: id,
					surveyId: survey.uuid,
					createOn: new Date().toISOString(),
					bgColor: code.bgColor,
					color: code.color,
					tags: ammendedTags,
				};
				generatedCodes.push(newCode);
			}

			return generatedCodes;
		});

		console.log(codes);
		await handleBulkImport(codes, tempAttributes);
		setData([]);
		setErrors([]);
	};

	// 1. gets an object from an s3 bucket via a lambda function and our api (callApiGWLambdaGetS3Item('code-import-template.xlsx')), which returns an arraybuffer
	// 2. converts the arraybuffer to a blob
	// 3. converts the blob to a file
	// 4. downloads the file
	const handleGetTemplate = async () => {
		try {
			// const file = await getS3File('code-import-template.xlsx');
			// console.log(file);
			const response = await callApiGWLambdaGetS3Item('code-import-template.xlsx'); // call your API endpoint to get the arraybuffer
			const blob = new Blob([response.data], { type: response.headers['content-type'] }); // convert the arraybuffer to a blob
			console.log(blob);
			const file = new File([blob], 'code-import-template.xlsx', {
				type: response.headers['content-type'],
			}); // convert the blob to a file
			console.log(file);
			const url = window.URL.createObjectURL(file); // create a URL for the file
			const link = document.createElement('a'); // create a link element
			link.href = url; // set the URL as the href attribute of the link
			link.setAttribute('download', file.name); // set the download attribute of the link to the name of the file
			document.body.appendChild(link); // append the link to the document body
			link.click(); // simulate a click on the link to initiate the download
			document.body.removeChild(link); // remove the link from the document body once the download is complete
		} catch (error) {
			console.log(error);
			alert(error);
		}
	};

	console.log(existingCodes);
	return (
		<Grid container spacing={2} p={4}>
			{loading && (
				<DumbLoading text='Loading codes and locating any unused duplicates that could be re-used...' />
			)}
			<Grid item xs={12}>
				<Button variant='contained' onClick={() => navigate('/manage/codes')}>
					Back
				</Button>
			</Grid>
			<Grid item xs={12}>
				<Typography variant='h5'>Import from file</Typography>
			</Grid>
			<Grid item xs={6}>
				<TextField
					id='importFromFile'
					label='Import from file'
					type='file'
					onChange={handleLoadFile}
					InputLabelProps={{
						shrink: true,
					}}
					variant='outlined'
					fullWidth
					size='small'
					error={Boolean(errors.file)}
					helperText={errors.file}
				/>
			</Grid>
			<Grid item xs={6} display='flex' justifyContent='space-around'>
				<Button
					variant='contained'
					color='primary'
					onClick={() => {
						setData([]);
						setExistingCodes([]);
						setErrors([]);
					}}
					fullWidth
					size='small'
					disabled={data.length === 0}
					sx={{ mr: 1 }}
				>
					Clear
				</Button>
				<Button
					variant='contained'
					color='primary'
					onClick={handleImport}
					fullWidth
					disabled={data.length === 0 || errors.length > 0 || loading}
					size='small'
					sx={{ mr: 1 }}
				>
					Import
				</Button>
				{/* <Button onClick={handleGetTemplate} variant='contained' size='small' fullWidth>
					Get Template
				</Button> */}
			</Grid>
			{data && (
				<Grid item xs={12}>
					<ImportTable
						data={data}
						errors={errors}
						handleRemoveRow={handleRemoveRow}
						hierarchies={hierarchies}
						attributes={attributes}
						surveys={surveys}
					/>
				</Grid>
			)}
		</Grid>
	);
};

export default ImportFromFile;
