import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';

const TabPanel = ({ children, value, current, ...other }) => (
	<div
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		{...other}
	>
		{value === current && (
			<Box sx={{ p: 3 }}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</div>
);

const NewHierarchyPanel = ({
	selectedTab,
	attributes,
	handleNewHierarchy,
}) => {

	const [newHierarchy, setNewHierarchy] = React.useState({
		uuid: null,
		name: '',
		linkedAttributeKeyId: null,
		nodes: [],
		deleted: false,
	});

	// accept key down on Enter key as a submit
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			handleNewHierarchy(newHierarchy);
		}
	};

	return (
		<TabPanel key={0} value='new' current={selectedTab}>
			<Box display='flex' justifyContent='flex-start' alignItems='center' p={1}>
				<TextField
					id='newHierarchy'
					label='New Hierarchy'
					variant='outlined'
					value={newHierarchy.name}
					onChange={e => {
						setNewHierarchy({
							...newHierarchy,
							name: e.target.value,
						});
					}}
					onKeyDown={handleKeyDown}
					size='small'
				/>
				<Select
					id='linkedAttributeKeyId'
					label='Linked Attribute'
					value={newHierarchy.linkedAttributeKeyId || ''}
					onChange={e => {
						setNewHierarchy({
							...newHierarchy,
							linkedAttributeKeyId: e.target.value,
						});
					}}
					sx={{ minWidth: '10rem', ml: '1rem' }}
					size='small'
				>
					{attributes.map(attr => (
						<MenuItem key={attr.uuid} value={attr.uuid}>
							{convertCamelCaseToTitleCase(attr.key)}
						</MenuItem>
					))}
				</Select>
				<AddIcon sx={{ cursor: 'pointer' }} onClick={() => handleNewHierarchy(newHierarchy)} />
			</Box>
		</TabPanel>
	);
};

export default NewHierarchyPanel;
