import React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Snack = ({ message, severity, open, handleClose }) => (
	<Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
		<MuiAlert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
			{message}
		</MuiAlert>
	</Snackbar>
);

export default Snack;
