import React, { createContext } from 'react';
import { callApiGWLambda } from '../apis/api-gw';
import { AdminContext } from './AdminContext';
import dayjs from 'dayjs';
import { parseResultResponse } from '../helpers/lambdaResponseUtils';
import { getOptionFromAllByValue } from '../helpers/attributUtils';
import { filterComments, filterSurveysByTag } from '../helpers/surveyUtils';
import { findNodeFromAttributeOption, findPathString } from '../helpers/treeUtils';
import { generateFakeData } from '../apis/dev-data';
import getTenantEnv from '../apis/getEnv';

export const ReportContext = createContext(null);

export const ReportProvider = props => {
	const { config } = React.useContext(AdminContext);
	const [rawData, setRawData] = React.useState(null);
	const [processedData, setProcessedData] = React.useState(null);
	const [groupedData, setGroupedData] = React.useState({});
	const [historicalData, setHistoricalData] = React.useState([]);

	const fakeIt = () => {
		const env = getTenantEnv();
		if (env === 'dev' || env === 'test') {
			const surveys = config?.shared?.global?.surveys;
			if (!surveys) return;
			const fakeData = generateFakeData(config);
			setRawData(fakeData);
		}
	};

	// Load Historical Data
	const fetchHistoricalData = React.useCallback(async () => {
		console.log('Fetching historical data');
		const subdomain = window.location.hostname.split('.')[0];

		const queryBody = {
			groupId: subdomain,
		};
		const response = await callApiGWLambda('post', 'surveys/results/history', queryBody);

		console.log(response);
		if (response.status !== 200) {
			console.log('Error fetching historical data');
			return;
		}
		if (response.data.statusCode === 200) {
			const bodyJson = JSON.parse(response.data.body);
			if (bodyJson) {
				console.log(bodyJson);
				setHistoricalData(bodyJson);
			}
		}
	}, []);

	// Load completed surveys
	const fetchResults = React.useCallback(async (batchSize = 500) => {
		console.log('Fetching results');
		const subdomain = window.location.hostname.split('.')[0];
		const surveys = config?.shared?.global?.surveys;

		// changed to post request to pass body in instead of trying to parse json into a properly formatted query string
		const getSurveyResults = async (
			surveyId,
			lastEvaluatedKey,
			batchSize,
			retryAttempts = 0,
			data = {}
		) => {
			try {
				// console.log(`Fetching results for survey ${surveyId}`);
				if (!data[surveyId]) {
					data[surveyId] = [];
				}
				const queryBody = {
					groupId: subdomain,
					surveyId: surveyId,
					pageSize: batchSize,
					lastEvaluatedKey,
				};
				const response = await callApiGWLambda('post', 'surveys/results', queryBody);

				if (response.data.statusCode === 200) {
					const bodyJson = JSON.parse(response.data.body);
					if (bodyJson) {
						const newData = bodyJson.map(parseResultResponse);

						data[surveyId].push(...newData);
						const newLastEvaluatedKey = response.data.headers.LastEvaluatedKey;
						if (newLastEvaluatedKey) {
							const parsedHeader = JSON.parse(newLastEvaluatedKey);

							if (Object.keys(parsedHeader).length > 0) {
								const newQueryBody = {
									groupId: subdomain,
									surveyId: surveyId,
									pageSize: batchSize,
									lastEvaluatedKey: JSON.stringify({
										SurveyId: { S: parsedHeader.SurveyId['s'] },
										Id: { S: parsedHeader.Id['s'] },
									}),
								};

								await getSurveyResults(
									surveyId,
									newQueryBody.lastEvaluatedKey,
									batchSize,
									retryAttempts,
									data
								);
							}
						} else {
							return Promise.resolve(data);
						}
					} else {
						return Promise.resolve(data);
					}
				}
				return Promise.resolve(data);
			} catch (error) {
				console.log(error);
				console.log(`Error Record. LastEvaluatedKey: ${lastEvaluatedKey}`);
			}
		};

		switch (process.env.NODE_ENV) {
			// case 'development':
			// 	fakeIt();
			// 	break;
			default: {
				// we can go ahead and fetch historical data while we wait for the results
				fetchHistoricalData();

				const data = await Promise.all(
					(surveys || []).map(
						async s => await getSurveyResults(s.uuid, null, batchSize, null, {})
					)
				);
				console.log(data);
				// convert array to an object
				const dataObj =
					data &&
					data.reduce((acc, cur) => {
						return { ...acc, ...cur };
					}, {});
				console.log(dataObj);
				setRawData(dataObj);
			}
		}
	}, []);

	// process raw data
	React.useEffect(() => {
		var canceled = false;

		const processRawData = async () => {
			if (rawData && Object.keys(rawData).length > 0) {
				var tempProcessedData = {};
				Object.keys(rawData).forEach(surveyId => {
					const processedData = [];
					const surveyData = rawData[surveyId];

					surveyData.forEach(submission => {
						const { responses, pitTags, ...restSub } = submission || {};
						processedData.push({
							surveyId,
							...restSub,
							pitTags,
							responses,
						});
					});

					tempProcessedData[surveyId] = processedData;
				});

				if (!canceled) {
					setProcessedData(tempProcessedData);
				}
			}
		};

		processRawData();

		return () => {
			canceled = true;
		};
	}, [rawData]);

	// group processed data
	React.useEffect(() => {
		var canceled = false;

		const groupProcessedData = () => {
			try {
				if (processedData && Object.keys(processedData).length > 0) {
					var tempGroupedData = {};
					Object.entries(processedData).forEach(([surveyId, data]) => {
						const attributes = config.admin?.customerManaged?.attributes || [];
						const hierarchies = config.admin?.customerManaged?.hierarchies || [];
						const saao = getOptionFromAllByValue(attributes, 'Self Assessment');
						const selfAssessments = filterSurveysByTag(data, 'surveyType', saao.uuid);
						const allOther = data.filter(
							s => !selfAssessments.map(s => s.id).includes(s.id)
						);

						const grouped = {
							survey: {},
							year: {},
							selfEvals: selfAssessments,
							// tags
						};

						// array of any attributes that are linked to a hierarchy
						const linkedHAOIds = (hierarchies || []).map(h => h.linkedAttributeKeyId);
						// build groupings
						allOther.forEach(submission => {
							const { submittedOn } = submission || {};
							const surveyDt = dayjs(submittedOn);
							// init groupings and set any new keys to an empty array
							if (!grouped.survey[surveyId]) grouped.survey[surveyId] = [];
							grouped.survey[surveyId].push(submission);

							if (!grouped.year[surveyDt.year()]) grouped.year[surveyDt.year()] = [];
							grouped.year[surveyDt.year()].push(submission);

							submission.pitTags.forEach(tag => {
								const handleNonHAO = () => {
									if (!grouped[tag.key]) grouped[tag.key] = {};
									if (!grouped[tag.key][tag.value])
										grouped[tag.key][tag.value] = [];
									// add this submission to the grouping
									grouped[tag.key][tag.value].push(submission);
								};
								const hierarchy = hierarchies.find(
									h => h.linkedAttributeKeyId === tag.attributeId
								);
								if (!linkedHAOIds.includes(tag.attributeId) || !hierarchy) {
									handleNonHAO();
									return;
								}
								const hNode = findNodeFromAttributeOption(
									hierarchy.nodes,
									tag.value
								);
								if (!hNode) {
									console.log(tag);
									handleNonHAO();
									return;
								}
								const path = findPathString(hierarchy.nodes, hNode);
								if (!path) {
									console.log(tag);
									handleNonHAO();
									return;
								}
								if (!grouped[`${tag.key}`]) grouped[`${tag.key}`] = {};
								if (!grouped[`${tag.key}`][path]) grouped[`${tag.key}`][path] = [];
								// add this submission to the grouping
								grouped[`${tag.key}`][path].push(submission);
							});
						});

						tempGroupedData[surveyId] = grouped;
					});

					if (!canceled) {
						setGroupedData(tempGroupedData);
					}
				}
			} catch (error) {
				console.log(error);
			}
		};

		groupProcessedData();

		return () => {
			canceled = true;
		};
	}, [processedData]);

	// console.log(rawData);
	// console.log(processedData);
	// console.log(groupedData);
	return (
		<ReportContext.Provider value={{ config, fetchResults, groupedData, historicalData }}>
			{props.children}
		</ReportContext.Provider>
	);
};

// Path: src\contexts\ReportContext.js
// React Context that is shared between admin components
