import Box from '@mui/material/Box';
import React from 'react';
import { avgAllResponses } from '../../../helpers/surveyUtils';
import {
	findAOIdFromPathString,
	findNode,
	findNodeFromAttributeOption,
	getHierarchyFromNodeId,
	isChildOf,
} from '../../../helpers/treeUtils';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import HierarchyBar from '../generics/HierarchyBar';
import { Container, Typography } from '@mui/material';

const initConfig = {
	keys: [], // keys to group by
	defs: [],
	fill: [],
	legends: [
		{
			dataFrom: 'keys',
			anchor: 'bottom-right',
			direction: 'column',
			justify: false,
			translateX: 120,
			translateY: 0,
			itemsSpacing: 2,
			itemWidth: 100,
			itemHeight: 20,
			itemDirection: 'left-to-right',
			itemOpacity: 0.85,
			symbolSize: 20,
			effects: [
				{
					on: 'hover',
					style: {
						itemOpacity: 1,
					},
				},
			],
		},
	],
	margins: { mt: 50, mr: 200, mb: 125, ml: 75 },
	indexBy: 'name',
	// groupMode: 'grouped', // 'stacked' or 'grouped'
};

const OrgHierarchyWrapper = ({ data, filters, lookupData, aggregate, config: eConfig }) => {
	const [config] = React.useState({
		...initConfig,
		keys: eConfig?.thresholdGroups.map(group => {
			return group.label;
		}),
	});
	const [formattedData, setFormattedData] = React.useState([]);
	const [nodeMap, setNodeMap] = React.useState(new Map());
	const [hierarchyErrors, setHierarchyErrors] = React.useState([]);

	React.useEffect(() => {
		var map = new Map();
		Object.entries(data).forEach(([path, submissions]) => {
			/*
			need to accumulate the data for each node in the path
			e.g.
			{
				path: 'dept1>dept1.1>dept1.1.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.2'
				submissions: [all the submissions for that path]
			}
			should produce a map like this:
			{
				dept1: [all the submissions for dept1],
				dept1.1: [all the submissions for dept1.1],
				dept1.1.1: [all the submissions for dept1.1.1],
				dept1.2: [all the submissions for dept1.2],
				dept1.2.1: [all the submissions for dept1.2.1],
				dept1.2.2: [all the submissions for dept1.2.2],
			}
			*/
			const nodePath = path.split('>');

			if (aggregate) {
				nodePath.forEach(node => {
					if (!map.has(node)) {
						map.set(node, []);
					}
					map.get(node).push(...submissions);
				});
			} else {
				// last node is the org node
				const orgNode = nodePath[nodePath.length - 1];
				if (!map.has(orgNode)) {
					map.set(orgNode, []);
				}
				map.get(orgNode).push(...submissions);
			}
		});

		setNodeMap(map);
	}, [data]);

	React.useEffect(() => {
		const { attributes, hierarchies } = lookupData;
		const { thresholdGroups } = eConfig || {};

		// now calc the avgs of all the submissions within each node in the map
		// also need to lookup the name of the node (key)
		const formattedData = [];
		const tmpHierarchyErrors = [];
		nodeMap.forEach((submissions, key) => {
			const hierarchy = getHierarchyFromNodeId(hierarchies, key);

			if (!hierarchy) {
				const option = getOptionFromAllById(attributes, key);
				return tmpHierarchyErrors.push(
					option?.value
						? `${option.value} is not included in any hierarchy. Please go to the hierarchy page to add it.`
						: 'No hierarchy or attribute option found. If something was recently added, please refresh the page.'
				);
			}

			const { nodes } = hierarchy || {};
			const nodeAOId = findAOIdFromPathString(hierarchies, key);
			const option = getOptionFromAllById(attributes, nodeAOId);
			const isChildOfSelectedOrg = checkIsChildOfSelectedOrg(nodes, attributes, key);

			// hacky way to do this, but the value needs to be unique for grouping to work. handle the hiding in the nivo component
			const name = isChildOfSelectedOrg ? option.value || 'n/a' : `hide|${option.value}`;
			const avg = avgAllResponses(submissions);

			// find which threshold group this avg belongs to
			const thresholdGroup = thresholdGroups.find(group => {
				const { min, max } = group;
				return avg >= min && avg <= max;
			});
			const color = thresholdGroup?.color || '#000000';

			formattedData.push({
				name: name,
				[thresholdGroup.label]: isNaN(avg) ? 0 : avg,
				[`${thresholdGroup.label}Color`]: color,
				sortValue: isNaN(avg) ? 0 : avg,
			});
		});

		// sort the data by avg
		const sorted = formattedData.sort((a, b) => b.sortValue - a.sortValue);
		setFormattedData(sorted);
		setHierarchyErrors(tmpHierarchyErrors);
	}, [nodeMap, filters, lookupData]);

	const checkIsChildOfSelectedOrg = (nodes, attributes, key) => {
		try {
			const { org } = filters;
			const orgNode = findNodeFromAttributeOption(nodes, org.orgId);
			if (!orgNode) {
				const orgOption = getOptionFromAllById(attributes, org.orgId);
				if (orgOption && orgOption.links) {
					for (const id of orgOption.links) {
						const linkedOrgNode = findNodeFromAttributeOption(nodes, id);
						const isChild = isChildOf(nodes, linkedOrgNode.uuid, key);
						if (isChild) return true;
					}
					return false;
				} else {
					// no linked attributes, last attempt is to try and find a survey tag with the org id
					return true; // for now, assuming its an org report and not an individual report
				}
			}
			return isChildOf(nodes, orgNode.uuid, key);
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	return (
		<Container maxWidth={false} disableGutters>
			<Box height={'90%'} width={'100%'}>
				<HierarchyBar data={formattedData} config={config} />
			</Box>
			{hierarchyErrors.length > 0 && (
				<>
					<Typography variant='subtitle2' color='error'>
						Errors:
					</Typography>
					{hierarchyErrors.map((error, i) => (
						<Typography key={i} variant='body2'>
							{error}
						</Typography>
					))}
				</>
			)}
		</Container>
	);
};

export default OrgHierarchyWrapper;
