// install (please try to align the version of installed @nivo packages)
// yarn add @nivo/marimekko
import { Box } from '@mui/material';
import { ResponsiveMarimekko } from '@nivo/marimekko';

const TT = ({ bar, dimensions }) => {
	const { datum, /*formattedValue,*/ id, key, value, ...style } = bar;
	const formattedValue = `${(value * 100).toFixed(2)}%`;
	const dim = dimensions.find(d => d.id === id);
	const participation = datum.data.participation;
	const dimData = datum.data[dim.value];
	return (
		<Box
			style={{
				background: 'white',
				color: 'inherit',
				fontSize: 'inherit',
				borderRadius: '2px',
				boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
				padding: '5px 9px',
			}}
		>
			<Box display='flex' alignItems='center' whiteSpace='pre'>
				<Box
					style={{
						display: 'block',
						width: '12px',
						height: '12px',
						background: style.color,
						marginRight: '7px',
					}}
				/>
				<Box display='flex' flexDirection='column'>
					{!datum.id.includes('hide|') && (
						<Box
							component='span'
							fontWeight='bold'
							sx={{ textDecoration: 'underline' }}
						>
							{datum.id}
						</Box>
					)}
					<Box component='span' fontStyle='italic'>
						{id}
					</Box>
					<Box component='span'>
						Average: <strong>{formattedValue}</strong>
					</Box>
					<Box component='span'>{`Participation: ${participation}`}</Box>
					<Box component='span'>{`${id} Count: ${dimData}`}</Box>
				</Box>
			</Box>
		</Box>
	);
};

const Labels = ({ data: d }) => {
	// console.log(d);
	const dw = d.dimensions[0].width;
	const duw = dw + d.dimensions[1].width;
	const dumw = duw + d.dimensions[2].width;
	const dumhw = dumw + d.dimensions[3].width;
	// console.log('w', dw, duw, dumw, dumhw);
	const dex = dw / 2;
	const uex = duw - d.dimensions[1].width / 2;
	const mex = dumw - d.dimensions[2].width / 2;
	const hex = dumhw - d.dimensions[3].width / 2;
	const xP = 0;
	// console.log('x', dex, uex, mex, hex);
	const y = d.dimensions[0].y + d.dimensions[0].height / 2 + 5;

	return (
		<>
			{/* Participation */}
			<g
				key={`participation-${d.index}`}
				transform={`translate(${d.x - 5}, ${y - 5}) rotate(-90)`}
			>
				<text
					textAnchor='middle'
					style={{
						fill: '#999',
					}}
				>
					{d.data.participation}
				</text>
			</g>
			{/* Q1 (E.g. Disengaged) */}
			{d.data.quad1 > 0 && (
				<g key={`quad1-${d.index}`} transform={`translate(${dex + xP}, ${y})`}>
					<text
						textAnchor='middle'
						style={{
							fill: '#fff',
							fontSize: '1.2rem',
						}}
					>
						{d.data.quad1}
					</text>
				</g>
			)}

			{/* Q2 (E.g. Under Engaged) */}
			{d.data.quad2 > 0 && (
				<g key={`quad2-${d.index}`} transform={`translate(${uex + xP}, ${y})`}>
					<text
						textAnchor='middle'
						style={{
							fill: '#000',
							fontSize: '1.2rem',
						}}
					>
						{d.data.quad2}
					</text>
				</g>
			)}

			{/* Q3 (E.g. Moderately Engaged) */}
			{d.data.quad3 > 0 && (
				<g key={`quad3-${d.index}`} transform={`translate(${mex + xP}, ${y})`}>
					<text
						textAnchor='middle'
						style={{
							fill: '#000',
							fontSize: '1.2rem',
						}}
					>
						{d.data.quad3}
					</text>
				</g>
			)}

			{/* Q4 (E.g. Highly Engaged) */}
			{d.data.quad4 > 0 && (
				<g key={`quad4-${d.index}`} transform={`translate(${hex + xP}, ${y})`}>
					<text
						textAnchor='middle'
						style={{
							fill: '#000',
							fontSize: '1.2rem',
						}}
					>
						{d.data.quad4}
					</text>
				</g>
			)}
			{/* Right Labels */}
			<g key={`right-${d.index}`} transform={`translate(${d.width + 5}, ${y})`}>
				<text
					style={{
						fontSize: '0.8rem',
						fill: '#999',
					}}
				>
					{d?.id?.includes('hide|') ? '' : d.id}
				</text>
			</g>
		</>
	);
};

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyMarimekko = ({ data, config }) => {
	const { id, value, dimensions, margins, legends } = config;
	const { mt, mr, mb, ml } = margins || { mt: 50, mr: 250, mb: 20, ml: 50 };

	return (
		<ResponsiveMarimekko
			data={data}
			id={id} // 'statement'
			value={value} // 'participation'
			dimensions={dimensions}
			// ^ required ^
			// valueFormat='>-1.2f'
			layout='horizontal'
			offset='expand'
			margin={{ top: mt, right: mr, bottom: mb, left: ml }}
			innerPadding={15}
			// only apply padding on every 4th bar
			borderWidth={1}
			colors={{ scheme: 'spectral' }}
			tooltip={({ bar }) => <TT bar={bar} dimensions={dimensions} />}
			borderColor={{
				from: 'color',
				modifiers: [['darker', 0.2]],
			}}
			// defs={[
			// 	{
			// 		id: 'quad1',
			// 		type: 'patternLines',
			// 		// light diagonal lines
			// 		background: 'inherit',
			// 		color: '#e0e0e0',
			// 		rotation: -45,
			// 		lineWidth: 4,
			// 		spacing: 8,
			// 	},
			// 	{
			// 		id: 'quad2',
			// 		type: 'patternSquares',
			// 		// light offset squares
			// 		spacing: 6,
			// 		rotation: 0,
			// 		background: 'inherit',
			// 		color: '#e0e0e0',
			// 		lineWidth: 4,
			// 	},
			// 	{
			// 		id: 'quad3',
			// 		type: 'patternLines',
			// 		// light diagonal lines (reverse)
			// 		background: 'inherit',
			// 		color: '#e0e0e0',
			// 		rotation: 45,
			// 		lineWidth: 4,
			// 		spacing: 8,
			// 	},
			// 	{
			// 		id: 'quad4',
			// 		type: 'patternCircles',
			// 		// light circles
			// 		background: 'inherit',
			// 		color: '#e0e0e0',
			// 		lineWidth: 4,
			// 		spacing: 8,
			// 	},
			// ]}
			// fill={[
			// 	{
			// 		match: {
			// 			id: dimensions[0].id,
			// 		},
			// 		id: 'quad1',
			// 	},
			// 	{
			// 		match: {
			// 			id: dimensions[1].id,
			// 		},
			// 		id: 'quad2',
			// 	},
			// 	{
			// 		match: {
			// 			id: dimensions[2].id,
			// 		},
			// 		id: 'quad3',
			// 	},
			// 	{
			// 		match: {
			// 			id: dimensions[3].id,
			// 		},
			// 		id: 'quad4',
			// 	},
			// ]}
			legends={
				legends || [
					{
						anchor: 'top',
						direction: 'row',
						translateY: -30,
						itemsSpacing: 0,
						itemWidth: 250,
						itemHeight: 18,
						itemTextColor: '#999',
						itemDirection: 'right-to-left',
						itemOpacity: 1,
						symbolSize: 18,
						symbolShape: 'square',
						effects: [
							{
								on: 'hover',
								style: {
									itemTextColor: '#000',
								},
							},
						],
					},
				]
			}
			enableLabels={true}
			label={d => `${d.id} (${d.formattedValue})`}
			layers={[
				'grid',
				'bars',
				'legends',
				({ data }) => data.map(d => <Labels key={`labels-${d.index}`} data={d} />),
			]}
		/>
	);
};

export default MyMarimekko;
