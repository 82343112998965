import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import MyMarimekko from '../generics/Merimekko';
import { allNONEs, calcAvg, filterSurveysByTag } from '../../../helpers/surveyUtils';
import {
	depth,
	findAOIdFromPathString,
	findNode,
	getHierarchyFromNodeId,
	isChildOf,
	isChildOfDeepCheck,
} from '../../../helpers/treeUtils';
import { getOptionFromAllById } from '../../../helpers/attributUtils';

const MarimekkoWrapper = ({
	data,
	filters,
	lookupData,
	config,
	maxDepth,
	alwaysShowLabels,
	subgroup,
	aggregate,
	title = 'Marimekko Chart',
}) => {
	const [formattedData, setFormattedData] = React.useState([]);
	const [nodeMap, setNodeMap] = React.useState(new Map());
	const [hierarchyErrors, setHierarchyErrors] = React.useState([]);
	React.useEffect(() => {
		var map = new Map();
		Object.entries(data).forEach(([path, submissions]) => {
			/*
			need to accumulate the data for each node in the path
			e.g.
			{
				path: 'dept1>dept1.1>dept1.1.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.2'
				submissions: [all the submissions for that path]
			}
			should produce a map like this:
			{
				dept1: [all the submissions for dept1],
				dept1.1: [all the submissions for dept1.1],
				dept1.1.1: [all the submissions for dept1.1.1],
				dept1.2: [all the submissions for dept1.2],
				dept1.2.1: [all the submissions for dept1.2.1],
				dept1.2.2: [all the submissions for dept1.2.2],
			}
			*/
			// however, we need to handle the org and leader hierarchies differently
			const nodePath = path.split('>');
			switch (filters?.hierarchy) {
				case 'org':
					// add appropriate depth of nodes
					const node = nodePath[maxDepth];
					if (!map.has(node)) {
						map.set(node, []);
					}
					map.get(node).push(...submissions);
					break;
				case 'leader':
					if (aggregate) {
						nodePath.forEach(node => {
							if (!map.has(node)) {
								map.set(node, []);
							}
							map.get(node).push(...submissions);
						});
					} else {
						// only add the leaf node
						const node = nodePath[nodePath.length - 1];
						if (!map.has(node)) {
							map.set(node, []);
						}
						map.get(node).push(...submissions);
					}
					break;
				default:
					console.log(
						`invalid hierarchy passed in: ${filters?.hierarchy || 'undefined'}`
					);
					break;
			}
		});

		setNodeMap(map);
	}, [data]);

	React.useEffect(() => {
		const { attributes, hierarchies } = lookupData;
		const { thresholdGroups } = config || {};

		// now calc the avgs of all the submissions within each node in the map
		// also need to lookup the name of the node (key)
		const formattedData = [];
		const tmpHierarchyErrors = [];
		nodeMap.forEach((submissions, key) => {
			const hierarchy = getHierarchyFromNodeId(hierarchies, key);
			if (!hierarchy) {
				const option = getOptionFromAllById(attributes, key);
				return tmpHierarchyErrors.push(
					option?.value
						? `${option.value} is not included in any hierarchy. Please go to the hierarchy page to add it.`
						: `No hierarchy or attribute option found. If something was recently added, please refresh the page.`
				);
			}
			const { nodes } = hierarchy || {};
			const node = findNode(nodes, key);
			const nodeAOId = findAOIdFromPathString(hierarchies, key);
			const option = getOptionFromAllById(attributes, nodeAOId);
			const nodeDepth = depth(nodes, node);
			const isChildOfSelectedNode = isChildOfDeepCheck(
				nodes,
				attributes,
				key,
				filters[filters.hierarchy]
			);

			if (!isChildOfSelectedNode && !alwaysShowLabels) {
				return;
			}
			// handle the hiding in the nivo component
			const name =
				isChildOfSelectedNode || alwaysShowLabels
					? option?.value ?? 'n/a'
					: `hide|${option.value}`;

			var submissionThresholds = {};
			if (subgroup) {
				// split submissions into shifts
				const shiftAOs = attributes.find(attribute => attribute.key === 'Shift');
				const shifts = shiftAOs?.options.map(shift => {
					const shiftSubmissions = filterSurveysByTag(submissions, 'shift', shift.uuid);
					// filter out surveys where every response is none/no opinion-
					const validSubs = shiftSubmissions.filter(s => !allNONEs(s.responses));
					if (!validSubs) console.log('no valid subs', shiftSubmissions);
					return {
						name: shift.value,
						submissions: validSubs,
					};
				});
				// similar to the reduce function in the ELSE statement, but need to do this for each shift
				// e.g. shift-1-underengaged, shift-1-moderatelyengaged, shift-1-highlyengaged, shift-1-disengaged, shift-2-underengaged, shift-2-moderatelyengaged, etc.
				// need to do this because the data is grouped by shift, but the thresholds are the same for each shift
				const shiftThresholds =
					shifts?.map(shift => {
						return shift.submissions.reduce((acc, submission) => {
							const avg = calcAvg(submission.responses);
							if (!avg) return acc;
							const thresholdGroup = thresholdGroups.find(group => {
								return (
									avg.toFixed(2) >= group.min.toFixed(2) &&
									avg.toFixed(2) <= group.max.toFixed(2)
								);
							});
							if (!thresholdGroup)
								alert('!!thresholdGroup', avg, thresholdGroup, submission);
							if (!thresholdGroup) return acc;
							const thresholdGroupKey = `${shift.name}-${thresholdGroup.id}`;
							if (!acc[thresholdGroupKey]) {
								acc[thresholdGroupKey] = [];
							}

							acc[thresholdGroupKey].push(submission);
							return acc;
						}, {});
					}) ?? [];
				// now need to format the data for the nivo component
				const nodeShiftSummary =
					shiftThresholds?.map(shiftT => {
						try {
							if (Object.keys(shiftT).length === 0) return null;
							const shift = Object.keys(shiftT)[0].split('-')[0];
							// E.g. 1-highlyEngaged, 1-moderatelyEngaged, 1-underEngaged, 1-disengaged
							const value = Object.values(shiftT).reduce((acc, val) => {
								return acc + val.length;
							}, 0);

							const quad1 = shiftT[`${shift}-quad1`]?.length || 0;
							const quad2 = shiftT[`${shift}-quad2`]?.length || 0;
							const quad3 = shiftT[`${shift}-quad3`]?.length || 0;
							const quad4 = shiftT[`${shift}-quad4`]?.length || 0;

							return {
								[config.id]: `${'>'.repeat(
									nodeDepth - 1
								)} ${name} | Shift ${shift}`,
								[config.value]: value,
								quad1,
								quad2,
								quad3,
								quad4,
								sort: node.left,
							};
						} catch (error) {
							return {
								[config.id]: `${name} | Shift ${shiftT}`,
								[config.value]: 0,
								quad1: 0,
								quad2: 0,
								quad3: 0,
								quad4: 0,
							};
						}
					}) ?? [];

				const filtered = nodeShiftSummary.filter(node => node !== null);
				formattedData.push(...filtered);
			} else {
				const validSubs = submissions.filter(s => !allNONEs(s.responses));
				// loop through the submissions to get where each submission falls in the threshold groups
				submissionThresholds = validSubs.reduce((acc, submission) => {
					const avg = calcAvg(submission.responses);
					if (!avg) console.log('avg', avg, submission);
					if (!avg) return acc;
					const thresholdGroup = thresholdGroups.find(
						group => avg >= group.min && avg <= group.max
					);
					if (!thresholdGroup) console.log('thresholdGroup', thresholdGroup, submission);
					if (!thresholdGroup) return acc;
					if (!acc[thresholdGroup.id]) {
						acc[thresholdGroup.id] = [];
					}
					acc[thresholdGroup.id].push(submission);
					return acc;
				}, {});

				formattedData.push({
					[config.id]: '>'.repeat(nodeDepth - 1) + name,
					[config.value]: validSubs.length,
					quad1: submissionThresholds.quad1?.length || 0,
					quad2: submissionThresholds.quad2?.length || 0,
					quad3: submissionThresholds.quad3?.length || 0,
					quad4: submissionThresholds.quad4?.length || 0,
					sort: node.left,
				});
			}
		});

		// sort the data by sort value
		formattedData.sort((a, b) => a.sort - b.sort);
		setFormattedData(formattedData);
		setHierarchyErrors(tmpHierarchyErrors);
	}, [nodeMap, filters, lookupData]);

	const dynamHeight = 50 * formattedData.length;
	const height = dynamHeight < 250 ? 250 : dynamHeight;

	return (
		<Container maxWidth={false} disableGutters>
			<Typography variant='h6' align='center' py={2}>
				{`${title}${alwaysShowLabels ? ' (Showing All)' : ''}`}
			</Typography>
			<Box height={height} width={'100%'}>
				<MyMarimekko data={formattedData} config={config} />
			</Box>
			{hierarchyErrors.length > 0 && (
				<>
					<Typography variant='subtitle2' color='error'>
						Errors:
					</Typography>
					{hierarchyErrors.map((error, i) => (
						<Typography key={i} variant='body2'>
							{error}
						</Typography>
					))}
				</>
			)}
		</Container>
	);
};

export default MarimekkoWrapper;
