import Breadcrumbs from '@mui/material/Breadcrumbs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { callApiGWLambda } from '../../apis/api-gw';
import { AdminContext } from '../../contexts/AdminContext';
import { convertCamelCaseToTitleCase } from '../../helpers/stringUtils';
import Attributes from '../admin/attributes/Attributes';
import Surveys from '../admin/surveys/Surveys';
import Tenant from '../admin/Tenant';
// import ComingSoon from '../shared/ComingSoon';
import Reports from '../reports/Reports';
import DumbLoading from '../shared/DumbLoading';
import DumbSaving from '../shared/DumbSaving';
import Snack from '../shared/Snack';
import Hierarchies from './hierarchies/Hierarchies';
import SmartSaving from '../shared/SmartSaving';
import CodeWrapper from './codes/CodeWrapper';
import { chunkArray } from '../../helpers/arrayUtils';
import DummyAuth from './auth/DummyAuth';

/*
A tenants config file (AdminConfig) is stored in S3 and is used to configure their tenant.
Its broken down into 3 sections:
1. customerManaged - this is the data that the customer can manage themselves
	"customerManaged": {
		"name": "",
		"division": "",
		"site": "",
		"logoUrl": "",
		"active": true,
		"surveys": [],
		"attributes": [],
		"hierarchies": []
	},
2. c2sManaged - this is the data that is managed by C2S
	"c2sManaged": {
		"currentPlan": {
			"name": "",
			"price": 0,
			"currency": ""
		},
		"billing": {
			"frequency": "",
			"frequencyUnit": "",
			"frequencyUnitCount": 0,
			"frequencyUnitCountDisplay": "",
			"awsBillingAccount": "",
			"billingAccountEmail": "",
			"billingAccountPhone": ""
		},
		"prioritySupport": false,
		"customDomain": true,
		"awsAccounts": []
	},
3. readOnly - this is the data that is read only
	"readOnly": {
		"subdomain": "",
		"tableAlias": "",
		"plan": "",
		"industry": "",
		"industryCategory": "",
		"industrySubcategory": "",
		"companySize": ""
	}
*/

const TABS = [
	<Tab value='config' key='config' label='Tenant' />,
	<Tab value='attribute' key='attribute' label={`Attribute's`} />,
	<Tab value='hierarchy' key='hierarchy' label='Hierarchies' />,
	<Tab value='survey' key='survey' label={`Survey's`} />,
	<Tab value='codes' key='codes' label={`Codes`} />,
	<Tab value='reports' key='reports' label={`Reports`} />,
];

const AdminHome = () => {
	const { config, setConfig, refreshConfig, fetchCodes, setCodesPrinted } =
		React.useContext(AdminContext);

	const { ...route } = useParams();
	const navigate = useNavigate();

	const [globalConfig, setGlobalConfig] = React.useState(null);
	const [adminConfig, setAdminConfig] = React.useState(null);
	const [customerManaged, setCustomerManaged] = React.useState(null);
	const [c2sManaged, setC2sManaged] = React.useState(null);
	const [readOnly, setReadOnly] = React.useState(null);
	const [saving, setSaving] = React.useState(false);
	const [savingProgress, setSavingProgress] = React.useState(-1);
	const [changes, setChanges] = React.useState(false);
	const [snack, setSnack] = React.useState({ open: false, message: '', severity: 'success' });
	const [progressAudit, setProgressAudit] = React.useState([]);
	const [forbid, setForbid] = React.useState(true);

	// save changes if the managing param changes
	React.useEffect(() => {
		if (changes) {
			handleSaveTenant();
		}
	}, [route.managing]);

	React.useEffect(() => {
		let cancelled = false;

		if (config && config.shared) {
			if (!cancelled) setGlobalConfig(config.shared);
		}
		if (config && config.admin) {
			if (!cancelled) setAdminConfig(config.admin);
		}

		return () => (cancelled = true);
	}, [config]);

	React.useEffect(() => {
		if (adminConfig) {
			const { customerManaged, c2sManaged, readOnly } = adminConfig;
			setCustomerManaged(customerManaged);
			setC2sManaged(c2sManaged);
			setReadOnly(readOnly);
		}
	}, [adminConfig]);

	const handleTenantChange = e => {
		const { name, value } = e.target;
		setCustomerManaged({
			...customerManaged,
			[name]: value,
		});
		setChanges(true);
	};

	const handleSurveysChange = surveys => {
		if (!surveys) return;
		setCustomerManaged({
			...customerManaged,
			surveys,
		});

		setChanges(true);
	};

	const handleHierarchyChange = hierarchy => {
		if (!hierarchy) return;
		const hierarchyWithId = hierarchy.uuid ? hierarchy : { ...hierarchy, uuid: uuidv4() };
		setCustomerManaged({
			...customerManaged,
			hierarchies: [
				// hierarchies could be undefined here so we need to check for that
				...(customerManaged.hierarchies || []).filter(h => h.uuid !== hierarchy.uuid),
				{
					...hierarchyWithId,
				},
			],
		});

		setChanges(true);

		return hierarchyWithId; // passing back so we can switch to the new hierarchy's tab
	};

	// need to update the entire tree since we're using a nested set model
	const handleHierarchyNodeChange = (tree, hierarchy) => {
		setCustomerManaged({
			...customerManaged,
			hierarchies: [
				// hierarchies could be undefined here so we need to check for that
				...(customerManaged.hierarchies || []).filter(h => h.uuid !== hierarchy.uuid),
				{
					...hierarchy,
					nodes: [...tree],
				},
			].sort((a, b) => (a.deleted ? 1 : a.left - b.left)),
		});

		setChanges(true);
	};

	const handleAttributeChange = attribute => {
		if (!attribute) return;
		setCustomerManaged({
			...customerManaged,
			attributes: [
				// attributes could be undefined here so we need to check for that
				...(customerManaged.attributes || []).filter(a => a.uuid !== attribute.uuid),
				attribute,
			],
		});
		setChanges(true);
	};

	const handleAttributeBulkChange = async attributes => {
		if (!attributes) return;
		setCustomerManaged({
			...customerManaged,
			attributes: [...attributes],
		});
		setChanges(true);
	};

	const handleAttributeOptionChange = (attributeId, option, type = 'add') => {
		if (!option) return;

		const updatedAttributes = (customerManaged.attributes || []).map(attribute => {
			if (attribute.uuid === attributeId) {
				return {
					...attribute,
					options: [
						{ ...option },
						...(attribute.options || []).filter(o => o.uuid !== option.uuid),
					],
				};
			}

			return attribute;
		});

		setCustomerManaged({
			...customerManaged,
			attributes: updatedAttributes,
		});
		setChanges(true);
		handleSnack(type, option.value);
	};

	const handleBulkImportCodes = async (codes, attributes, batchSize = 500) => {
		try {
			setSavingProgress(0);
			await handleAttributeBulkChange(attributes);

			setProgressAudit(`Processing your request in code batches.`);
			if (!readOnly.subdomain) return;

			// codes is now a flat array of codes, which we need to batch out to avoid hitting the lambda timeout
			// prepend the array with an empty array, this is to buy us time if the lambda function needs to first
			// create the table for this tenant
			const batches = [[], ...chunkArray(codes, batchSize)];

			setProgressAudit(`Processing ${batches.length} batches of codes.`);

			for (let i = 0; i < batches.length; i++) {
				try {
					setSavingProgress(Math.round((i / batches.length) * 100));
					setProgressAudit(`Processing batch ${i + 1} of ${batches.length}.`);
					const final = {
						groupId: readOnly.subdomain,
						codes: batches[i],
					};
					console.log('final', final);
					// delay each batch by 1 second to avoid hitting the lambda timeout
					const result = await callApiGWLambda('post', 'codes', final);

					if (result.status === 200) {
						if (result.data.statusCode === 200) {
							setSnack({
								open: true,
								message: result.data.body || 'Codes generated successfully',
								severity: 'success',
							});
							setProgressAudit(
								`Batch ${i + 1} of ${batches.length} processed successfully.`
							);
						} else {
							setSnack({
								open: true,
								message: result.data.body || result.data.errorMessage,
								severity: 'error',
							});
						}
					} else {
						setSnack({
							open: true,
							message: result.data.message || result.data.errorMessage,
							severity: 'error',
						});
					}
				} catch (err) {
					console.log(err);
				}
			}
			setSavingProgress(-1);

			// await zzrefreshCodes(); REFACTOR

			await handleSaveTenant();
		} catch (error) {
			setSavingProgress(-1);
			setSnack({ open: true, message: error.message, severity: 'error' });
			console.log(error);
		}
	};

	const handleSnack = (type, variable, severity = 'success') => {
		var message = 'Changes saved';
		switch (type) {
			case 'add':
				message = `${variable} added. Click "Save Changes" to apply the change`;
				break;
			case 'delete':
				message = `${variable} deleted. Click "Save Changes" to apply the change`;
				break;
			case 'restore':
				message = `${variable} restored. Click "Save Changes" to apply the change`;
				break;
			case 'link':
				message = `${variable} link created. Click "Save Changes" to apply the change`;
				break;
			default:
				break;
		}
		setSnack({ open: true, message, severity: severity });
	};

	const handleSaveTenant = async () => {
		try {
			// check to ensure valid JSON
			const adminConfigReunited = {
				...adminConfig,
				customerManaged,
				c2sManaged,
				readOnly,
			};
			const JSONBody = JSON.parse(JSON.stringify(adminConfigReunited));
			console.log(JSONBody);

			const payload = {
				bucketName: 'survently-configs', //s3Config.configFileBucket,
				key: `${readOnly.subdomain}-config.json`, //s3Config.configFileKey,
				contentBody: JSON.stringify(JSONBody),
			};

			// post to api
			setSaving('Saving configuration...');
			const result = await callApiGWLambda('post', 'config', payload);
			setSaving(false);
			if (result.data) {
				switch (result.data.statusCode) {
					case 200:
						{
							// update  context and show success message
							setConfig({
								...config,
								admin: JSONBody,
							});
							setChanges(false);
							setSnack({
								open: true,
								message: 'Changes saved',
								severity: 'success',
							});
						}
						break;
					default:
						setSnack({
							open: true,
							message: 'There was an error saving your changes. Please try again.',
							severity: 'error',
						});
						break;
				}
				return result.data.statusCode; // response from lambda
			}
		} catch (error) {
			console.log(error);
			setSaving(false);
			setSnack({
				open: true,
				message: `There was an error saving your changes. Please try again. ${error}`,
				severity: 'error',
			});
		}
	};
	// #endregion

	const { attributes, hierarchies } = customerManaged || {};
	const { id } = adminConfig || {};

	return (
		<Container fixed maxWidth={false} sx={{ my: 3 }}>
			{savingProgress > -1 ? (
				<SmartSaving
					progress={savingProgress}
					text='Generating Codes'
					secondaryText={progressAudit}
				/>
			) : (
				saving && <DumbSaving text={saving} />
			)}
			<Snack
				open={snack.open}
				message={snack.message}
				severity={snack.severity}
				handleClose={() => {
					setSnack({ open: false, message: '', severity: 'success' });
				}}
			/>
			<Paper
				sx={{
					minHeight: '100vh',
					maxWidth: '100rem',
					p: 2,
				}}
			>
				{forbid ? (
					<DummyAuth setForbid={setForbid} tenantId={id} />
				) : (
					<>
						<Box display='flex' justifyContent='space-between' mx={7}>
							<Breadcrumbs>
								<Typography color='text.primary'>Manage Tenant</Typography>
								{Object.keys(route).map(key => {
									return (
										<Typography color='text.primary' key={key}>
											{convertCamelCaseToTitleCase(route[key])}
										</Typography>
									);
								})}
							</Breadcrumbs>
							<Button
								variant={changes ? 'contained' : 'outlined'}
								onClick={handleSaveTenant}
								size='small'
								disabled={!!saving || !changes}
								sx={{
									border: changes ? '3px solid' : 'none',
									borderColor: 'error.main',
								}}
							>
								Save Changes
							</Button>
						</Box>
						<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
							<Tabs
								value={route.managing}
								onChange={(e, newValue) => navigate(`/manage/${newValue}`)}
								variant='scrollable'
								scrollButtons
								allowScrollButtonsMobile
								sx={{
									borderBottom: 1,
									borderColor: 'divider',
								}}
							>
								{TABS}
							</Tabs>
						</Box>
						{!adminConfig ? (
							<DumbLoading text='Loading Confguration...' />
						) : (
							<>
								{route.managing === 'config' && (
									<Tenant
										customerManaged={customerManaged || {}}
										readOnly={readOnly || {}}
										onChange={handleTenantChange}
										tenantId={id}
									/>
								)}
								{route.managing === 'hierarchy' && (
									<Hierarchies
										hierarchies={hierarchies || []}
										attributes={attributes || []}
										handleHierarchyChange={handleHierarchyChange}
										handleHierarchyNodeChange={handleHierarchyNodeChange}
									/>
								)}
								{route.managing === 'attribute' && (
									<Attributes
										attributes={attributes || []}
										handleAttributeChange={handleAttributeChange}
										handleAttributeBulkChange={handleAttributeBulkChange}
										handleAttributeOptionChange={handleAttributeOptionChange}
										saving={saving}
									/>
								)}
								{route.managing === 'survey' && (
									<Surveys
										handleSurveysChange={handleSurveysChange}
										customerManaged={customerManaged || {}}
										readOnly={readOnly || {}}
										globalConfig={globalConfig}
									/>
								)}
								{route.managing === 'codes' && (
									<CodeWrapper
										customerManaged={customerManaged || {}}
										readOnly={readOnly || {}}
										globalConfig={globalConfig}
										action={route.action}
										handleAttributeBulkChange={handleAttributeBulkChange}
										handleBulkImport={handleBulkImportCodes}
										refreshConfig={refreshConfig}
										fetchCodes={fetchCodes}
										setCodesPrinted={setCodesPrinted}
									/>
								)}
								{route.managing === 'reports' && (
									<Reports globalConfig={globalConfig} />
								)}
							</>
						)}
					</>
				)}
			</Paper>
		</Container>
	);
};

export default AdminHome;
