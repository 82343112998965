import React from 'react';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import IconButton from '@mui/material/IconButton';
import { Button, Grid, Typography } from '@mui/material';
import { useSpring, animated } from 'react-spring';
// response option components
import Scale from './responseOptions/Scale';
import FreeText from './responseOptions/FreeText';
import Choice from './responseOptions/Choice';
import YesNo from './responseOptions/YesNo';
import Email from './responseOptions/Email';
import Phone from './responseOptions/Phone';
import { Box } from '@mui/material';
import Numeric from './responseOptions/Numeric';

const SurveyQuestion = ({ thisQuestionIndex, questions, handleAnswer, back, done, next }) => {
    const question = questions.find((_, i) => i === thisQuestionIndex);
    const animation = useSpring({
        from: { opacity: 0.3, x: back ? -300 : 300 },
        to: { opacity: 1, x: 0 },
        config: { duration: 400, acceleration: 0.5 },
    });

    // refactor to be compatible with various device browsers (e.g. Chrome, Firefox, Safari, Edge)
    const handleReadAloud = () => {
        // check if browser supports speech synthesis
        if (checkBrowserCompatibility() && question.questionText) {
            // get selected text
            const selectedText = question.questionText;
            // if text is selected
            if (selectedText) {
                // new speech synthesis utterance
                const msg = new SpeechSynthesisUtterance();
                // voice preferences (should cover the primary browsers)
                const preferences = [
                    'Microsoft Zira - English (United States)',
                    'Microsoft Zira Desktop - English (United States)',
                    'Google US English',
                ];
                const voices = window.speechSynthesis.getVoices();
                const _default = voices.find(voice => voice.lang === 'en-US');
                const preferredVoice = voices.find(voice => preferences.includes(voice.name));
                // console.log(preferredVoice);
                // console.log(preferredVoice || _default);
                msg.voice = preferredVoice || _default;
                msg.voiceURI = 'native';
                msg.volume = 1; // 0 to 1
                msg.rate = 1; // 0.1 to 10
                msg.pitch = 1; // 0 to 2
                msg.lang = 'en-US';
                msg.text = selectedText;
                window.speechSynthesis.speak(msg);
            }
        }
    };

    const checkBrowserCompatibility = () => 'speechSynthesis' in window;

    const ResponseOptions = () => {
        if (!question) return <Typography component='div'>No question found</Typography>;
        if (!question.options) return <Typography component='div'>No question options found</Typography>;

        switch (question.options.type) {
            case 'scale':
            case 'opt_scale':
                return <Scale question={question} handleAnswer={handleAnswer} optional={true} />;
            case 'free':
                return <FreeText question={question} handleAnswer={handleAnswer} />;
            case 'int':
                return <Numeric question={question} handleAnswer={handleAnswer} />;
            case 'choice':
                return <Choice question={question} handleAnswer={handleAnswer} />;
            case 'multiple_choice':
                return <Choice question={question} handleAnswer={handleAnswer} next={next} multiple />;
            case 'yes_no':
                return <YesNo question={question} handleAnswer={handleAnswer} />;
            case 'email':
            case 'phone':
                return (
                    <>
                        <Email question={question} handleAnswer={handleAnswer} />;
                        <Phone question={question} handleAnswer={handleAnswer} />;
                    </>
                );
            default:
                return <Typography component='div'>Question type not supported</Typography>;
        }
    };

    //V3Q8X7C8
    return (
        <animated.div style={animation}>
            {!done ? (
                <Grid container spacing={2} display='flex' justifyContent='center' width='100%'>
                    <Grid item xs={12} mt='2rem' display='flex' justifyContent='center'>
                        <Typography variant='h5'>
                            {`${question.required ? '*' : ''} ${question.index + 1}) ${question.questionText}`}
                            <IconButton size='small' onClick={handleReadAloud}>
                                <RecordVoiceOverIcon fontSize='small' />
                            </IconButton>
                        </Typography>
                    </Grid>

                    <Grid item xs={10} md={8} display='flex' flexDirection='column' alignItems='center'>
                        <ResponseOptions />
                    </Grid>
                </Grid>
            ) : (
                <Typography component='span' variant='h4' mt='10vh'>
                    All finished, press Submit below to submit your responses.
                </Typography>
            )}
        </animated.div>
    );
};

export default SurveyQuestion;
