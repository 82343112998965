import { CircularProgress, Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { Navigate, useLoaderData } from 'react-router-dom';

const Loading = ({ text }) => {
	const redirectInfo = useLoaderData();
	const message = text || 'Loading...';

	return redirectInfo ? (
		<Navigate {...redirectInfo} />
	) : (
		<Dialog open={true} maxWidth='xs' fullWidth>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Typography component='span' variant='h4' gutterBottom>
					{message}
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center' }}>
				<CircularProgress variant='indeterminate' />
			</DialogContent>
		</Dialog>
	);
};

export default Loading;
