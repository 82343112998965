import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress variant='determinate' {...props} />
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography variant='body2' color='text.secondary'>{`${Math.round(
					props.value
				)}%`}</Typography>
			</Box>
		</Box>
	);
}

const SmartSaving = ({ progress, text, secondaryText }) => {
	return (
		<Dialog open={true} maxWidth='xs' fullWidth>
			<DialogTitle sx={{ textAlign: 'center' }}>{text || 'Saving...'}</DialogTitle>
			<DialogContent sx={{ textAlign: 'center' }}>
				<Box sx={{ width: '100%' }}>
					<LinearProgressWithLabel value={progress} />
				</Box>
				{secondaryText && (
					<Box sx={{ width: '100%', mt: 2 }}>
						<Typography variant='subtitle2'>Status Updates</Typography>
						<Typography variant='body2'>{secondaryText}</Typography>
					</Box>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default SmartSaving;
