// a function that takes an array, and returns an array of arrays, with  the max number of items in each array being the maxItemsPerArray parameter
// example: arrayUtils.chunkArray([1,2,3,4,5,6,7,8,9,10], 3) returns [[1,2,3],[4,5,6],[7,8,9],[10]]
//

export const chunkArray = (array, maxItemsPerArray) => {
	const chunkedArray = [];
	let index = 0;
	while (index < array.length) {
		chunkedArray.push(array.slice(index, maxItemsPerArray + index));
		index += maxItemsPerArray;
	}
	return chunkedArray;
};
