import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { convertCamelCaseToTitleCase, scrubBrackets } from '../../helpers/stringUtils';
import { getOptionById } from '../../helpers/attributUtils';
import { findNode } from '../../helpers/treeUtils';

const Tags = ({ code, tenantData }) => {
	const { tags } = code;
	const { hierarchies, attributes } = (tenantData || {}).customerManaged;

	const tagLabel = tag => {
		try {
			if (tag.key === 'surveyId') {
				return (
					<>
						<strong>Code</strong>: {code.code}
					</>
				);
			} else if (tag.hierarchyId) {
				const hierarchy = hierarchies.find(h => h.uuid === tag.hierarchyId);
				const node = findNode(hierarchy.nodes, tag.value);
				return (
					<>
						<strong>{scrubBrackets(hierarchy.name) || 'N/A'}</strong>:{' '}
						{node.value || 'N/A'}
					</>
				);
			} else if (tag.attributeId) {
				const attribute = attributes.find(a => a.uuid === tag.attributeId);
				const option = getOptionById(attribute, tag.value);
				return (
					<>
						<strong>{scrubBrackets(attribute.key) || 'N/A'}</strong>:{' '}
						{option.value || 'N/A'}
					</>
				);
			} else {
				return (
					<>
						<strong>
							Tag Lookup Failed -{' '}
							{convertCamelCaseToTitleCase(scrubBrackets(tag.key))}
						</strong>
						: {tag.value}
					</>
				);
			}
		} catch (error) {
			console.log(error);
			return (
				<>
					<strong>Tag Lookup Failed. Key</strong>: {tag.key || 'N/A'}
				</>
			);
		}
	};

	return (
		<>
			{(tags || []).map((tag, index) => (
				<Box key={tag.value} display='flex'>
					<Typography component='div' variant='body2'>
						{/* <strong>{convertCamelCaseToTitleCase(tag.key)}</strong>: {tag.value} */}
						{tagLabel(tag)}
					</Typography>
				</Box>
			))}
		</>
	);
};

export default Tags;
