import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ButtonGroup } from '@mui/material';
import { useTheme } from '@emotion/react';

const YesNo = ({ question, handleAnswer, optional }) => {
    const { response } = question;
    const theme = useTheme();

    const [hover, setHover] = React.useState(-1);
    const [showAnswerKey, setShowAnswerKey] = React.useState(false);

    const AnswerKey = () => (
        <Dialog open={showAnswerKey} onClose={() => setShowAnswerKey(false)}>
            <DialogTitle sx={{ textAlign: 'center' }}>Answer Key</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}></DialogContent>
        </Dialog>
    );

    const getColor = value => {
        if (response === value) return theme.palette.primary.dark;
        if (hover === value) return theme.palette.primary.main;
        return theme.palette.primary.light;
    };

    //Y6H5D5M8
    return (
        <Box display='flex' flexDirection='column' alignItems='center' minWidth='50%'>
            <Box mt={2} mb={2} display='flex' flexDirection='column'>
                <ButtonGroup variant='contained' color='primary' aria-label='contained primary button group'>
                    <Button
                        key='Yes'
                        variant='contained'
                        onMouseEnter={() => setHover('Yes')}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => handleAnswer('Yes')}
                        fullWidth
                        sx={{
                            backgroundColor: getColor('Yes'),
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        key='No'
                        variant='contained'
                        onMouseEnter={() => setHover('No')}
                        onMouseLeave={() => setHover(null)}
                        onClick={() => handleAnswer('No')}
                        fullWidth
                        sx={{
                            backgroundColor: getColor('No'),
                        }}
                    >
                        No
                    </Button>
                </ButtonGroup>
            </Box>

            <Typography variant='subtitle2' mt={2}>
                Your Response: {response ? response : hover !== -1 ? hover : 'No Response'}
            </Typography>

            <Hidden mdUp>
                <Button size='small' onClick={() => setShowAnswerKey(!showAnswerKey)}>
                    Show Answer Key
                </Button>
                {showAnswerKey && <AnswerKey />}
            </Hidden>
        </Box>
    );
};

export default YesNo;
