import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';
import { Autocomplete, Divider, Grid, IconButton, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const TabPanel = ({ children, value, current, ...other }) => (
	<Box
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		{...other}
		// flexGrow={1}
		maxWidth='80%'
	>
		{value === current && (
			<Box sx={{ p: 3 }}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</Box>
);

const LinkAttributePanel = ({ selectedTab, attributes, handleLinkAttributes, saving }) => {
	const [mainOption, setMainOption] = React.useState();
	const [potentialLink, setPotentialLink] = React.useState();

	const saved = React.useRef(false);

	React.useEffect(() => {
		// update mainOption when saving bool changes.
		if (saving && !saved.current) {
			// move any new links to existing links
			if (mainOption && mainOption.newLinks) {
				setMainOption({
					...mainOption,
					existingLinks: [...(mainOption.existingLinks || []), ...mainOption.newLinks],
					newLinks: undefined,
				});
			}
			saved.current = true;
		} else if (!saving && saved.current) {
			saved.current = false;
		}
	}, [saving]);

	const handleLink = () => {
		if (!potentialLink) return;
		// check if no link has been initiated
		if (mainOption.newLinks === undefined) {
			const newLink = {
				optionId: mainOption.optionId,
				links: [...(mainOption.existingLinks || []), potentialLink.optionId],
			};

			handleLinkAttributes(mainOption.attributeId, newLink);
			setMainOption({ ...mainOption, newLinks: [potentialLink.optionId] });
		} else {
			// check if the link already exists
			const existingLink = (mainOption.existingLinks || []).find(
				id => id === potentialLink.optionId
			);
			if (existingLink) return;

			// add the new link
			const updatedLinks = {
				optionId: mainOption.optionId,
				links: [...mainOption.newLinks, potentialLink.optionId],
			};

			handleLinkAttributes(mainOption.attributeId, updatedLinks);
			setMainOption({ ...mainOption, newLinks: updatedLinks.links });
		}
		setPotentialLink(null);
	};

	const handleUnlink = id => {
		console.log(`unlinking ${id} from ${mainOption.optionId}`);
		const linkProp = (mainOption.newLinks || []).includes(id) ? 'newLinks' : 'existingLinks';
		const linksWithOutDeletedLink = mainOption[linkProp].filter(l => l !== id);
		const updatedLinks = {
			optionId: mainOption.optionId,
			[linkProp]: linksWithOutDeletedLink,
		};

		handleLinkAttributes(mainOption.attributeId, updatedLinks);
		setMainOption({ ...mainOption, [linkProp]: linksWithOutDeletedLink });
	};

	const allOptions = (attributes || []).flatMap(a => {
		return (a.options || [])
			.filter(o => !o.deleted)
			.sort((a, b) => a.value.localeCompare(b.value))
			.map(o => {
				return {
					key: a.key,
					attributeId: a.uuid,
					optionId: o.uuid,
					value: o.value, // for display only
					existingLinks: o.links,
				};
			});
	});

	return (
		<TabPanel value='link' current={selectedTab}>
			<Box display='flex' justifyContent='flex-start' alignItems='center' p={1}>
				<Grid container spacing={2} display='flex' justifyContent='center'>
					<Grid item xs={12} mb={3} display='flex' flexDirection='column'>
						<Typography variant='h5' textAlign='center'>
							Link Attribute Options Together
						</Typography>
						<Typography variant='body2'>
							Links are <strong>only</strong> used to streamline code generation.
							Therefore only existing links at the time of code generation will be
							pulled into the generated codes. Existing codes and surveys will not be
							affected.
							<br />
							<br />
							For example, if you're working with an "Employee" attribute, you can
							link a given employee's "Department", "Shift", "Job Title", etc.
							together if those attributes exist. Then, when you go to generate a code
							for that employee, you only need to select the employee, and all of
							their linked attributes will be pulled in automatically.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Autocomplete
							id='main-attribute'
							label='Main Attribute'
							options={allOptions}
							groupBy={option => option.key}
							getOptionLabel={option => option.value}
							onChange={(_, value) => {
								setMainOption(value);
								setPotentialLink(null);
							}}
							value={mainOption || null}
							size='small'
							renderInput={params => (
								<TextField {...params} label='Main Attribute' fullWidth />
							)}
						/>
					</Grid>
					{mainOption && (
						<Grid item xs={12} display='flex'>
							<Grid item xs={11}>
								<Autocomplete
									id='link-attribute'
									label='Linked Attribute'
									options={allOptions.filter(
										a => a.attributeId !== mainOption.attributeId
									)}
									groupBy={option => option.key}
									getOptionLabel={option => option.value}
									onChange={(_, value) => setPotentialLink(value)}
									value={potentialLink || null}
									size='small'
									disabled={!mainOption.optionId}
									renderInput={params => (
										<TextField {...params} label='Linked Attribute' fullWidth />
									)}
								/>
							</Grid>
							<Grid item xs={1} display='flex' justifyContent='flex-end'>
								<IconButton onClick={handleLink} sx={{ pr: 0 }}>
									<AddIcon />
								</IconButton>
							</Grid>
						</Grid>
					)}
					{/* unsaved links */}
					{mainOption && mainOption.newLinks && (
						<Grid item xs={12} my={2}>
							<Divider />
							<Typography variant='h6' textAlign='center' my={1} color='error.main'>
								Unsaved Links
							</Typography>
							{(mainOption.newLinks || []).map(id => {
								const option = allOptions.find(o => o.optionId === id);
								return (
									<Grid container spacing={2} key={id}>
										<Grid item xs={6} display='flex' justifyContent='flex-end'>
											<Typography variant='h6'>
												{convertCamelCaseToTitleCase(option.key)}:
											</Typography>
										</Grid>
										<Grid item xs={5}>
											<Typography variant='h6'>{option.value}</Typography>
										</Grid>
										<Grid item xs={1}>
											<RemoveIcon
												sx={{ cursor: 'pointer' }}
												onClick={() => handleUnlink(id)}
											/>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					)}
					{/* existing links */}
					{mainOption && mainOption.existingLinks && (
						<Grid item xs={12} my={2}>
							<Divider />
							<Typography variant='h6' textAlign='center' my={1}>
								Existing Links
							</Typography>
							{(mainOption.existingLinks || []).map(id => {
								const option = allOptions.find(o => o.optionId === id);
								return (
									<Grid container spacing={2} key={id}>
										<Grid item xs={6} display='flex' justifyContent='flex-end'>
											<Typography variant='h6'>
												{convertCamelCaseToTitleCase(option.key)}:
											</Typography>
										</Grid>
										<Grid item xs={5}>
											<Typography variant='h6'>{option.value}</Typography>
										</Grid>
										<Grid item xs={1}>
											<RemoveIcon
												sx={{ cursor: 'pointer' }}
												onClick={() => handleUnlink(id)}
											/>
										</Grid>
									</Grid>
								);
							})}
						</Grid>
					)}
				</Grid>
			</Box>
		</TabPanel>
	);
};

export default LinkAttributePanel;
