import { convertTitleCaseToCamelCase } from './stringUtils';

export const parseCodeResponse = code => {
	console.log(code);
	return {
		id: code.Id['s'],
		code: code.Id['s'],
		surveyId: code.SurveyId['s'],
		claimed: code.Claimed['n'] === '1' ? true : false,
		claimedOn: code.ClaimedOn ? code.ClaimedOn['s'] : null,
		printedOn: code.PrintedOn ? code.PrintedOn['s'] : null,
		createdOn: code.CreatedOn['s'],
		bgColor: code.BGColor ? code.BGColor['s'] : null,
		color: code.Color ? code.Color['s'] : null,
		tags: Object.values(code.Tags['l'].map(t => t['m']) || {}).map(tag => {
			return Object.entries(tag).reduce((acc, [key, value]) => {
				return { ...acc, [convertTitleCaseToCamelCase(key)]: value['s'] };
			}, {});
		}),
	};
};

export const parseResultResponse = result => {
	return {
		surveyId: result.SurveyId['s'],
		code: result.Code['s'],
		submittedOn: result.SubmittedOn['s'],
		id: result.Id['s'],
		responses: JSON.parse(result.Responses['s']).map(r => {
			// hate this, but still working on the lambda side. Some properties are capitalized, some are not.
			return {
				questionId: r.QuestionId || r.questionId,
				response: isNaN(parseFloat(r.UserResponse || r.userResponse))
					? r.UserResponse || r.userResponse
					: parseFloat(r.UserResponse || r.userResponse),
			};
		}),
		pitTags: JSON.parse(result.PITTags['s']),
	};
};
