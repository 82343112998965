import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import GenerateCodes from './GenerateCodes';
import ImportFromFile from './ImportFromFile';
import Codes from './Codes';

const CodeWrapper = ({
    customerManaged,
    readOnly,
    globalConfig,
    action,
    handleBulkImport,
    fetchCodes,
    setCodesPrinted,
}) => {
    const navigate = useNavigate();

    const { global, codes } = globalConfig || {};
    const { hierarchies, attributes, surveys } = customerManaged || {};
    const allSurveys = [...((global || {}).surveys || []), ...(surveys || [])];

    const [viewClaimed, setViewClaimed] = React.useState(false);
    const [viewPrinted, setViewPrinted] = React.useState(false);
    const [codesPerPage, setCodesPerPage] = React.useState(8);
    const [pages, setPages] = React.useState(5);
    const [ticketHeight, setTicketHeight] = React.useState(100);
    const [columnCount, setColumnCount] = React.useState(2);

    // #region react-to-print
    const qrRef = React.useRef();
    const reactToPrintContent = React.useCallback(() => {
        return qrRef.current;
    }, [qrRef.current]);

    // #middle > a
    const handleMarkCodesAsPrinted = async () => {
        try {
            // get all codes that are printed, by looking for the '#middle > a' elements in the DOM
            const codesToMark = Array.from(document.querySelectorAll('#middle > a')).map(
                a => `${a.innerText}|${a.getAttribute('surveyid')}`
            );
            console.log('codesToMark', codesToMark);
            await setCodesPrinted(codesToMark);

            // await callApiGWLambda('put', 'codes/print', final);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePrint = useReactToPrint({
        bodyClass: 'print',
        content: reactToPrintContent,
        documentTitle: `SurveyCodes-${new Date().toLocaleDateString()}`,
        removeAfterPrint: true,
        onAfterPrint: handleMarkCodesAsPrinted,
    });
    // #endregion

    return (
        <Container
            fixed
            sx={{
                '& .print': {
                    backgroundColor: 'white',
                    padding: '1rem',
                    margin: '1rem',
                },
            }}
        >
            <>
                {action === 'generate' && (
                    <GenerateCodes
                        customerManaged={customerManaged}
                        readOnly={readOnly || {}}
                        globalConfig={globalConfig}
                    />
                )}
                {action === 'import' && (
                    <ImportFromFile
                        hierarchies={hierarchies}
                        attributes={attributes}
                        surveys={allSurveys}
                        handleBulkImport={handleBulkImport}
                        fetchCodes={fetchCodes}
                        codes={codes}
                    />
                )}
                {!action && (
                    <>
                        {/* <Typography variant='body2' my={2}>
							If the codes on your screen do not appear to be properly formatted, do
							not worry; they will be formatted correctly when printed. To ensure that
							all of the codes are visible without any overlapping, you can adjust the
							zoom of your browser.
						</Typography> */}
                        <Grid container spacing={2} display='flex' alignItems='center' my={1}>
                            <Grid item xs={12} display='flex' justifyContent='space-around'>
                                <Button
                                    onClick={() => navigate('/manage/codes/generate')}
                                    variant='contained'
                                    fullWidth
                                    sx={{ mx: 1, backgroundColor: 'primary.light' }}
                                    disabled={true} // force user to import codes
                                >
                                    Generate
                                </Button>
                                <Button
                                    onClick={() => navigate('/manage/codes/import')}
                                    variant='contained'
                                    fullWidth
                                    sx={{
                                        mx: 1,
                                        backgroundColor: 'primary.main',
                                        color: 'primary.contrastText',
                                    }}
                                >
                                    Import
                                </Button>
                                <Button
                                    onClick={handlePrint}
                                    variant='contained'
                                    fullWidth
                                    sx={{ mx: 1, backgroundColor: 'primary.main' }}
                                >
                                    Print
                                </Button>
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={viewClaimed || false}
                                            onChange={e => {
                                                setViewClaimed(e.target.checked);
                                            }}
                                            color='primary'
                                            size='small'
                                        />
                                    }
                                    // label={`${viewClaimed ? 'Hide' : 'View'} Claimed`}
                                    label='View Claimed'
                                    labelPlacement='top'
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={viewPrinted || false}
                                            onChange={e => {
                                                setViewPrinted(e.target.checked);
                                            }}
                                            color='primary'
                                            size='small'
                                        />
                                    }
                                    // label={`${viewPrinted ? 'Hide' : 'View'} Printed`}
                                    label='View Printed'
                                    labelPlacement='top'
                                />
                            </Grid>
                            <Grid item xs={8} display='flex' justifyContent='space-around'>
                                <TextField
                                    label='Code Columns'
                                    value={columnCount}
                                    onChange={e => setColumnCount(Number(e.target.value))}
                                    type='number'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        px: 1,
                                    }}
                                />

                                <TextField
                                    label='Codes Per Page'
                                    value={codesPerPage}
                                    onChange={e => setCodesPerPage(Number(e.target.value))}
                                    type='number'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        px: 1,
                                    }}
                                />

                                <TextField
                                    label='Pages'
                                    value={pages}
                                    onChange={e => setPages(Number(e.target.value))}
                                    type='number'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        px: 1,
                                    }}
                                />

                                <TextField
                                    label='Ticket Height'
                                    value={ticketHeight}
                                    onChange={e => setTicketHeight(Number(e.target.value))}
                                    type='number'
                                    variant='outlined'
                                    size='small'
                                    sx={{
                                        px: 1,
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid ref={qrRef} container spacing={2} display='flex'>
                            {columnCount > 0 &&
                                [...Array(columnCount)].map((_, i) => {
                                    return (
                                        <Codes
                                            key={`code-column-${i}`}
                                            codeColumnIndex={i}
                                            columnCount={columnCount}
                                            viewClaimed={viewClaimed}
                                            viewPrinted={viewPrinted}
                                            readOnly={readOnly || {}}
                                            hierarchies={hierarchies}
                                            attributes={attributes}
                                            allSurveys={allSurveys}
                                            codesPerPage={codesPerPage}
                                            pages={pages}
                                            ticketHeight={ticketHeight}
                                            fetchCodes={fetchCodes}
                                            codes={codes}
                                        />
                                    );
                                })}
                        </Grid>
                    </>
                )}
            </>
        </Container>
    );
};

export default CodeWrapper;
