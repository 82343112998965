import {
	Grid,
	VirtualTable,
	TableColumnResizing,
	TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import React from 'react';

// const RowComponent = ({ row, ...restProps }) => (
// 	<VirtualTable.Row
// 		{...restProps}
// 		// eslint-disable-next-line no-alert
// 		style={{
// 			fontSize: '.8em',
// 		}}
// 	/>
// );

const CommentsTable = ({ data }) => {
	const [columns] = React.useState([
		{ name: 'metadata', title: 'Survey Info' },
		{ name: 'start', title: `Start doing...` },
		{ name: 'stop', title: `Stop doing...` },
		{ name: 'keep', title: `Keep doing...` },
		{ name: 'comments', title: `Misc. Comments` },
	]);

	const [columnWidths, setColumnWidths] = React.useState([
		{ columnName: 'metadata', width: 300 },
		{ columnName: 'start', width: 250 },
		{ columnName: 'stop', width: 250 },
		{ columnName: 'keep', width: 250 },
		{ columnName: 'comments', width: 250 },
	]);

	const [tableColumnExtensions] = React.useState([
		{ columnName: 'metadata', align: 'left', wordWrapEnabled: true },
		{ columnName: 'start', align: 'left', wordWrapEnabled: true },
		{ columnName: 'stop', align: 'left', wordWrapEnabled: true },
		{ columnName: 'keep', align: 'left', wordWrapEnabled: true },
		{ columnName: 'comments', align: 'left', wordWrapEnabled: true },
	]);

	return (
		<Grid rows={data || []} columns={columns}>
			<VirtualTable columnExtensions={tableColumnExtensions} />
			<TableColumnResizing
				columnWidths={columnWidths || []}
				onColumnWidthsChange={setColumnWidths}
			/>
			<TableHeaderRow />
		</Grid>
	);
};

export default CommentsTable;
