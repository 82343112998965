import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { convertCamelCaseToTitleCase } from '../../helpers/stringUtils';

const Metadata = ({ metadata, templateStrings }) => {
	return Object.entries(metadata).map(([key, value]) => {
		// find any variables in the metadata text. ex: {company}
		const metadatanVars = value.match(/{(.*?)}/g) || [];
		// replace the variables with the template string value
		metadatanVars.forEach(varName => {
			const templateString = templateStrings.find(ts => ts.tsKey === varName);
			value = templateString ? value.replace(varName, templateString.tsValue) : value;
		});

		return (
			<Box key={key} display='flex'>
				<Typography component='div' variant='body2'>
					<strong>{convertCamelCaseToTitleCase(key)}</strong>: {value}
				</Typography>
			</Box>
		);
	});
};

export default Metadata;
