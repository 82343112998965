import SaveIcon from '@mui/icons-material/Save';
import { Box, Button, IconButton, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { initSurvey } from '../../../helpers/surveyUtils';

const TabPanel = ({ children, value, current, ...other }) => (
	<Box
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		width='100%'
		{...other}
	>
		{value === current && (
			<Box p={3}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</Box>
);

const NewSurvey = ({ handleAddSurvey }) => {
	const [newSurvey, setNewSurvey] = React.useState(initSurvey());
	const [metadata, setMetadata] = React.useState(newSurvey.metadata);

	const handleSubmit = e => {
		e.preventDefault();
		if (metadata.name === '') return;
		handleAddSurvey({
			...newSurvey,
			metadata: metadata,
		});
		const initSurvey = initSurvey();
		setNewSurvey(initSurvey);
		setMetadata(initSurvey.metadata);
	};

	return (
		<TabPanel value='new' current='new'>
			<form onSubmit={handleSubmit}>
				<Grid container spacing={1} my={1} flexDirection='column' alignContent='center'>
					<Grid item xs={12} my={1}>
						<Typography mx={1} variant='h5'>
							Create a New Survey
						</Typography>
					</Grid>
					<Grid item xs={12} my={1} width='50%'>
						<TextField
							label='Survey Name'
							variant='outlined'
							size='small'
							value={metadata.name || ''}
							onChange={e => setMetadata({ ...metadata, name: e.target.value })}
							fullWidth
							required
							error={metadata.name === ''}
							helperText={metadata.name === '' ? 'Survey name is required' : ''}
						/>
					</Grid>
					<Grid item xs={12} my={1}>
						<TextField
							label='Survey Description'
							variant='outlined'
							size='small'
							value={metadata.description || ''}
							onChange={e =>
								setMetadata({ ...metadata, description: e.target.value })
							}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} my={1}>
						<TextField
							label='Instructions (optional)'
							variant='outlined'
							size='small'
							value={metadata.instructions || ''}
							onChange={e =>
								setMetadata({ ...metadata, instructions: e.target.value })
							}
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} display='flex' alignItems='center'>
						<Button
							type='submit'
							onClick={handleSubmit}
							disabled={newSurvey.name === ''}
							variant='contained'
							color='primary'
							// startIcon={<SaveIcon />}
							fullWidth
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</form>
		</TabPanel>
	);
};

export default NewSurvey;
