import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Questions from './Questions';

const Survey = ({ survey, globalConfig }) => {
	const [surveyChanges, setSurveyChanges] = React.useState(survey);

	const handleSaveChanges = e => {
		e.preventDefault();
		setSurveyChanges(surveyChanges);
		console.log(surveyChanges);
	};

	const handleSurveyChange = changes => {
		setSurveyChanges(changes);
	};

	console.log(surveyChanges);
	return <Questions survey={surveyChanges} handleSurveyChange={handleSurveyChange} globalConfig={globalConfig} />;
};

export default Survey;
