import Box from '@mui/material/Box';
import React from 'react';
import { allNONEs, avgAllResponses, calcAvg, calcStdDev } from '../../../helpers/surveyUtils';
import {
	depth,
	findAOIdFromPathString,
	findNode,
	findParent,
	getHierarchyFromNodeId,
} from '../../../helpers/treeUtils';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import BoxPlot from '../servant-leadership/BoxPlot';

const BoxPlotWrapper = ({ data, filters, lookupData, aggregate }) => {
	const [formattedData, setFormattedData] = React.useState([]);
	const [nodeMap, setNodeMap] = React.useState(new Map());
	const [groups, setGroups] = React.useState(new Map());

	React.useEffect(() => {
		var map = new Map();
		Object.entries(data).forEach(([path, submissions]) => {
			const nodePath = path.split('>');

			if (aggregate) {
				nodePath.forEach(node => {
					if (!map.has(node)) {
						map.set(node, []);
					}
					map.get(node).push(...submissions);
				});
			} else {
				// last node is the leaf node we want
				const leafNode = nodePath[nodePath.length - 1];
				if (!map.has(leafNode)) {
					map.set(leafNode, []);
				}
				map.get(leafNode).push(...submissions);
			}
		});

		setNodeMap(map);
	}, [data]);

	React.useEffect(() => {
		const { attributes, hierarchies } = lookupData;

		// now calc the avgs of all the submissions within each node in the map
		// also need to lookup the name of the node (key)
		const formattedData = [];
		// build map of distinct groups (names)
		const groups = new Map();
		nodeMap.forEach((allSubmissions, key) => {
			// filter out surveys where every response is none/no opinion-
			const questionCount = allSubmissions[0].responses.map(r => r.question)?.length ?? 0;
			const submissions = allSubmissions.filter(
				s => !allNONEs(s.responses, 0, questionCount - 4)
			);
			// const hierarchy = getHierarchyFromNodeId(hierarchies, key);
			// const node = findNode(hierarchy?.nodes ?? [], key);
			// const nodeDepth = depth(hierarchy?.nodes ?? [], node);
			const nodeAOId = findAOIdFromPathString(hierarchies, key);
			const option = getOptionFromAllById(attributes, nodeAOId || key);
			const name = option.value || 'n/a';
			if (!groups.has(name)) {
				groups.set(name, []);
			}
			const groupAvg = avgAllResponses(submissions);

			submissions &&
				submissions.forEach(submission => {
					const { responses, code } = submission;

					const avg = calcAvg(responses);
					const stdDev = calcStdDev(responses);

					if ([avg, stdDev, groupAvg].some(isNaN)) {
						console.log(name, code, avg, stdDev, groupAvg);
						return;
					} // skip if any of the values are NaN

					formattedData.push({
						group: name,
						// subgroup: `${nodeDepth || -1}`,
						mu: groupAvg, // mean
						sd: stdDev, // standard deviation
						n: submissions?.length ?? 0, // sample size
						value: avg, // value
						sortValue: name, // value
					});
				});
		});

		// sort the data by avg
		const sorted = formattedData.sort((a, b) => b.sortValue - a.sortValue);
		setGroups(groups);
		setFormattedData(sorted);
	}, [nodeMap, filters, lookupData]);

	return (
		<Box height={'90%'} width={'100%'}>
			<BoxPlot data={formattedData} groups={groups} />
		</Box>
	);
};

export default BoxPlotWrapper;
