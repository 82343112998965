import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Hidden from '@mui/material/Hidden';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTheme } from '@emotion/react';

const Choice = ({ question, handleAnswer, optional, multiple, next }) => {
    const { options, response } = question;
    const { labels } = options;
    const theme = useTheme();

    const [hover, setHover] = React.useState(null);
    const [showAnswerKey, setShowAnswerKey] = React.useState(false);

    const AnswerKey = () => (
        <Dialog open={showAnswerKey} onClose={() => setShowAnswerKey(false)}>
            <DialogTitle sx={{ textAlign: 'center' }}>Answer Key</DialogTitle>
            <DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
                {Object.values(labels).map(value => (
                    <Box display='flex' justifyContent='space-between'>
                        <Box pr={1}>{value}</Box>
                        <Typography fontSize='.7rem'>{value}</Typography>
                    </Box>
                ))}
            </DialogContent>
        </Dialog>
    );

    const getColor = value => {
        if (multiple) {
            if (response?.includes(value)) return theme.palette.primary.dark;
            if (hover === value) return theme.palette.primary.main;
            return theme.palette.primary.light;
        }
        if (response === value) return theme.palette.primary.dark;
        if (hover === value) return theme.palette.primary.main;
        return theme.palette.primary.light;
    };

    return (
        <Box display='flex' flexDirection='column' alignItems='center' minWidth='50%'>
            <Box mt={2} mb={2} display='flex' flexWrap='wrap'>
                {Object.entries(labels).map(([key, value]) => {
                    return (
                        <Button
                            key={key}
                            variant='contained'
                            onMouseEnter={() => setHover(value)}
                            onMouseLeave={() => setHover(null)}
                            onClick={() => handleAnswer(value, multiple)}
                            fullWidth
                            sx={{
                                p: 1,
                                m: 1,
                                backgroundColor: getColor(value),
                                maxWidth: '45%',
                            }}
                        >
                            {value}
                        </Button>
                    );
                })}
            </Box>

            {/* <Typography variant='subtitle2' mt={2}>
                Your Response: {hover !== null ? hover : response}
            </Typography> */}
            {multiple && <Button onClick={next}>Continue</Button>}

            <Hidden mdUp>
                <Button size='small' onClick={() => setShowAnswerKey(!showAnswerKey)}>
                    Show Answer Key
                </Button>
                {showAnswerKey && <AnswerKey />}
            </Hidden>
        </Box>
    );
};

export default Choice;
