import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import MoveUpIcon from '@mui/icons-material/MoveUp';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { IconButton, Typography } from '@mui/material';

const Question = ({
	boxNumber,
	handleDrag,
	handleDrop,
	question,
	readonly,
	responseTypeOptions,
	handleQuestionRequiredChange,
	handleQuestionTypeChange,
	handleQuestionTextChange,
	handleQuestionDelete,
	handleQuestionRestore,
}) => {
	const [questionText, setQuestionText] = React.useState(question.questionText || '');

	return (
		<div
			draggable={true}
			id={boxNumber}
			onDragOver={e => e.preventDefault()}
			onDragStart={handleDrag}
			onDrop={handleDrop}
		>
			<Grid container spacing={1} my={1}>
				<Grid item xs={readonly ? 1 : 2} display='flex' alignItems='center'>
					{!readonly && !question.deleted && (
						<span style={{ cursor: 'grab' }}>
							<IconButton>
								<MoveUpIcon />
							</IconButton>
						</span>
					)}
					{!readonly && !question.deleted && (
						<IconButton onClick={() => handleQuestionDelete(question.uuid)}>
							<DeleteIcon />
						</IconButton>
					)}
					{!readonly && question.deleted && (
						<IconButton onClick={() => handleQuestionRestore(question.uuid)}>
							<Typography variant='overline'>Restore</Typography>
							<AddIcon />
						</IconButton>
					)}

					{!readonly && !question.deleted && (
						<Checkbox
							name={`${question.uuid}-required`}
							checked={question.required || false}
							onChange={e => handleQuestionRequiredChange(e, question.uuid)}
							size='small'
							disabled={readonly}
						/>
					)}
				</Grid>
				<Grid item xs={3} display='flex' alignItems='center'>
					<Autocomplete
						name={`${question.uuid}-type`}
						disabled={readonly || question.deleted}
						options={responseTypeOptions}
						getOptionLabel={option => option.label || ''}
						value={
							responseTypeOptions.find(t => t.value === question.responseTypeId) || {
								value: null,
								label: 'Select Type',
							}
						}
						onChange={(e, value) => handleQuestionTypeChange(e, value, question.uuid)}
						renderInput={params => (
							<TextField {...params} variant='outlined' size='small' />
						)}
						fullWidth
					/>
				</Grid>
				<Grid item xs={readonly ? 8 : 7}>
					<TextField
						name={`${question.uuid}-text`}
						variant='outlined'
						size='small'
						value={questionText || ''}
						onChange={e => setQuestionText(e.target.value)}
						onBlur={() => handleQuestionTextChange(question.uuid, questionText)}
						fullWidth
						multiline
						minRows={1}
						disabled={readonly || question.deleted}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default Question;
