import React from 'react';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import Code from './Code';
import { Typography } from '@mui/material';

const CodePagination = ({
	filteredCodes,
	hierarchies,
	attributes,
	allSurveys,
	handleSetFiltersOnTagClick,
	ticketHeight,
	codesPerPage,
	pages,
	loadingBackground,
}) => {
	const totalCodes = codesPerPage * pages;
	//get current host url
	const currentUrl = window.location.origin;

	const [page, setPage] = React.useState(1);

	const handleChange = (e, value) => {
		setPage(value);
	};

	const PageComponent = () => {
		if (!filteredCodes) return null;
		return filteredCodes.slice((page - 1) * totalCodes, page * totalCodes).map((code, i) => (
			<>
				<Typography color='text.secondary' fontSize='.8rem' m={0} p={0} textAlign='center'>
					Scan the QR Code, or go to: <a href={currentUrl}>{currentUrl}</a>
				</Typography>
				<Box
					key={code.id}
					height={ticketHeight}
					display='flex'
					borderBottom='2px dashed black'
					justifyContent='center'
					alignItems='center'
				>
					<Code
						key={code.id}
						code={code}
						hierarchies={hierarchies}
						attributes={attributes}
						surveys={allSurveys}
						handleSetFiltersOnTagClick={handleSetFiltersOnTagClick}
						loadingBackground={loadingBackground}
					/>
					{i % codesPerPage === codesPerPage - 1 && (
						<Box
							sx={{
								pageBreakAfter: 'always',
							}}
						/>
					)}
				</Box>
			</>
		));
	};

	if (!filteredCodes) return null;
	return (
		<Stack spacing={2}>
			<Pagination
				count={Math.ceil(filteredCodes.length / totalCodes)}
				page={page}
				variant='outlined'
				shape='rounded'
				onChange={handleChange}
				sx={{
					display: 'flex',
					justifyContent: 'center',
				}}
			/>
			<PageComponent />
		</Stack>
	);
};

export default CodePagination;
