import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
	convertCamelCaseToTitleCase,
	convertTitleCaseToCamelCase,
	scrubBrackets,
} from '../../../helpers/stringUtils';
import { Button, IconButton } from '@mui/material';
import Children from './Children';
import Remove from '@mui/icons-material/Remove';
import Confirm from './Confirm';
import { depth, sorted } from '../../../helpers/treeUtils';
import { findTagLinks } from '../../../helpers/tagUtils';
import { useNavigate } from 'react-router-dom';

const GenerateCodes = ({ customerManaged, readOnly, globalConfig }) => {
	const navigate = useNavigate();

	const { subdomain, id, hierarchies, attributes, surveys } = customerManaged;
	const { global } = globalConfig;
	const allSurveys = [
		...(surveys || []),
		...((global || {}).surveys || []).map(s => ({ ...s, global: true })),
	];

	const [configType, setConfigType] = React.useState({ value: '', label: '' });
	const [configTypeKey, setConfigTypeKey] = React.useState({ value: '', label: '' });
	const [intermediateTag, setIntermediateTag] = React.useState({});
	const [tags, setTags] = React.useState({});
	const [linkedTags, setLinkedTags] = React.useState({});
	const [options, setOptions] = React.useState({
		hierarchyOptions: {},
	});
	const [showConfirm, setShowConfirm] = React.useState(false);

	// check if the attribute is linked to another attribute
	React.useEffect(() => {
		// iterate through the tags, checking for tag/attribute links;
		const linkedTags = {};
		Object.keys(tags).forEach(key => {
			const tag = tags[key];
			console.log('tag', tag);
			findTagLinks(tag, attributes, hierarchies).forEach(linkedTag => {
				console.log('linkedTag', linkedTag);
				linkedTags[convertTitleCaseToCamelCase(linkedTag.key)] = linkedTag;
			});
		});
		setLinkedTags(linkedTags);
	}, [tags]);

	const handleSetTags = (key, value) => {
		setTags({
			...tags,
			[key]: value,
		});
	};

	const handleDeleteTag = key => {
		const newTags = { ...tags };
		delete newTags[key];
		setTags(newTags);
	};

	// quick check to see if any of the tags currently set have a "hierarchyId" property, we only want to allow one of these types of tags
	const hasHierarchyTag = () => Object.keys(tags).some(key => tags[key].hierarchyId);

	return (
		<Box display='flex' flexDirection='column'>
			<Grid container spacing={2} p={4}>
				{showConfirm && (
					<Confirm
						tags={tags}
						linkedTags={linkedTags}
						options={options}
						groupId={readOnly.subdomain}
						surveys={allSurveys}
						hierarchies={hierarchies}
						attributes={attributes}
						setShowConfirm={setShowConfirm}
					/>
				)}
				<Grid item xs={12} my={2}>
					<Button variant='contained' onClick={() => navigate('/manage/codes')}>
						Back
					</Button>
				</Grid>
				<Grid item xs={12} my={2}>
					<Autocomplete
						options={(allSurveys || []).map(s => ({
							value: s.uuid,
							label: s.metadata.name,
							global: s.global,
						}))}
						groupBy={option =>
							option.global ? 'Globally Managed Surveys' : 'Your Surveys'
						}
						value={tags.surveyId || ''}
						onChange={(e, selection) => handleSetTags('surveyId', selection)}
						getOptionLabel={option => option.label || 'Select Survey'}
						renderInput={params => <TextField {...params} label='Survey' />}
						size='small'
					/>
				</Grid>
				<Grid item xs={3} my={2}>
					<Autocomplete
						id='configType'
						options={(hasHierarchyTag()
							? ['attribute']
							: ['hierarchy', 'attribute']
						).map(s => ({
							value: s,
							label: convertCamelCaseToTitleCase(s),
						}))}
						value={configType || ''}
						onChange={(e, selection) => {
							setConfigType(selection);
							setConfigTypeKey({ value: '', label: '' });
						}}
						getOptionLabel={option => option.label || 'Select Tag Type'}
						renderInput={params => <TextField {...params} label='Tag Type' />}
						size='small'
					/>
				</Grid>
				<Grid item xs={3} my={2}>
					<Autocomplete
						id='hierarchy'
						options={(hierarchies || [])
							.filter(h => !h.deleted)
							.map(h => {
								return { value: h.uuid, label: h.name };
							})}
						value={configTypeKey || ''}
						onChange={(e, selection) => setConfigTypeKey(selection)}
						getOptionLabel={option => option.label || 'Select Hierarchy'}
						renderInput={params => <TextField {...params} label='Hierarchy' />}
						size='small'
						hidden={configType.value !== 'hierarchy'}
					/>
					<Autocomplete
						id='attribute'
						options={(attributes || [])
							.filter(a => !a.deleted)
							.map(a => {
								return { value: a.uuid, label: a.key };
							})}
						value={configTypeKey || ''}
						onChange={(e, selection) => setConfigTypeKey(selection)}
						getOptionLabel={option => option.label || 'Select Attribute'}
						renderInput={params => <TextField {...params} label='Attribute' />}
						size='small'
						hidden={configType.value !== 'attribute'}
					/>
				</Grid>
				<Grid item xs={5} my={2}>
					<Autocomplete
						options={sorted(
							(hierarchies || [])
								.find(h => h.uuid === configTypeKey.value)
								?.nodes.filter(n => n.left > 0)
								.map((n, i, tree) => {
									return {
										value: n.uuid,
										label: n.value,
										indentedLabel: `${'_'.repeat(depth(tree, n))} ${n.value}`,
									};
								}) || []
						)}
						value={
							intermediateTag[convertTitleCaseToCamelCase(configTypeKey.label)] || ''
						}
						onChange={(e, selection) =>
							setIntermediateTag({
								...intermediateTag,
								[convertTitleCaseToCamelCase(configTypeKey.label)]: {
									...selection,
									hierarchyId: configTypeKey.value,
								},
							})
						}
						getOptionLabel={option => option.indentedLabel || 'Select Hierarchy Node'}
						renderInput={params => <TextField {...params} label='Node' />}
						size='small'
						hidden={configType.value !== 'hierarchy'}
					/>
					<Autocomplete
						options={(
							((attributes || []).find(a => a.uuid === configTypeKey.value) || [])
								.options || []
						)
							.filter(o => !o.deleted)
							.map(o => {
								return { value: o.uuid, label: o.value };
							})}
						value={
							intermediateTag[convertTitleCaseToCamelCase(configTypeKey.label)] || ''
						}
						onChange={(e, selection) =>
							setIntermediateTag({
								...intermediateTag,
								[convertTitleCaseToCamelCase(configTypeKey.label)]: {
									...selection,
									attributeId: configTypeKey.value,
								},
							})
						}
						getOptionLabel={option => option.label || 'Select Attribute Option'}
						renderInput={params => <TextField {...params} label='Option' />}
						size='small'
						hidden={configType.value !== 'attribute'}
					/>
				</Grid>
				<Grid
					item
					xs={1}
					my={2}
					display={Object.keys(intermediateTag).length > 0 ? 'flex' : 'none'}
				>
					<IconButton
						onClick={() => {
							handleSetTags(convertTitleCaseToCamelCase(configTypeKey.label), {
								...intermediateTag[
									convertTitleCaseToCamelCase(configTypeKey.label)
								],
							});
							setIntermediateTag({});
							configType.value === 'hierarchy' &&
								setConfigType({ value: '', label: '' });
							setConfigTypeKey({ value: '', label: '' });
						}}
					>
						<AddIcon />
					</IconButton>
				</Grid>
				{/* User can add as many tags as they'd like */}
				<Grid
					item
					xs={12}
					my={2}
					textAlign='center'
					display={Object.keys(tags).length >= 1 && tags.surveyId ? 'block' : 'none'}
				>
					<Typography variant='h4'>Survey Tags</Typography>
					<Box display='flex' justifyContent='space-evenly' mt={2}>
						<Button
							variant='outlined'
							color='primary'
							size='small'
							onClick={() => setTags({})}
						>
							Clear All
						</Button>
						<Button
							variant='contained'
							color='primary'
							size='small'
							onClick={() => setShowConfirm(true)}
						>
							Confirm & Generate
						</Button>
					</Box>
				</Grid>
				{Object.keys(tags).map(key => (
					<Grid item xs={12} display='flex' alignItems='center' p={0}>
						<Grid item xs={4} display='flex' justifyContent='flex-end'>
							<Typography variant='body1'>
								{scrubBrackets(convertCamelCaseToTitleCase(key))}
							</Typography>
						</Grid>
						<Grid
							item
							xs={8}
							mx={1}
							px={1}
							justifyContent='flex-start'
							borderLeft='2px solid'
							borderColor='primary.light'
						>
							<Box display='flex'>
								<Typography variant='body1'>
									<strong>{tags[key].label}</strong>
								</Typography>
								<IconButton
									onClick={() => handleDeleteTag(key)}
									size='small'
									sx={{ m: 0, p: 0 }}
								>
									<Remove />
								</IconButton>
							</Box>

							{/* If hierarchy, display number of children with an option to generate codes for them as well */}
							{tags[key].hierarchyId && (
								<Children
									tags={tags[key]}
									options={options}
									setOptions={setOptions}
									hierarchy={hierarchies.find(
										h => h.uuid === tags[key].hierarchyId
									)}
								/>
							)}
						</Grid>
					</Grid>
				))}
				{/* Tags (attributes) can also be linked to other attributes, so we need to display those */}
				{Object.keys(linkedTags).map(key => (
					<Grid item xs={12} display='flex' alignItems='center' p={0}>
						<Grid item xs={4} display='flex' justifyContent='flex-end'>
							<Typography variant='body1'>
								{convertCamelCaseToTitleCase(key)}
								<em> (linked)</em>
							</Typography>
						</Grid>
						<Grid
							item
							xs={8}
							mx={1}
							px={1}
							justifyContent='flex-start'
							borderLeft='2px solid'
							borderColor='primary.light'
						>
							<Box display='flex'>
								<Typography variant='body1'>
									<em>
										<strong>{linkedTags[key].label}</strong>
									</em>
								</Typography>
							</Box>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

export default GenerateCodes;
