// simple axios function to post survey responses to our API
import axios from 'axios';
import getTenantEnv from './getEnv';

// To be replaced by the endpoint of the API deployed through the CloudFormation Template
const tenantEnv = getTenantEnv();
const APIEndPoint = `https://e6xb08goec.execute-api.us-west-2.amazonaws.com/${tenantEnv}`;
const APIEndPointS3 = `https://ktrbpdhyf1.execute-api.us-west-2.amazonaws.com/${tenantEnv}`;

// create separate axios instances for the s3 api
const axiosS3 = axios.create({
    baseURL: APIEndPointS3,
    timeout: 10000,
});

// Default headers for the API
axios.defaults.headers.common['Content-Type'] = 'application/json';

// extract subdomain from url
// add groupid to get request headers
axios.defaults.headers.get['groupId'] = window.location.href.split('.')[0].split('//')[1];

// timestamp of latest fetch for a given endpoint
// if a post is made, clear the timestamp so the next get will fetch new data
sessionStorage.clear();

// one function to handle all get requests to API Gateway
export const callApiGWLambdaGet = async (path, query = '', groupOverride = null) => {
    try {
        console.log(process.env.NODE_ENV);
        // // check if we have already fetched this data recently
        // const fetchedAt = sessionStorage.getItem(`fetched-${path}At`);
        // console.log(fetchedAt);
        // if (fetchedAt && Date.now() - fetchedAt < THRESHOLD) {
        // 	console.log('Data already fetched');
        // 	return { error: false, statusCode: 425, body: 'Data already fetched' }; // 425 = Too Early, experimental - https://developer.mozilla.org/en-US/docs/Web/HTTP/Status/425
        // }
        axios.defaults.headers.get['groupId'] = !groupOverride
            ? window.location.href.split('.')[0].split('//')[1]
            : groupOverride;
        const response = await axios.get(`${APIEndPoint}/${path}${query}`);
        console.log(response);
        return handleLambdaResponse(response, path);
    } catch (error) {
        console.log(error);
        if (error.response) {
            console.log(error.response);
            return (error.response || {}).data;
        }
        return error;
    }
};

// one function to handle all s3 get requests to API Gateway
// FUCK this. Simply trying to return a file from S3 and download to client
export const callApiGWLambdaGetS3Item = async key => {
    try {
        // https://e6xb08goec.execute-api.us-west-2.amazonaws.com/prod/config/files/code-import-template.xlsx
        // responseType is set to 'arraybuffer' to return the file as a blob
        // const response = await axios.get(`${APIEndPoint}/config/item?key=${key}`, {
        const response = await axiosS3.get(`${APIEndPointS3}/survently-configs/${key}`, {
            // add Accept header to request
            headers: {
                Accept: 'binary/octet-stream',
                'Content-Type': 'binary/octet-stream',
            },
            // set responseType to arraybuffer
            responseType: 'arraybuffer',
        });
        return response;
    } catch (error) {
        console.log(error);
        if (error.response) {
            console.log(error.response);
            return (error.response || {}).data;
        }
        return error;
    }
};

// one function to handle all other requests to API Gateway
export const callApiGWLambda = async (method, path, data) => {
    try {
        const response = await axios({
            method,
            url: `${APIEndPoint}/${path}`,
            data,
        });
        if (response.status === 200) {
            sessionStorage.setItem(`fetched-${path}At`, 0);
        }
        return response;
    } catch (error) {
        console.log(error);
        return 'Error posting survey responses';
    }
};

// we're returning a custom APIProxyResponse object here, so the core response may be a 200, but we need to look inside the body to find the true response code we sent back from our lambda function
const handleLambdaResponse = (response, path) => {
    // core level response code
    console.log('response=', response);
    console.log('path=', path);
    if (response.status === 200) {
        sessionStorage.setItem(`fetched-${path}At`, Date.now());
        return {
            ...response.data,
            body: response.data.body,
            error: response.data.statusCode !== 200,
        };
    } else {
        console.log(`Error calling endpoint ${response.config.url}, response code: ${response.status} `);
        sessionStorage.setItem(`fetched-${path}At`, 0);
        return {
            ...response.data,
            body: response.data.body,
            error: true,
        };
    }
};

// #region offline data handling
const handleSetOfflineData = (path, data, groupOverride) => {
    try {
        // set the data in session storage
        if (groupOverride) {
            const key = `${groupOverride}-${path}`;
            sessionStorage.setItem(
                key,
                JSON.stringify({
                    timestamp: Date.now(),
                    data: data,
                })
            );
        } else {
            sessionStorage.setItem(
                path,
                JSON.stringify({
                    timestamp: Date.now(),
                    data: data,
                })
            );
        }
    } catch (error) {
        console.log(error);
    }
};

/*
const handleGetOfflineData = (path, groupOverride) => {
    try {
        // check if this key is in session storage
        // if it is, check if it's less than 24 hours old
        // if it's not, delete the key and return the error
        var offlineData;
        if (groupOverride) {
            const key = `${groupOverride}-${path}`;
            offlineData = sessionStorage.getItem(key);
        } else {
            offlineData = sessionStorage.getItem(path);
        }

        if (offlineData) {
            const parsedData = JSON.parse(offlineData);
            const now = Date.now();
            const difference = now - parsedData.timestamp;
            const hoursDifference = Math.floor(difference / 1000 / 60 / 60);
            if (hoursDifference < 24) {
                return {
                    ...parsedData.data,
                    body: parsedData.data.body,
                    error: parsedData.data.statusCode !== 200,
                };
            } else {
                sessionStorage.removeItem(path);
            }
        }
    } catch (error) {
        console.log(error);
    }
};
*/
// #endregion
