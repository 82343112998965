import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// Custom components
import { useTheme } from '@emotion/react';
import { callApiGWLambda, callApiGWLambdaGet } from '../../apis/api-gw';
import { SharedContext } from '../../contexts/SharedContext';
import { parseCodeResponse } from '../../helpers/lambdaResponseUtils';
import { formatTemplateStringVariable } from '../../helpers/stringUtils';
import { generateTemplateStringMap } from '../../helpers/tagUtils';
import Loading from '../shared/Loading';
import Snack from '../shared/Snack';
import Metadata from './Metadata';
import Responses from './Responses';
import Stepper from './Stepper';
import SurveyQuestion from './SurveyQuestion';
import Tags from './Tags';

const Survey = () => {
    const { shared } = React.useContext(SharedContext);
    const theme = useTheme();
    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(true);
    const [survey, setSurvey] = React.useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = React.useState(-1);
    const [questions, setQuestions] = React.useState([]);
    const [templateStrings, setTemplateStrings] = React.useState({});
    const [errors, setErrors] = React.useState([]);
    const [back, setBack] = React.useState(false);
    const [showAnswers, setShowAnswers] = React.useState(false);
    // if screen is md, show the metadata dialog by default
    const [showMetadataDialog, setShowMetadataDialog] = React.useState(
        window.innerWidth <= theme.breakpoints.values.md
    );
    const [submitting, setSubmitting] = React.useState(false);
    const [snackState, setSnackState] = React.useState({
        open: false,
        message: '',
        severity: 'success',
    });

    React.useEffect(() => {
        let cancelled = false;

        const load = async () => {
            if (!shared) return;

            const code = sessionStorage.getItem('surveyCode');

            if (!code) {
                setSnackState({
                    open: true,
                    message: 'No code found.',
                    severity: 'error',
                });
            }
            const codeRes = await callApiGWLambdaGet(`surveys/${code}`);
            console.log('codeRes=', codeRes);
            if (codeRes.statusCode !== 200)
                return setSnackState({
                    open: true,
                    message: codeRes.body || codeRes.errorMessage,
                    severity: 'error',
                });
            const codeJson = JSON.parse(codeRes.body);
            const codeData = parseCodeResponse(codeJson);
            const tags = codeData.tags;
            if (codeData && codeData.claimed) {
                return setSnackState({
                    open: true,
                    message: `Code ${code} has already been claimed. Please contact your administrator to gain a different code.`,
                    severity: 'error',
                });
            }

            const { global, tenant } = shared || {};
            if (!global || !tenant) return;

            const { supportedQuestionTypes } = global || {};
            const { customerManaged } = tenant || {};
            const { hierarchies, attributes, surveys } = customerManaged || {};
            const allSurveys = [...(global.surveys || []), ...(surveys || [])];
            if (!allSurveys)
                return setSnackState({
                    open: true,
                    message: `No surveys found in global configuration.`,
                    severity: 'error',
                });

            if (!codeData)
                return setSnackState({
                    open: true,
                    message:
                        'Session code found, but no information relating to the code was found. Please contact your administrator to gain a different code.',
                    severity: 'error',
                });

            const foundSurvey = allSurveys.find(s => s.uuid === codeData.surveyId);
            if (!foundSurvey) {
                console.log(`${codeData.id} is a valid code but is associated to a survey that was not found. Waiting for state...
				`);
                return;
            }
            const questions = foundSurvey.questions;
            if (!questions)
                return setSnackState({
                    open: true,
                    message: `Survey found, but no questions were found associated to this survey. Please contact your administrator to gain a different code.`,
                    severity: 'error',
                });

            // find any attributes of type 'templateString'
            var tsMap = generateTemplateStringMap(tags, attributes, hierarchies);
            setTemplateStrings(tsMap); // could be used in the survey metadata, so add to state

            const formattedQuestion = questions.map((q, i) => {
                // if no template strings, return question
                if (!tsMap.length) return q;
                // find any variables in the question text. ex: {leader} {organization}
                const questionVars = q.questionText.match(/{(.*?)}/g) || [];
                // replace the variables with the actual values
                const formattedQuestionText = questionVars.reduce((acc, curr) => {
                    const ts = tsMap.find(ts => ts.tsKey === curr);
                    if (!ts) return acc;
                    return acc.replace(curr, formatTemplateStringVariable(ts.tsValue));
                }, q.questionText);

                return {
                    ...q,
                    index: i,
                    questionText: formattedQuestionText || q.questionText,
                    options: supportedQuestionTypes.find(o => o.uuid === q.responseTypeId),
                    response: null,
                    // dummy response for testing. random number between 1 and 5
                    //response: Math.floor(Math.random() * 5) + 1,
                };
            });

            if (!cancelled) {
                setSurvey({
                    ...foundSurvey,
                    metadata: foundSurvey.metadata,
                    code: {
                        ...codeData,
                        tags: tags,
                    },
                });
                setQuestions(formattedQuestion);
                setLoading(false);
            }
        };
        console.log('loading survey');
        load();
        console.log('survey loaded');
        return () => (cancelled = true);
    }, [shared]);

    const handleNext = () => {
        setCurrentQuestionIndex(prevIndex => (prevIndex === -1 ? 0 : prevIndex + 1));
        setBack(false);
        // if last question, initiate validation automatically and go to next screen
        if (currentQuestionIndex === questions.length - 1) handleValidate();
    };

    const handleBack = () => {
        setCurrentQuestionIndex(prevIndex => prevIndex - 1); // if -1, will go to -2, which will render the intro
        setBack(true);
    };

    const handleJumpToLast = () => {
        setCurrentQuestionIndex(questions.length - 1);
        setBack(false);
    };

    const handleJumpToFirst = () => {
        setCurrentQuestionIndex(0);
        setBack(true);
    };

    const handleJumpToIndex = index => {
        setCurrentQuestionIndex(index);
        setBack(index < currentQuestionIndex);
    };

    const handleAnswer = (answer, multiple = false) => {
        // the Copilot way
        setQuestions(prevQuestions => {
            const newQuestions = [...prevQuestions];
            if (multiple) {
                // append to comma separated string
                // or remove if already in the string
                const currentResponse = newQuestions[currentQuestionIndex].response;
                if (currentResponse === null || currentResponse === '') {
                    newQuestions[currentQuestionIndex].response = answer;
                } else {
                    const responses = currentResponse.split(',');
                    if (responses.includes(answer)) {
                        const index = responses.indexOf(answer);
                        responses.splice(index, 1);
                    } else {
                        responses.push(answer);
                    }
                    newQuestions[currentQuestionIndex].response = responses.join(',');
                }
                return newQuestions;
            } else {
                newQuestions[currentQuestionIndex].response = answer;
                return newQuestions;
            }
        });

        // remove any errors for this question
        if (errors.includes(questions[currentQuestionIndex].id)) {
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                const index = newErrors.indexOf(questions[currentQuestionIndex].id);
                newErrors.splice(index, 1);
                return newErrors;
            });
        }

        // if last question, initiate validation automatically and go to next screen
        if (!multiple && currentQuestionIndex === questions.length - 1) handleValidate();

        // if anything but first question, go to next question
        if (!multiple && currentQuestionIndex !== -1) handleNext();
    };

    const handleSubmit = async () => {
        try {
            console.log('submitting code');
            setSubmitting(true);
            const responses = questions.map(q => {
                return {
                    questionId: q.uuid,
                    userResponse: `${q.response}`, // api is expecting a string even if it's a number
                };
            });
            const tenant = shared?.tenant?.readOnly?.subdomain;
            console.log(tenant);
            const final = {
                groupId: tenant,
                surveyId: survey.uuid,
                code: survey.code.id,
                responses: responses,
                pitTags: survey.code.tags,
            };

            // post to api
            const submission = await callApiGWLambda('post', 'surveys', final);

            if (submission.data.statusCode !== 200) {
                console.log(submission);
            } else {
                navigate('/thank-you');
            }
            setSubmitting(false);
        } catch (error) {
            console.log(error);
        } finally {
            setSubmitting(false);
        }
    };

    const handleValidate = () => {
        // check if all required questions have been answered
        const requiredQuestions = questions.filter(q => q.required);
        const requiredQuestionsAnswered = requiredQuestions.filter(q => q.response !== null && q.response !== '');
        if (requiredQuestionsAnswered.length !== requiredQuestions.length) {
            // if not, add the question ids to the errors array
            const requiredQuestionsNotAnswered = requiredQuestions.filter(
                q => q.response === null || q.response === ''
            );
            const requiredQuestionIds = requiredQuestionsNotAnswered.map(q => q.id);
            setErrors(prevErrors => {
                const newErrors = [...prevErrors];
                requiredQuestionIds.forEach(id => {
                    if (!newErrors.includes(id)) newErrors.push(id);
                });
                return newErrors;
            });
            return false;
        } else {
            return true;
        }
    };

    const SurveyInfo = () => (
        <Box flex='1 0 100px'>
            <Grid container spacing={2} pt={2}>
                <Grid item xs={12} sm={6}>
                    <Metadata metadata={survey.metadata} templateStrings={templateStrings} />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    display='flex'
                    flexDirection='column'
                    alignItems={showMetadataDialog ? 'flex-start' : 'flex-end'}
                    borderLeft={showMetadataDialog ? 'none' : '1px solid #ccc'}
                    borderTop={showMetadataDialog ? '1px solid #ccc' : 'none'}
                    mt={showMetadataDialog ? 2 : 0}
                >
                    <Tags code={survey.code} tenantData={shared.tenant} />
                </Grid>
            </Grid>
        </Box>
    );

    // console.log('questions=', questions);
    return (
        <>
            <Snack
                {...snackState}
                handleClose={() => {
                    setSnackState({ ...snackState, open: false });
                    navigate('/');
                }}
            />
            {loading || questions?.length === 0 ? (
                <Loading text='One moment, loading up your survey...' />
            ) : submitting ? (
                <Loading text='Submitting your responses...' />
            ) : (
                <Container fixed maxWidth={false}>
                    <Paper
                        sx={{
                            m: '2rem',
                            p: '1rem',
                            minHeight: '60rem',
                            overflowX: 'hidden',
                        }}
                    >
                        <Box display='flex' flexDirection='column'>
                            <Hidden mdDown>
                                <SurveyInfo />
                            </Hidden>
                            <Hidden mdUp>
                                <Box display='flex' justifyContent='center'>
                                    <Button onClick={() => setShowMetadataDialog(true)}>What Survey is This?</Button>
                                </Box>
                            </Hidden>
                            <Dialog open={showMetadataDialog} onClose={() => setShowMetadataDialog(false)}>
                                <DialogContent>
                                    <SurveyInfo />
                                </DialogContent>
                            </Dialog>
                            <Box flex='1 0 100px'>
                                <Box textAlign='center' mt='2rem'>
                                    <Stepper
                                        currentIndex={currentQuestionIndex}
                                        questionCount={questions.length}
                                        handleNext={handleNext}
                                        handleBack={handleBack}
                                        handleJumpToLast={handleJumpToLast}
                                        handleJumpToFirst={handleJumpToFirst}
                                    />
                                </Box>
                            </Box>
                            <Box flex='2 0 20rem'>
                                <Box xs={12} id='question' display='flex' flexDirection='column' alignItems='center'>
                                    {(currentQuestionIndex >= 0 || currentQuestionIndex === questions.length) && (
                                        <SurveyQuestion
                                            thisQuestionIndex={currentQuestionIndex}
                                            questions={questions}
                                            handleAnswer={handleAnswer}
                                            back={back}
                                            next={handleNext}
                                            done={currentQuestionIndex === questions.length}
                                        />
                                    )}
                                </Box>
                                <Box display='flex' flexDirection='column' alignItems='center' alignContent='center'>
                                    {currentQuestionIndex === questions.length && (
                                        <Button
                                            variant='contained'
                                            onClick={handleSubmit}
                                            disabled={errors && errors.length > 0}
                                            sx={{ m: '2rem' }}
                                        >
                                            Submit Survey
                                        </Button>
                                    )}
                                    {errors && errors.length > 0 && (
                                        <Typography variant='caption' color='error' gutterBottom>
                                            Please go back answer all required questions. You can view all answers by
                                            pressing the "Show/Hide Answers button below."
                                        </Typography>
                                    )}

                                    {currentQuestionIndex === -1 && questions.length && (
                                        <Button variant='contained' onClick={handleNext}>
                                            Begin Survey
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                            <Box display='flex' justifyContent='center' mt={2}>
                                <Button onClick={() => setShowAnswers(!showAnswers)} size='small'>
                                    {showAnswers ? 'Hide' : 'Show'} Responses
                                </Button>
                            </Box>
                            {showAnswers && (
                                <Box flex='1 0 100px' overflowY='scroll'>
                                    <Divider />
                                    {questions && currentQuestionIndex > -1 && (
                                        <Responses
                                            seenQuestions={questions.slice(0, currentQuestionIndex + 1)}
                                            errors={errors}
                                            onJumpToIndex={handleJumpToIndex}
                                        />
                                    )}
                                </Box>
                            )}
                        </Box>
                    </Paper>
                </Container>
            )}
        </>
    );
};

export default Survey;
