import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@mui/material';

// yarn add @nivo/core @nivo/bar
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const MyBar = ({ data, config, overrides }) => {
	const { keys, defs, fill, indexBy, groupMode } = config;
	const { height, width, ...restOverrides } = overrides;

	return (
		<Box height={height || 400} width={width || '100%'}>
			<ResponsiveBar
				// **data and config**
				data={data}
				keys={keys || []}
				indexBy={indexBy || null}
				groupMode={groupMode || 'stacked'}
				defs={defs || []}
				fill={fill || []}
				// **style overrides**
				minValue={'auto'}
				maxValue={'auto'}
				margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				colors={{ scheme: 'nivo' }}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				axisTop={null}
				axisRight={null}
				axisBottom={{
					tickSize: 5,
					tickPadding: 3,
					tickRotation: -30,
					// format: (d, i, a) => {
					// 	console.log(d);
					// 	console.log(i);
					// 	console.log(a);
					// 	return d;
					// }
				}}
				axisLeft={null}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				// labelFormat={d => d}
				layers={[
					'grid',
					'axes',
					'markers',
					'legends',
					'annotations',
					'bars',
					// ({ bars }) => {
					// 	console.log(bars);
					// 	return bars.map(bar => {
					// 		console.log(bar);
					// 		return (
					// 			<g>
					// 				<text
					// 					x={bar.x + bar.width / 2}
					// 					y={bar.y + bar.height / 2}
					// 					fill='black'
					// 					textAnchor='middle'
					// 					dominantBaseline='middle'
					// 					fontSize={16}
					// 				>
					// 					{bar.data.value}
					// 				</text>
					// 			</g>
					// 		);
					// 	});
					// },
				]}
				legends={[]} // need to pass this in if you want legand(s)
				role='application'
				// **overrides: this will override anything in the previous section**
				{...restOverrides}
			/>
		</Box>
	);
};

export default MyBar;
