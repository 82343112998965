import React from 'react';
import Routes from './Routes';
import { getCDNContent } from '../../apis/cdn';
import { ThemeProvider } from '@emotion/react';
import { SharedProvider } from '../../contexts/SharedContext';
import theme from '../../theme';


const App = () => {
	React.useEffect(() => {
		getCDNContent();
	}, []);

	return (
		<React.StrictMode>
			<SharedProvider>
				<ThemeProvider theme={theme}>
					<Routes />
				</ThemeProvider>
			</SharedProvider>
		</React.StrictMode>
	);
};

export default App;
