import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { getAttributeByOptionId, getOptionById } from '../../../helpers/attributUtils';
import { convertCamelCaseToTitleCase, scrubBrackets } from '../../../helpers/stringUtils';
import { findTagLinks } from '../../../helpers/tagUtils';
import { findNode } from '../../../helpers/treeUtils';

const Tags = ({ tags, surveys, attributes, hierarchies, handleSetFiltersOnTagClick }) => {
	const [orderedTags, setOrderedTags] = React.useState([]);

	React.useEffect(() => {
		if (!tags) return;
		try {
			const linkedTags = tags.map(lt => findTagLinks(lt, attributes, hierarchies)).flat();
			const ordered = tags
				.map(t => {
					const linkedTag = linkedTags.find(lt => lt.attributeId === t.attributeId);
					return {
						...t,
						linked: linkedTag ? true : false,
					};
				})
				.sort((a, b) => {
					if (a.key === 'surveyId' || b.key === 'surveyId') return 1;

					var tagAAttribute = getAttributeByOptionId(attributes, a.value);
					var tagBAttribute = getAttributeByOptionId(attributes, b.value);

					if (!Object.keys(tagAAttribute).length) {
						const tagAHierarchy = hierarchies.find(h => h.uuid === a.hierarchyId);
						if (tagAHierarchy) {
							const tagANode = findNode(tagAHierarchy.nodes, a.value);
							if (tagANode) {
								tagAAttribute = getAttributeByOptionId(
									attributes,
									tagANode.attributeOptionId
								);
							} else {
								return -1;
							}
						} else {
							return -1;
						}
					}
					if (!Object.keys(tagBAttribute).length) {
						const tagBHierarchy = hierarchies.find(h => h.uuid === b.hierarchyId);
						if (tagBHierarchy) {
							const tagBNode = findNode(tagBHierarchy.nodes, b.value);
							if (tagBNode) {
								tagBAttribute = getAttributeByOptionId(
									attributes,
									tagBNode.attributeOptionId
								);
							} else {
								return -1;
							}
						} else {
							return -1;
						}
					}

					// sort by tagAttribute.order property, then by tagAttribute.name
					if (tagAAttribute.order < tagBAttribute.order) return -1;
					if (tagAAttribute.order > tagBAttribute.order) return 1;
					if (tagAAttribute.name < tagBAttribute.name) return -1;
					if (tagAAttribute.name > tagBAttribute.name) return 1;
					return 0;
				});
			setOrderedTags(ordered);
		} catch (error) {
			console.log(error);
		}
	}, []);

	const tagLabel = tag => {
		try {
			if (tag.key === 'surveyId') {
				const survey = surveys.find(s => s.uuid === tag.value);
				return (
					<Tooltip title={`Click to filter this column on "${survey.metadata.name}"`}>
						<a onClick={() => handleSetFiltersOnTagClick(survey.metadata.name)}>
							{survey.metadata.name}
						</a>
					</Tooltip>
				);
			} else if (tag.hierarchyId) {
				const hierarchy = hierarchies.find(h => h.uuid === tag.hierarchyId);
				const node = findNode(hierarchy.nodes, tag.value);
				const readable = `${scrubBrackets(hierarchy.name)}: ${node.value || 'N/A'}`;
				return (
					<Tooltip title={`Click to filter this column on "${node.value}"`}>
						<a onClick={() => handleSetFiltersOnTagClick(readable)}>{readable}</a>
					</Tooltip>
				);
			} else if (tag.attributeId) {
				const attribute = attributes.find(a => a.uuid === tag.attributeId);
				const option = getOptionById(attribute, tag.value);
				const readable = `${scrubBrackets(attribute.key)}: ${option.value || 'N/A'}`;
				return (
					<Tooltip title={`Click to filter this column on "${option.value}"`}>
						<a onClick={() => handleSetFiltersOnTagClick(readable)}>{readable}</a>
					</Tooltip>
				);
			} else {
				const attribute = getAttributeByOptionId(attributes, tag.value);
				const option = getOptionById(attribute, tag.value);
				const readable = `${scrubBrackets(attribute.key)}: ${option.value || 'N/A'}`;
				return (
					<Tooltip title={`Click to filter this column on "${option.value}"`}>
						<a onClick={() => handleSetFiltersOnTagClick(readable)}>{readable}</a>
					</Tooltip>
				);
			}
		} catch (error) {
			console.log(error);
			return 'N/A';
		}
	};

	return orderedTags.map(tag => {
		return (
			<Box display='flex' flexDirection='column'>
				<Box
					key={tag.key}
					id='tag'
					display='flex'
					alignItems='center'
					sx={{
						cursor: 'pointer',
						'&:hover': {
							backgroundColor: 'rgba(0, 0, 0, 0.04)',
						},
						fontStyle: tag.linked ? 'italic' : 'normal',
					}}
				>
					{tagLabel(tag)}
				</Box>
			</Box>
		);
	});
};

export default Tags;
