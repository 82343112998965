import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';
import NewAttributeOption from './NewOptionPanel';

const TabPanel = ({ children, value, current, ...other }) => (
	<div
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		{...other}
	>
		{value === current && (
			<Box sx={{ p: 3 }}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</div>
);

const TabPanels = ({
	selectedTab,
	handleRemoveAttribute,
	handleRestoreAttribute,
	handleAddOption,
	handleRemoveOption,
	handleRestoreOption,
	activeAttribute,
}) => {
	const [confirmDeleteAttribute, setConfirmDeleteAttribute] = React.useState(false);
	const [confirmDeleteOption, setConfirmDeleteOption] = React.useState(false);

	const ConfirmDeleteAttribute = () => {
		return (
			<Dialog
				open={!!confirmDeleteAttribute}
				onClose={() => setConfirmDeleteAttribute(false)}
			>
				<DialogTitle color='error'>
					<strong>Confirm</strong>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to proceed with deleting this attribute?
					</DialogContentText>
					<DialogContentText color='error'>
						{`Deleting Attribute: ${activeAttribute.key || confirmDeleteAttribute}`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setConfirmDeleteAttribute(false)}>Cancel</Button>
					<Button
						onClick={() => {
							handleRemoveAttribute(activeAttribute);
							setConfirmDeleteAttribute(false);
						}}
					>
						Delete Attribute
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const ConfirmDeleteOption = () => {
		const option = (activeAttribute.options || []).find(o => o.uuid === confirmDeleteOption);
		return (
			<Dialog open={!!confirmDeleteOption} onClose={() => setConfirmDeleteOption(false)}>
				<DialogTitle color='error'>
					<strong>Confirm</strong>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to proceed with deleting this option?
					</DialogContentText>
					<DialogContentText color='error'>
						{`Deleting Option: ${option.value || confirmDeleteOption}`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setConfirmDeleteOption(false)}>Cancel</Button>
					<Button
						onClick={() => {
							handleRemoveOption(option);
							setConfirmDeleteOption(false);
						}}
					>
						Delete Option
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	const readonly = activeAttribute.deleted;

	return (
		<>
			{confirmDeleteAttribute && <ConfirmDeleteAttribute open={confirmDeleteAttribute} />}
			{confirmDeleteOption && <ConfirmDeleteOption open={confirmDeleteOption} />}
			{Object.keys(activeAttribute).length ? (
				<TabPanel value={activeAttribute.uuid} current={selectedTab}>
					<Box
						display='flex'
						justifyContent='flex-start'
						alignItems='center'
						p={1}
						sx={{
							cursor: 'pointer',
						}}
					>
						<Typography
							variant='h6'
							sx={{
								textDecoration: readonly ? 'line-through' : 'none',
								color: readonly ? 'error.main' : 'text.primary',
							}}
						>
							{convertCamelCaseToTitleCase(activeAttribute.key)}
						</Typography>
						{!readonly && (
							<RemoveIcon
								sx={{ cursor: 'pointer' }}
								onClick={() => setConfirmDeleteAttribute(true)}
							/>
						)}
						{readonly && (
							<>
								<AddIcon onClick={() => handleRestoreAttribute(activeAttribute)} />

								<Typography variant='caption' color='error' mx={1}>
									Deleted - To edit this attribute, restore it first.
								</Typography>
							</>
						)}
					</Box>
					{!readonly && <NewAttributeOption handleAdd={handleAddOption} />}
					{(activeAttribute.options || [])
						.sort((a, b) => !b.deleted - !a.deleted)
						.map(o => (
						<Box key={o.uuid} display='flex' justifyContent='flex-start'>
							<Typography
								variant='subtitle1'
								sx={{
									textDecoration: o.deleted ? 'line-through' : 'none',
									color: o.deleted ? 'error.main' : 'text.primary',
								}}
							>
								{o.value}
							</Typography>

							<Box
								key={o.uuid}
								component='span'
								display={readonly ? 'none' : 'flex'}
								pl={1}
								sx={{
									cursor: 'pointer',
								}}
							>
								{!o.deleted && (
									<RemoveIcon onClick={() => setConfirmDeleteOption(o.uuid)} />
								)}
								{o.deleted && <AddIcon onClick={() => handleRestoreOption(o)} />}
							</Box>
						</Box>
					))}
				</TabPanel>
			) : null}
		</>
	);
};

export default TabPanels;
