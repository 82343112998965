import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, IconButton } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import React from 'react';
import { initQuestion } from '../../../helpers/surveyUtils';

const NewQuestion = ({ responseTypeOptions, handleAddNewQuestion }) => {
	const [newQuestion, setNewQuestion] = React.useState(initQuestion());

	return (
		<Grid container spacing={1} my={1}>
			<Grid item xs={1} display='flex' alignItems='center'>
				<IconButton
					onClick={() => setNewQuestion(initQuestion())}
					disabled={newQuestion.questionText === ''}
				>
					<DeleteIcon />
				</IconButton>
				<IconButton
					onClick={() => {
						handleAddNewQuestion(newQuestion);
						setNewQuestion(initQuestion());
					}}
					disabled={newQuestion.questionText === ''}
				>
					<SaveIcon />
				</IconButton>
			</Grid>
			<Grid item xs={1} display='flex' alignItems='center'>
				<Checkbox
					name={`${newQuestion.uuid}-required`}
					checked={newQuestion.required || false}
					onChange={e => setNewQuestion({ ...newQuestion, required: e.target.checked })}
					size='small'
				/>
			</Grid>
			<Grid item xs={3} display='flex' alignItems='center'>
				<Autocomplete
					name={`${newQuestion.uuid}-type`}
					options={responseTypeOptions}
					getOptionLabel={option => option.label || ''}
					value={
						responseTypeOptions.find(t => t.value === newQuestion.responseTypeId) || {
							value: null,
							label: 'Select Type',
						}
					}
					onChange={(e, value) =>
						setNewQuestion({ ...newQuestion, responseTypeId: value.value })
					}
					renderInput={params => (
						<TextField {...params} variant='outlined' size='small' />
					)}
					fullWidth
				/>
			</Grid>
			<Grid item xs={7}>
				<TextField
					name={`${newQuestion.uuid}-text`}
					variant='outlined'
					size='small'
					value={newQuestion.questionText || ''}
					onChange={e => setNewQuestion({ ...newQuestion, questionText: e.target.value })}
					fullWidth
					multiline
					minRows={1}
				/>
			</Grid>
			<Divider
				orientation='horizontal'
				flexItem
				style={{ width: '100%', marginTop: '10px' }}
			/>
		</Grid>
	);
};

export default NewQuestion;
