import Box from '@mui/material/Box';
import React from 'react';
import { avgAllResponses } from '../../../helpers/surveyUtils';
import { colorSchemes } from '@nivo/colors';
import {
	depth,
	findAOIdFromPathString,
	findNode,
	findNodeFromAttributeOption,
	getHierarchyFromNodeId,
	isChildOf,
} from '../../../helpers/treeUtils';
import { getOptionFromAllById } from '../../../helpers/attributUtils';
import HierarchyBar from '../generics/HierarchyBar';
import { Container, Typography } from '@mui/material';

const initConfig = {
	keys: [], // keys to group by
	defs: [],
	fill: [],
	indexBy: 'name',
	margins: { mt: 50, mr: 50, mb: 100, ml: 75 },
	// groupMode: 'grouped', // 'stacked' or 'grouped'
};

const HierarchyBarWrapper = ({ data, filters, lookupData, aggregate, title }) => {
	const [config, setConfig] = React.useState(initConfig);
	const [formattedData, setFormattedData] = React.useState([]);
	const [nodeMap, setNodeMap] = React.useState(new Map());
	const [hierarchyErrors, setHierarchyErrors] = React.useState([]);
	const colorScheme = colorSchemes.nivo;

	React.useEffect(() => {
		var map = new Map();
		Object.entries(data).forEach(([path, submissions]) => {
			/*
			need to accumulate the data for each node in the path
			e.g.
			{
				path: 'dept1>dept1.1>dept1.1.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.1'
				submissions: [all the submissions for that path]
			},
			{
				path: 'dept1>dept1.2>dept1.2.2'
				submissions: [all the submissions for that path]
			}
			should produce a map like this:
			{
				dept1: [all the submissions for dept1],
				dept1.1: [all the submissions for dept1.1],
				dept1.1.1: [all the submissions for dept1.1.1],
				dept1.2: [all the submissions for dept1.2],
				dept1.2.1: [all the submissions for dept1.2.1],
				dept1.2.2: [all the submissions for dept1.2.2],
			}
			*/
			const nodePath = path.split('>');

			if (aggregate) {
				nodePath.forEach(node => {
					if (!map.has(node)) {
						map.set(node, []);
					}
					map.get(node).push(...submissions);
				});
			} else {
				// last node is the leader node
				const leafNode = nodePath[nodePath.length - 1];
				if (!map.has(leafNode)) {
					map.set(leafNode, []);
				}
				map.get(leafNode).push(...submissions);
			}
		});

		setNodeMap(map);
	}, [data]);

	React.useEffect(() => {
		const { attributes, hierarchies } = lookupData;
		const colors = colorScheme.flatMap(color => color);
		const colorCount = colors.length;

		// now calc the avgs of all the submissions within each node in the map
		// also need to lookup the name of the node (key)
		const formattedData = [];
		const keyNames = [];
		const tmpHierarchyErrors = [];
		nodeMap.forEach((submissions, key) => {
			const hierarchy = getHierarchyFromNodeId(hierarchies, key);

			if (!hierarchy) {
				const option = getOptionFromAllById(attributes, key);
				return tmpHierarchyErrors.push(
					option?.value
						? `${option.value} is not included in any hierarchy. Please go to the hierarchy page to add it.`
						: 'No hierarchy or attribute option found. If something was recently added, please refresh the page.'
				);
			}

			const { nodes } = hierarchy;
			const node = findNode(nodes, key);
			const nodeDepth = depth(nodes, node);
			const nodeAOId = findAOIdFromPathString(hierarchies, key);
			const option = getOptionFromAllById(attributes, nodeAOId);
			const isChildOfSelectedLeader = checkIsChildOfSelectedLeader(
				nodes,
				attributes,
				submissions,
				key
			);

			// hacky way to do this, but the value needs to be unique for grouping to work. handle the hiding in the nivo component
			const name = isChildOfSelectedLeader ? option.value || 'n/a' : `hide|${option.value}`;
			const avg = avgAllResponses(submissions);
			const color = colors[formattedData.length % colorCount];

			if (!keyNames.includes(`hier_level_${nodeDepth}`)) {
				keyNames.push(`hier_level_${nodeDepth}`);
			}
			formattedData.push({
				name: name,
				[`hier_level_${nodeDepth}`]: isNaN(avg) ? 0 : avg,
				[`hier_level_${nodeDepth}Color`]: color,
				sortValue: isNaN(avg) ? 0 : avg,
			});
		});

		setConfig({
			...config,
			keys: keyNames,
		});
		// sort the data by avg
		const sorted = formattedData.sort((a, b) => b.sortValue - a.sortValue);
		setFormattedData(sorted);
		setHierarchyErrors(tmpHierarchyErrors);
	}, [nodeMap, filters, lookupData]);

	const checkIsChildOfSelectedLeader = (nodes, attributes, submissions, key) => {
		try {
			const { leader } = filters;
			const leaderNode = findNodeFromAttributeOption(nodes, leader.leaderId);

			if (!leaderNode) {
				const leaderOption = getOptionFromAllById(attributes, leader.leaderId);
				// console.log(leaderOption);
				if (leaderOption && leaderOption.links) {
					for (const id of leaderOption.links) {
						const linkedLeaderNode = findNodeFromAttributeOption(nodes, id);
						const isChild = isChildOf(nodes, linkedLeaderNode.uuid, key);
						if (isChild) return true;
					}
					return false;
				} else {
					// no linked attributes, last attempt is to try and find a survey tag with the leader id
					return true; // for now, assuming its an org report and not an individual report
				}
			}
			return isChildOf(nodes, leaderNode.uuid, key);
		} catch (e) {
			console.log(e);
			return false;
		}
	};

	// console.log(nodeMap);
	// console.log(formattedData);
	return (
		<Container maxWidth={false} disableGutters>
			<Typography variant='h6' align='center' py={2}>
				{title || 'Hierarchy Bar'}
			</Typography>

			<Box height={'100%'} width={'100%'}>
				<HierarchyBar data={formattedData} config={config} />
			</Box>
			{hierarchyErrors.length > 0 && (
				<>
					<Typography variant='subtitle2' color='error'>
						Errors:
					</Typography>
					{hierarchyErrors.map((error, i) => (
						<Typography key={i} variant='body2'>
							{error}
						</Typography>
					))}
				</>
			)}
		</Container>
	);
};

export default HierarchyBarWrapper;
