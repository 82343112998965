// simple axios function to post survey responses to our API
import axios from 'axios';
import getTenantEnv from './getEnv';

// To be replaced by the endpoint of the API deployed through the CloudFormation Template
const tenantEnv = getTenantEnv();
const APIEndPoint = `https://e6xb08goec.execute-api.us-west-2.amazonaws.com/${tenantEnv}/index`;

// Default headers for the API
axios.defaults.headers.common['Content-Type'] = 'application/json';

export const getCDNContent = async () => {
    try {
        const response = await axios.get(APIEndPoint);
        if (response.status !== 200) {
            console.log('Error getting CDN content');
            return 'Error getting CDN content';
        }
        // console.log(response.data.message);
        return response.data.message;
    } catch (error) {
        console.log(error);
        return 'Error getting CDN content';
    }
}
