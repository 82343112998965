// React Context that is config between all components

import React, { createContext, useState } from 'react';
import { callApiGWLambdaGet } from '../apis/api-gw';
import { SharedContext } from './SharedContext';

export const AdminContext = createContext(null);

const REFRESH_DEV = true;

export const AdminProvider = props => {
	// inherit SharedContext as well
	const { shared, fetchCodes, setCodesPrinted } = React.useContext(SharedContext);
	const [config, setConfig] = useState({
		shared: shared,
	});

	// Load tenantConfig from api on initial load
	React.useEffect(() => {
		let canceled = false;

		const setSharedOnChange = async () => {
			if (!canceled) setConfig({ ...config, shared: shared });
		};
		setSharedOnChange();

		return () => (canceled = true);
	}, [shared]);

	// Load tenantConfig from api on initial loadgetTenantConfig
	const refreshConfig = React.useCallback(() => {
		let canceled = false;

		const getTenantConfig = async () => {
			const response = await callApiGWLambdaGet('config');
			if (response.statusCode !== 200) {
				if (!canceled) setConfig({ ...config, admin: [] });
				return;
			}

			const data = JSON.parse(response.body);
			if (!canceled) setConfig({ ...config, admin: data });
		};
		getTenantConfig();

		return () => (canceled = true);
	}, []);

	React.useEffect(() => {
		refreshConfig();
	}, [refreshConfig]);

	return (
		<AdminContext.Provider
			value={{ config, setConfig, fetchCodes, refreshConfig, setCodesPrinted }}
		>
			{props.children}
		</AdminContext.Provider>
	);
};

// Path: src\contexts\AdminContext.js
// React Context that is shared between admin components
