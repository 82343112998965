import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';

const ThankYou = () => {

    const [autoRedirectIn, setAutoRedirectIn] = React.useState(7);

    React.useEffect(() => {
        if (autoRedirectIn === 0) {
            window.location.href = '/';
        }
        const timer = setInterval(() => {
            setAutoRedirectIn(autoRedirectIn - 1);
        }, 1000);
        return () => clearInterval(timer);
    }, [autoRedirectIn]);

    return (
		<Dialog open={true} maxWidth='xs' fullWidth >
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Typography variant='subtitle1' gutterBottom>
                Your responses have been recorded. Thank you for taking the time to complete this survey!
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center' }}>
                <Typography variant='subtitle2' gutterBottom>
                    {`This page will automatically redirect in ${autoRedirectIn} seconds.`}
                </Typography>
			</DialogContent>
		</Dialog>
    );
}

export default ThankYou;