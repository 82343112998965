import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React from 'react';
import { findSubTree, findLeafNodes } from '../../../helpers/treeUtils';

const Children = ({ tags, options, setOptions, hierarchy }) => {
	const all = findSubTree(hierarchy.nodes, tags.value);
	const onlyLeafs = findLeafNodes(hierarchy.nodes, tags.value);

	// set default options
	React.useEffect(() => {
		let { selectedOption } = options.hierarchyOptions;
		let nodesToGenerate;

		switch (selectedOption) {
			case 'includeParent':
				nodesToGenerate = [];
				break;
			case 'includeChildren':
				nodesToGenerate = onlyLeafs;
				break;
			case 'includeAll':
				nodesToGenerate = all;
				break;
			default:
				nodesToGenerate = onlyLeafs;
				selectedOption = 'includeChildren';
				break;
		}

		setOptions({
			...options,
			hierarchyOptions: {
				hierarchyId: hierarchy.uuid,
				name: hierarchy.name,
				[hierarchy.uuid]: true,
				selectedOption,
				nodesToGenerate,
			},
		});
	}, []);

	// console.log(tags);
	// console.log(options);
	return (
		<>
			{onlyLeafs && onlyLeafs.length > 0 ? (
				<>
					<Typography tags='body2'>
						The hierarchy node "{tags.label}" has {onlyLeafs.length} child nodes. Which
						of the following would you like to generate codes for?
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'column' }}>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										options.hierarchyOptions.selectedOption === 'includeParent'
									}
									onChange={e => {
										setOptions({
											...options,
											hierarchyOptions: {
												hierarchyId: hierarchy.uuid,
												name: hierarchy.name,
												[hierarchy.uuid]: e.target.checked,
												selectedOption: e.target.name,
												nodesToGenerate: e.target.checked && [],
											},
										});
									}}
									name='includeParent'
									color='primary'
									size='small'
								/>
							}
							label={`Only generate codes for "${tags.label}"`}
							sx={{ typography: 'body2' }}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										options.hierarchyOptions.selectedOption ===
										'includeChildren'
									}
									onChange={e => {
										setOptions({
											...options,
											hierarchyOptions: {
												hierarchyId: hierarchy.uuid,
												name: hierarchy.name,
												[hierarchy.uuid]: e.target.checked,
												selectedOption: e.target.name,
												nodesToGenerate: e.target.checked ? onlyLeafs : [],
											},
										});
									}}
									name='includeChildren'
									color='primary'
									size='small'
								/>
							}
							label={`Only generate codes for the ${onlyLeafs.length} child hierarchy nodes`}
							sx={{ typography: 'body2' }}
						/>
						<FormControlLabel
							control={
								<Checkbox
									checked={
										options.hierarchyOptions.selectedOption === 'includeAll'
									}
									onChange={e => {
										setOptions({
											...options,
											hierarchyOptions: {
												hierarchyId: hierarchy.uuid,
												name: hierarchy.name,
												[hierarchy.uuid]: e.target.checked,
												selectedOption: e.target.name,
												nodesToGenerate: e.target.checked ? all : [],
											},
										});
									}}
									name='includeAll'
									color='primary'
									size='small'
								/>
							}
							label={`Generate codes for "${tags.label}" and the ${onlyLeafs.length} child hierarchy nodes`}
							sx={{ typography: 'body2' }}
						/>
					</Box>
				</>
			) : (
				<span>Node has no child nodes</span>
			)}
		</>
	);
};

export default Children;
