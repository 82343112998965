import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import { convertCamelCaseToTitleCase } from '../../../helpers/stringUtils';
import { addAttribute } from '../../../helpers/attributUtils';

const SUPPORTED_DATA_TYPES = {
	STRING: 'string',
	NUMBER: 'number',
	BOOLEAN: 'boolean',
	DATE: 'date',
	TEMPLATE_STRING: 'templateString',
};

const TabPanel = ({ children, value, current, ...other }) => (
	<div
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		{...other}
	>
		{value === current && (
			<Box sx={{ p: 3 }}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</div>
);

const NewAttributePanel = ({ selectedTab, handleNewAttribute }) => {
	const [newAttribute, setNewAttribute] = React.useState({
		key: null,
		type: SUPPORTED_DATA_TYPES.STRING,
	});

	const handleAdd = () => {
		const { key, type } = newAttribute;
		if (key && type && key !== '') {
			const attribute = addAttribute(key, type);
			handleNewAttribute(attribute);

			setNewAttribute({
				key: null,
				type: SUPPORTED_DATA_TYPES.STRING,
			});
		}
	};

	// accept key down on Enter key as a submit
	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			handleAdd();
		}
	};

	return (
		<TabPanel value='new' current={selectedTab}>
			<Box display='flex' justifyContent='flex-start' alignItems='center' p={1}>
				<TextField
					id='newAttribute'
					label='New Attribute'
					variant='outlined'
					value={newAttribute.key || ''}
					onChange={e => {
						setNewAttribute({
							...newAttribute,
							key: e.target.value,
						});
					}}
					onKeyDown={handleKeyDown}
					size='small'
				/>
				<Select
					value={newAttribute.type || SUPPORTED_DATA_TYPES.STRING}
					onChange={e => {
						setNewAttribute({
							...newAttribute,
							type: e.target.value,
						});
					}}
					sx={{ minWidth: '10rem', ml: '1rem' }}
					size='small'
				>
					{Object.keys(SUPPORTED_DATA_TYPES).map(key => (
						<MenuItem key={key} value={SUPPORTED_DATA_TYPES[key]}>
							{convertCamelCaseToTitleCase(SUPPORTED_DATA_TYPES[key])}
						</MenuItem>
					))}
				</Select>
				<AddIcon sx={{ cursor: 'pointer' }} onClick={handleAdd} />
			</Box>
		</TabPanel>
	);
};

export default NewAttributePanel;
