import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Box } from '@mui/material';

// yarn add @nivo/core @nivo/bar
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const HierarchyBar = ({ data, config }) => {
	const { keys, defs, fill, indexBy, groupMode, height, width, legends, margins } = config;
	const { mt, mr, mb, ml } = margins || { mt: 50, mr: 50, mb: 50, ml: 125 };

	// console.log(margins);
	return (
		<Box height={height || 500} width={width || '100%'}>
			<ResponsiveBar
				// **data and config**
				data={data}
				keys={keys || []}
				indexBy={indexBy || null}
				groupMode={groupMode || 'stacked'}
				defs={defs || []}
				fill={fill || []}
				// **style overrides**
				minValue={0}
				maxValue={5}
				margin={{ top: mt, right: mr, bottom: mb, left: ml }}
				padding={0.3}
				valueScale={{ type: 'linear' }}
				indexScale={{ type: 'band', round: true }}
				// colors={{ scheme: 'nivo' }}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				axisBottom={{
					tickSize: 5,
					tickPadding: 3,
					tickRotation: -40,
					format: d => {
						try {
							return d?.includes('hide|') ? '' : d;
						} catch (e) {
							console.log(e);
							return d;
						}
					},
				}}
				labelSkipWidth={12}
				labelSkipHeight={12}
				labelTextColor={{
					from: 'color',
					modifiers: [['darker', 1.6]],
				}}
				valueFormat=' >-1.2f' // 2 decimal places
				layers={[
					'grid',
					'axes',
					'markers',
					'legends',
					'annotations',
					// 'bars',
					({ bars }) => {
						// console.log(bars);
						return bars.map(bar => {
							// console.log(bar.data);
							return (
								<g>
									<rect
										x={bar.x}
										y={bar.y}
										width={bar.width}
										height={bar.height}
										fill={bar.color || 'rgba(0, 0, 0, .1)'}
										stroke={bar.borderColor}
										strokeWidth={bar.borderWidth}
										strokeOpacity={bar.borderOpacity}
										// onMouseEnter={() => {
										// 	// console.log('enter');
										// }}
										// onMouseLeave={() => {
										// 	// console.log('leave');
										// }}
									/>
									{bar.data.value && bar.data.value > 0 && (
										<text
											x={bar.x + bar.width / 2}
											y={bar.y + bar.height / 2}
											fill='black'
											textAnchor='middle'
											dominantBaseline='central'
											pointerEvents='none'
											// rotate={-90}
											transform={`rotate(-90 ${bar.x + bar.width / 2} ${
												bar.y + bar.height / 2
											})`}
											fontSize='.8rem'
										>
											{bar.data.formattedValue}
										</text>
									)}
								</g>
							);
						});
					},
				]}
				legends={legends || []} // need to pass this in if you want legand(s)
				role='application'
			/>
		</Box>
	);
};

export default HierarchyBar;
