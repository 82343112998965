// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/boxplot
import { Box } from '@mui/material';
import { ResponsiveBoxPlot } from '@nivo/boxplot';

const BoxPlot = ({ data, groups }) => {
	const calcedHeight = groups.size * 30;
	const height = calcedHeight > 300 ? calcedHeight : 300;
	return (
		<Box height={height} width={'100%'}>
			<ResponsiveBoxPlot
				data={data}
				margin={{ top: 60, right: 200, bottom: 60, left: 200 }}
				minValue={0}
				maxValue={5}
				// change orientation to 'horizontal' to flip chart
				layout='horizontal'
				// subGroups={['-1', '0', '1', '2', '3', '4', '5']}
				// colorBy='subgroup'
				// quantiles={['0.25', '0.5', '0.75']}
				padding={0.12}
				enableGridX={true}
				axisTop={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendOffset: 36,
				}}
				axisRight={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: '',
					legendOffset: 0,
				}}
				axisBottom={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'group',
					legendPosition: 'middle',
					legendOffset: 32,
				}}
				axisLeft={{
					tickSize: 5,
					tickPadding: 5,
					tickRotation: 0,
					legend: 'value',
					legendPosition: 'middle',
					legendOffset: -40,
				}}
				colors={{ scheme: 'nivo' }}
				borderRadius={2}
				borderWidth={2}
				borderColor={{
					from: 'color',
					modifiers: [['darker', 0.3]],
				}}
				medianWidth={2}
				medianColor={{
					from: 'color',
					modifiers: [['darker', 0.3]],
				}}
				whiskerEndSize={0.6}
				whiskerColor={{
					from: 'color',
					modifiers: [['darker', 0.3]],
				}}
				motionConfig='stiff'
				// legends={[
				// 	{
				// 		anchor: 'top',
				// 		direction: 'column',
				// 		justify: false,
				// 		translateX: 100,
				// 		translateY: 0,
				// 		itemWidth: 60,
				// 		itemHeight: 20,
				// 		itemsSpacing: 3,
				// 		itemTextColor: '#999',
				// 		itemDirection: 'left-to-right',
				// 		symbolSize: 20,
				// 		symbolShape: 'square',
				// 		effects: [
				// 			{
				// 				on: 'hover',
				// 				style: {
				// 					itemTextColor: '#000',
				// 				},
				// 			},
				// 		],
				// 	},
				// ]}
			/>
		</Box>
	);
};

export default BoxPlot;
