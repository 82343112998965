import { v4 as uuidv4 } from 'uuid';

export const addAttribute = (key, type) => {
    try {
        const newAttribute = {
            uuid: uuidv4(),
            key: type === 'templateString' ? `{${key.toLowerCase()}}` : key,
            type: type,
            deleted: false,
            options: [],
        };
        return newAttribute;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const addOption = value => {
    try {
        const newOption = {
            uuid: uuidv4(),
            value,
        };
        return newOption;
    } catch (e) {
        console.log(e);
        return null;
    }
};

// lookup attribute by uuid
export const getAttributeById = (attributes, uuid) => {
    try {
        // return attribute.options.find(o => o.uuid === uuid) || {};
        return attributes.find(a => a.uuid === uuid) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// lookup attribute by key
export const getAttributeByKey = (attributes, key) => {
    try {
        return attributes.find(a => a.key === key) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// lookup attribute option by uuid
export const getOptionById = (attribute, uuid) => {
    try {
        return attribute.options.find(o => o.uuid === uuid) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// lookup attribute option from all attributes and all of their options (a flat array)
export const getOptionFromAllById = (attributes, uuid) => {
    try {
        return (attributes || []).flatMap(a => a.options).find(o => o.uuid === uuid) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// lookup attribute option from all attributes and all of their options (a flat array)
export const getOptionFromAllByValue = (attributes, value) => {
    try {
        return (attributes || []).flatMap(a => a.options).find(o => o.value === value) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// lookup attribute option from for a specific attribute and its options
export const getOptionByValue = (attribute, value) => {
    try {
        return attribute.options.find(o => o.value === value.toString().trim());
    } catch (e) {
        console.log(e);
        return null;
    }
};

// find attribute that an option belongs to
export const getAttributeByOptionId = (attributes, uuid) => {
    try {
        return attributes.find(a => a.options.find(o => o.uuid === uuid)) || {};
    } catch (e) {
        console.log(e);
        return {};
    }
};

// get attributes with type x
export const getAttributesByType = (attributes, type) => {
    try {
        return attributes.filter(a => a.type === type) || [];
    } catch (e) {
        console.log(e);
        return [];
    }
};
