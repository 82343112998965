import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React from 'react';
import Survey from './Survey';

const TabPanel = ({ children, value, current, ...other }) => (
	<Box
		role='tabpanel'
		hidden={value !== current}
		id={value}
		aria-labelledby={`vertical-tab-${value}`}
		width='100%'
		{...other}
	>
		{value === current && (
			<Box p={3}>
				<Typography component='div'>{children}</Typography>
			</Box>
		)}
	</Box>
);

const TabPanels = ({ selectedTab, handleRemoveSurvey, surveys, activeSurvey, globalConfig }) => {
	const [confirmDeleteSurvey, setConfirmDeleteSurvey] = React.useState(false);

	const ConfirmDeleteAttribute = () => {
		return (
			<Dialog open={!!confirmDeleteSurvey} onClose={() => setConfirmDeleteSurvey(false)}>
				<DialogTitle color='error'>
					<strong>Confirm</strong>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						Are you sure you want to proceed with deleting this attribute?
					</DialogContentText>
					<DialogContentText color='error'>
						{`Deleting Attribute: ${activeSurvey.key || confirmDeleteSurvey}`}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setConfirmDeleteSurvey(false)}>Cancel</Button>
					<Button
						onClick={() => {
							handleRemoveSurvey(activeSurvey);
							setConfirmDeleteSurvey(false);
						}}
					>
						Delete Attribute
					</Button>
				</DialogActions>
			</Dialog>
		);
	};

	console.log(activeSurvey);
	return (
		<>
			{confirmDeleteSurvey && <ConfirmDeleteAttribute open={confirmDeleteSurvey} />}
			{surveys &&
				surveys.length > 0 &&
				surveys.map(s => (
					<TabPanel key={s.uuid} value={s.uuid} current={selectedTab}>
						<Survey survey={s} globalConfig={globalConfig} />
					</TabPanel>
				))}
		</>
	);
};

export default TabPanels;
