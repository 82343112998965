import Box from '@mui/material/Box';
import React from 'react';
import NewSurvey from './NewSurvey';
import SurveyTabs from './SurveyTabs';
import TabPanels from './TabPanels';

const Surveys = ({ handleSurveysChange, customerManaged, globalConfig }) => {
	const [selectedTab, setSelectedTab] = React.useState('new');
	const [activeSurvey, setActiveSurvey] = React.useState({});
	const [surveys, setSurveys] = React.useState(null);
	const [globalSurveys, setGlobalSurveys] = React.useState(null);
	const [allSurveys, setAllSurveys] = React.useState(null);

	React.useEffect(() => {
		if (selectedTab === 'new') {
			setActiveSurvey({});
		} else {
			if (allSurveys) setActiveSurvey(allSurveys.find(survey => survey.uuid === selectedTab));
		}
	}, [selectedTab]);

	React.useEffect(() => {
		if (customerManaged) {
			setSurveys(customerManaged.surveys);
		}
	}, [customerManaged]);

	React.useEffect(() => {
		if (globalConfig) {
			setGlobalSurveys((globalConfig.global || {}).surveys);
		}
	}, [globalConfig]);

	React.useEffect(() => {
		if (surveys && globalSurveys) {
			setAllSurveys([...surveys, ...globalSurveys.map(gs => ({ ...gs, global: true }))]);
		}
	}, [surveys, globalSurveys]);

	// #region handlers
	const handleChangeTab = (_, newValue) => {
		setSelectedTab(newValue);
	};

	const handleAddSurvey = survey => {
		const updatedSurveys = [...surveys, survey];
		setSurveys(updatedSurveys);
		handleSurveysChange(updatedSurveys);
	};

	const handleRemoveSurvey = () => {
		console.log('not yet implemented');
	};
	const handleRestoreSurvey = () => {
		console.log('not yet implemented');
	};
	// #endregion

	// console.log(globalConfig);
	return (
		<Box sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex' }}>
			<SurveyTabs
				selectedTab={selectedTab}
				handleChangeTab={handleChangeTab}
				surveys={allSurveys}
			/>
			{selectedTab === 'new' && <NewSurvey handleAddSurvey={handleAddSurvey} />}

			<TabPanels
				selectedTab={selectedTab}
				activeSurvey={activeSurvey}
				surveys={allSurveys}
				globalConfig={globalConfig}
				handleRemoveSurvey={handleRemoveSurvey}
				handleRestoreSurvey={handleRestoreSurvey}
			/>
		</Box>
	);
};

export default Surveys;
