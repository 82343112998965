import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { convertCamelCaseToTitleCase } from '../../helpers/stringUtils';

const label = {
	display: 'flex',
	justifyContent: 'flex-end',
	alignItems: 'center',
	pr: 2,
};

const textField = {
	size: 'small',
	variant: 'outlined',
	multiline: true,
	minRows: 1,
};

const Tenant = ({ customerManaged, readOnly, onChange, tenantId }) => {
	return (
		<Grid container spacing={1} mt={2}>
			<Grid item xs={6}>
				{Object.keys(customerManaged).map(key => {
					return (
						<Grid key={key} item xs={12} display='flex' my={2}>
							<Grid item xs={3} sx={{ ...label }}>
								<Typography component='div'>
									{convertCamelCaseToTitleCase(key)}
								</Typography>
							</Grid>
							<Grid item xs={9}>
								<TextField
									name={key}
									label={convertCamelCaseToTitleCase(key)}
									value={
										Array.isArray(customerManaged[key])
											? customerManaged[key].length
											: customerManaged[key] || ''
									}
									onChange={onChange}
									{...textField}
									fullWidth
									disabled={Array.isArray(customerManaged[key])}
								/>
							</Grid>
						</Grid>
					);
				})}
			</Grid>
			<Grid item xs={6}>
				<Grid item xs={12} display='flex' my={2}>
					<Grid item xs={6} sx={{ ...label }}>
						<Typography component='div'>Tenant Id</Typography>
					</Grid>
					<Grid item xs={6}>
						<Typography component='div'>{tenantId || ''}</Typography>
					</Grid>
				</Grid>
				{Object.keys(readOnly)
					.filter(key => readOnly[key])
					.map(key => {
						return (
							<Grid key={key} item xs={12} display='flex' my={2}>
								<Grid item xs={6} sx={{ ...label }}>
									<Typography component='div'>
										{convertCamelCaseToTitleCase(key)}
									</Typography>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label={convertCamelCaseToTitleCase(key)}
										value={
											Array.isArray(readOnly[key])
												? readOnly[key].length
												: readOnly[key] || ''
										}
										{...textField}
										disabled
									/>
								</Grid>
							</Grid>
						);
					})}
			</Grid>
		</Grid>
	);
};

export default Tenant;
