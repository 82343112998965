import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const DummyAuth = ({ setForbid, tenantId }) => {
	const navigate = useNavigate();
	const [password, setPassword] = React.useState('');
	const [attempts, setAttempts] = React.useState(0);

	React.useEffect(() => {
		if (attempts > 2) {
			navigate('/');
		}
	}, [attempts, navigate]);

	const handleLoginAttempt = () => {
		const accept = tenantId.split('-')[0];
		if (password === accept) {
			setForbid(false);
		} else {
			setPassword('');
			setAttempts(attempts + 1);
		}
	};

	// a18a39fc-6cba-4454-acf4-17e4f14b38d4
	return (
		<Dialog open={true} maxWidth='xs' fullWidth>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Typography component='span' variant='h4' gutterBottom>
					Please Log In
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center', my: 1 }}>
				<TextField
					fullWidth
					id='password'
					label='Password'
					type='password'
					value={password}
					onChange={e => setPassword(e.target.value)}
					size='small'
					sx={{ my: 1 }}
					error={attempts > 0}
					helperText={
						attempts > 0
							? `Incorrect password. ${3 - attempts} ${pluralize(
									'attempt',
									3 - attempts
							  )} remaining`
							: ''
					}
				/>
			</DialogContent>
			<DialogActions sx={{ textAlign: 'center' }}>
				<Button variant='outlined' fullWidth onClick={() => setForbid(true)} size='small'>
					Cancel
				</Button>
				<Button
					variant='contained'
					fullWidth
					onClick={handleLoginAttempt}
					size='small'
					disabled={!tenantId || !password}
				>
					Log In
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default DummyAuth;
