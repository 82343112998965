import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { Box } from '@mui/material';

const AttributeTabs = ({ selectedTab, handleChangeTab, attributes, handleReorderAttributes }) => {
	const [dragId, setDragId] = React.useState();
	const [orderedAttributes, setOrderedAttributes] = React.useState(
		(attributes || []).map((a, i) => {
			// order by deleted, then order, then index
			return {
				...a,
				order: a.deleted ? 99999 : a.order !== undefined ? a.order : i + 1,
			};
		})
	);

	React.useEffect(() => {
		let cancelled = false;
		if (!cancelled) {
			handleChangeTab({
				...attributes,
				attributes: orderedAttributes.map(a => {
					return {
						...a,
						uuid: a.uuid,
						order: parseInt(a.order),
					};
				}),
			});
		}
		return () => (cancelled = true);
	}, [orderedAttributes, dragId]);

	const handleDrag = e => {
		setDragId(e.currentTarget.id);
		handleChangeTab(e.currentTarget.id);
	};

	const handleDrop = e => {
		const dragBox = orderedAttributes.find(a => a.uuid === dragId);
		const dropBox = orderedAttributes.find(a => a.uuid === e.currentTarget.id);

		const dragBoxOrder = dragBox.order;
		const dropBoxOrder = dropBox.order;

		const newBoxState = orderedAttributes.map(a => {
			if (a.uuid === dragId) {
				a.order = dropBoxOrder;
			}
			if (a.uuid === e.currentTarget.id) {
				a.order = dragBoxOrder;
			}
			return a;
		});

		setOrderedAttributes(newBoxState);
		handleReorderAttributes(newBoxState);
	};

	return (
		<Tabs
			orientation='vertical'
			value={selectedTab || 'new'}
			onChange={handleChangeTab}
			variant='scrollable'
			scrollButtons
			allowScrollButtonsMobile
			aria-label='scrollable auto tabs example'
			sx={{
				borderRight: 1,
				borderColor: 'divider',
				height: '50rem',
				width: '12rem',
				wordWrap: 'break-word',
			}}
		>
			<Tab
				sx={{ alignItems: 'flex-end' }}
				label={`${attributes.length > 0 ? 'Add New' : 'Add Your First Attribute!'}`}
				value='new'
				key='new'
			/>
			<Tab
				sx={{ alignItems: 'flex-end' }}
				label={`${attributes.length > 0 ? 'Add Link' : 'Link Your First Attribute!'}`}
				value='link'
				key='link'
			/>
			<Divider />
			{(orderedAttributes || [])
				.sort((a, b) => a.order - b.order)
				.map((a, i) => (
					<Tab
						value={a.uuid}
						key={a.uuid}
						label={
							a.deleted ? (
								<Typography
									variant='subtitle2'
									sx={{ textDecorationLine: 'line-through' }}
								>
									{a.key || 'N/A'}
								</Typography>
							) : (
								<div
									draggable={true}
									id={`${a.uuid}`}
									key={`${a.uuid}`}
									onDragOver={e => e.preventDefault()}
									onDragStart={handleDrag}
									onDrop={handleDrop}
								>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant='subtitle2'>
											{a.key || 'N/A'}
										</Typography>
										<DragHandleIcon />
									</Box>
								</div>
							)
						}
						sx={{ alignItems: 'flex-end' }}
					/>
				))}
		</Tabs>
	);
};

export default AttributeTabs;
