import { getAttributeByOptionId, getAttributesByType, getOptionById, getOptionFromAllById } from './attributUtils';
import { convertTitleCaseToCamelCase, scrubBrackets } from './stringUtils';
import { findNode, getHierarchyFromId } from './treeUtils';

// try to locate any tag links
export const findTagLinks = (tag, attributes, hierarchies) => {
    try {
        if (tag.hierarchyId) {
            const hierarchy = getHierarchyFromId(hierarchies, tag.hierarchyId);
            const node = findNode(hierarchy.nodes, tag.value);
            const option = getOptionFromAllById(attributes, node.attributeOptionId);
            if (option && option.links) {
                let linkedTags = option.links.map((l, i) => {
                    const link = getOptionFromAllById(attributes, l);
                    // find the attribute that this link belongs to
                    const linkedAttribute = getAttributeByOptionId(attributes, l);
                    const linkedTag = {
                        attributeId: linkedAttribute.uuid,
                        hierarchyId: null,
                        key: linkedAttribute.key || '',
                        search: `${linkedAttribute.key}: ${link.value}`,
                        value: link.uuid,
                        label: link.value,
                        order: (linkedAttribute.order || i) + 1,
                    };
                    return linkedTag;
                });
                // console.log(linkedTags);
                return linkedTags;
            }
        } else if (tag.attributeId) {
            const option = getOptionFromAllById(attributes, tag.value);
            if (option && option.links) {
                let linkedTags = option.links.map(l => {
                    const link = getOptionFromAllById(attributes, l);
                    // find the attribute that this link belongs to
                    const linkedAttribute = getAttributeByOptionId(attributes, l);
                    const linkedTag = {
                        attributeId: linkedAttribute.uuid,
                        key: linkedAttribute.key || '',
                        search: `${linkedAttribute.key}: ${link.value}`,
                        value: link.uuid,
                        label: link.value,
                    };
                    return linkedTag;
                });
                // console.log(linkedTags);
                return linkedTags;
            }
        } else {
            // console.log(tag)
            return [];
        }
        return [];
    } catch (e) {
        console.log(e);
        return [];
    }
};

export const generateTemplateStringMap = (tags, attributes, hierarchies) => {
    try {
        // find any attributes of type 'templateString'
        const templateStrings = getAttributesByType(attributes, 'templateString');
        var tsMap = [];
        tags.forEach(tag => {
            if (tag.attributeId) {
                // similar process as below but without the hierarchy lookup
                const attribute = attributes.find(a => a.uuid === tag.attributeId);
                if (!attribute) return;
                // find attribute option
                const attributeOption = getOptionById(attribute, tag.value);
                if (!attributeOption) return;
                // check if attribute key is in templateStrings
                const attributeTS = templateStrings.find(ts => ts.key === attribute.key);
                if (!attributeTS) return;
                // add to tsMap
                tsMap.push({
                    tsKey: attributeTS.key,
                    tsValue: attributeOption.value,
                });
            } else if (tag.hierarchyId) {
                // find attribute associated with hierarchy
                const hierarchy = hierarchies.find(h => h.uuid === tag.hierarchyId);
                if (!hierarchy) return;
                const attribute = attributes.find(a => a.uuid === hierarchy.linkedAttributeKeyId);
                if (!attribute) return;
                // check if attribute key is in templateStrings
                const hierarchyAttributeTS = templateStrings.find(ts => ts.key === attribute.key);
                if (!hierarchyAttributeTS) return;
                // find node in hierarchy
                const node = findNode(hierarchy.nodes, tag.value);
                if (!node) return;
                // add to tsMap
                tsMap.push({
                    tsKey: hierarchyAttributeTS.key,
                    tsValue: node.value,
                });
            }
            return tag;
        });
        return tsMap;
    } catch (e) {
        console.log(e);
        return [];
    }
};

// find the human readable tags, return as key/value pairs in an object
export const findTagValues = (tags, attributes) => {
    try {
        // refactor to create an object instead of an array
        const tagValues = tags.reduce((acc, tag) => {
            const option = getOptionFromAllById(attributes, tag.value);
            if (!option) return acc;
            const attribute = attributes.find(a => a.uuid === tag.attributeId);
            if (!attribute) return acc;
            acc[convertTitleCaseToCamelCase(scrubBrackets(attribute.key || ''))] = option.value;
            return acc;
        }, {});
        return tagValues;
        // const tagValues = tags
        // 	.map(tag => {
        // 		const option = getOptionFromAllById(attributes, tag.value);
        // 		if (!option) return;
        // 		const attribute = attributes.find(a => a.uuid === tag.attributeId);
        // 		if (!attribute) return;
        // 		const tagValue = {
        // 			[convertTitleCaseToCamelCase(scrubBrackets(attribute?.key || ''))]:
        // 				option.value,
        // 		};
        // 		return tagValue;
        // 	})
        // 	.filter(t => t);
        // return tagValues;
    } catch (e) {
        console.log(e);
        return [];
    }
};
