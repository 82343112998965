import styled from '@emotion/styled';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeft from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRight from '@mui/icons-material/KeyboardDoubleArrowRight';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Hidden from '@mui/material/Hidden';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const StyledLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	width: '70%',
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
	},
}));

export default function Stepper(props) {
	const theme = useTheme();

	const {
		currentIndex,
		questionCount,
		handleNext,
		handleBack,
		handleJumpToLast,
		handleJumpToFirst,
	} = props;

	const start = currentIndex === -1;
	const end = currentIndex === questionCount;
	const progress = (currentIndex / questionCount) * 100;

	// TODO: change progess bar to be a percentage of the completed required questions
	return (
		<>
			<Box display='flex' alignItems='center' justifyContent='center'>
				<Button onClick={handleJumpToFirst} disabled={start}>
					{theme.direction === 'rtl' ? (
						<KeyboardDoubleArrowRight />
					) : (
						<KeyboardDoubleArrowLeft />
					)}
					<Hidden smDown>First</Hidden>
				</Button>
				<Button onClick={handleBack} disabled={start}>
					{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
					<Hidden smDown>Back</Hidden>
				</Button>

				<Hidden smDown>
					<StyledLinearProgress variant='determinate' value={start ? 0 : progress} />
				</Hidden>

				<Button onClick={handleNext} disabled={end}>
					<Hidden smDown>{start ? 'Begin' : 'Next'}</Hidden>
					{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
				</Button>
				<Button onClick={handleJumpToLast} disabled={end || start}>
					<Hidden smDown>Last</Hidden>
					{theme.direction === 'rtl' ? (
						<KeyboardDoubleArrowLeft />
					) : (
						<KeyboardDoubleArrowRight />
					)}
				</Button>
			</Box>

			<Box sx={{ minWidth: 35 }}>
				<Typography variant='body2' color='text.secondary'>{`${Math.round(
					start ? 0 : progress
				)}%`}</Typography>
			</Box>
		</>
	);
}
