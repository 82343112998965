import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import React from 'react';

const DumbSaving = ({ text }) => {
	const message = text || 'Saving...';

	return (
		<Dialog open={true} maxWidth='xs' fullWidth>
			<DialogTitle sx={{ textAlign: 'center' }}>
				<Typography variant='h4' gutterBottom>
					{message}
				</Typography>
			</DialogTitle>
			<DialogContent sx={{ textAlign: 'center' }}>
				<CircularProgress variant='indeterminate' />
			</DialogContent>
		</Dialog>
	);
};

export default DumbSaving;
