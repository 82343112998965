import { S3, SecretsManager } from 'aws-sdk';

const ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

export const getS3File = async key => {
	try {
		const s3 = new S3({
			accessKeyId: ACCESS_KEY_ID,
			secretAccessKey: SECRET_ACCESS_KEY,
			region: 'us-west-2',
		});

		const params = {
			Bucket: 'my-bucket',
			Key: key,
		};
		const response = await s3.getObject(params).promise();
		return response;
	} catch (error) {
		console.log(error);
		return error;
	}
};

export const getSecret = async secretName => {
	try {
		const client = new SecretsManager({
			accessKeyId: ACCESS_KEY_ID,
			secretAccessKey: SECRET_ACCESS_KEY,
			region: 'us-west-2',
		});

		const response = await client.getSecretValue({ SecretId: secretName }).promise();

		const secret = JSON.parse(response.SecretString);
		return secret;
	} catch (error) {
		console.log(error);
		return error;
	}
};
