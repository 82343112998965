import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme();
// set easings for transitions slower
theme.transitions.easing = {
	easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
	easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
	easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
	sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
};

// 62.5% trick override for Typeography
theme.typography.htmlFontSize = '62.5%';
theme.typography.fontSize = '1.6rem';

theme.palette = {
	...theme.palette,
	mode: 'light',
	primary: {
		main: '#4d6aa2',
		dark: '#2f3640',
		light: '#a4b0be',
		contrastText: '#fff',
	},
	secondary: {
		main: '#2de7ef',
		dark: '#2f3640',
		light: '#a4b0be',
		contrastText: '#fff',
	},
};

// hide vertical scrollbars always (not working, not sure how to implement)
theme.overrides = {
	...theme.overrides,
	MuiCssBaseline: {
		'@global': {
			'*::-webkit-scrollbar': {
				width: '0.4em',
			},
			'*::-webkit-scrollbar-track': {
				'-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
			},
			'*::-webkit-scrollbar-thumb': {
				backgroundColor: 'rgba(0,0,0,.1)',
				outline: '1px solid slategrey',
			},
		},
	},
};

theme = responsiveFontSizes(theme, {
	factor: 2,
	breakpoints: ['sm', 'md', 'lg', 'xl'],
	disableAlign: false,
});

export default theme;
