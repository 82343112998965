import { Autocomplete, Button, IconButton, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import dayjs from 'dayjs';
import React from 'react';
import { depth } from '../../../helpers/treeUtils';
import OrgHierarchyWrapper from '../wrappers/OrgHierarchyWrapper';
import AttributeWrapper from '../wrappers/AttributeWrapper';
import { useReactToPrint } from 'react-to-print';
import { Print } from '@mui/icons-material';
import MarimekkoWrapper from '../wrappers/MarimekkoWrapper';
import YoYWrapper from '../wrappers/YoYWrapper';
import { getAttributeByKey, getOptionFromAllById } from '../../../helpers/attributUtils';
import { findDistinctTags } from '../../../helpers/surveyUtils';

const initConfig = {
	defs: [],
	fill: [],
	id: 'org',
	value: 'participation',
	// margins: { mt: 0, mr: 0, mb: 0, ml: 0 },
	// legends: [
	// 	{
	// 		anchor: 'top',
	// 		direction: 'row',
	// 		justify: false,
	// 		translateX: 0,
	// 		translateY: 0,
	// 		itemsSpacing: 0,
	// 		itemWidth: 140,
	// 		itemHeight: 18,
	// 		itemTextColor: '#999',
	// 		itemDirection: 'right-to-left',
	// 		itemOpacity: 1,
	// 		symbolSize: 18,
	// 		symbolShape: 'square',
	// 		effects: [
	// 			{
	// 				on: 'hover',
	// 				style: {
	// 					itemTextColor: '#000',
	// 				},
	// 			},
	// 		],
	// 	},
	// ],
};
const OrgRollup = ({
	globalSurveyId,
	data,
	historicalData,
	surveys,
	attributes,
	hierarchies,
	config: eConfig,
}) => {
	const [eData, setEData] = React.useState({});
	const [surveyHistory, setSurveyHistory] = React.useState([]);
	const [bumpChartCfg, setBumpChartCfg] = React.useState({});
	const [years, setYears] = React.useState([]);
	const [org, setOrg] = React.useState([]);
	const [filters, setFilters] = React.useState({
		surveyId: null,
		years: [dayjs().year()],
		org: null,
		hierarchy: 'org',
	});
	const [visibility, setVisibility] = React.useState({
		showYoY: true,
		showByOrg: true,
		showByShift: true,
		showBySite: true,
		showBySiteAndShift: true,
		showByVS: true,
		showByVSShift: true,
		showByDept: true,
		showByDeptShift: true,
	});
	const printRef = React.useRef();

	React.useEffect(() => {
		if (surveys && data) {
			const eSurveyId = surveys.find(
				survey => survey.metadata.name === 'Employee Engagement Survey'
			).uuid;
			const filteredData = data[eSurveyId] || [];
			setEData(filteredData);
			setSurveyHistory(historicalData?.filter(d => d.surveyId === globalSurveyId) || []);
			setFilters({ ...filters, surveyId: eSurveyId });
		}
	}, []);

	React.useEffect(() => {
		if (eData && eData.year) {
			const years = Object.keys(eData.year).map(year => parseInt(year));
			setYears(years);
		}
	}, [eData]);

	// this is a bit different from leaders, we just need the whole org hierarchy
	React.useEffect(() => {
		if (eData && eData.organization) {
			const orgHierarchy = hierarchies?.find(o => o.name === 'Org.');
			if (!orgHierarchy) return;
			const org = orgHierarchy?.nodes
				?.sort((a, z) => a.left - z.left)
				.map(node => {
					return {
						value: node.uuid,
						label: node.value,
						nodeOptionId: node.attributeOptionId,
						depth: depth(orgHierarchy.nodes, node),
					};
				});

			const filtered = org.filter(org => org !== null); // remove nulls (must be in a hierarchy to be included)
			setOrg(filtered);
		}
	}, [eData]);

	React.useEffect(() => {
		const shiftAttributeId = getAttributeByKey(attributes, 'Shift')?.uuid;
		const orgAttributeId = getAttributeByKey(attributes, 'Organization')?.uuid;
		const orgTagIds = findDistinctTags(eData.organization?.[filters?.org?.value] ?? []);
		const orgTags = orgTagIds.map(id => getOptionFromAllById(attributes, id)?.value);
		const bumpChartActiveSerie = orgTags.filter(Boolean);

		setBumpChartCfg({
			shiftAttributeId,
			orgAttributeId,
			bumpChartActiveSerie: [...bumpChartActiveSerie, '*'],
		});
	}, [filters]);

	const lookupData = {
		surveys,
		attributes,
		hierarchies,
	};

	const ToggleVisibilityButton = ({ visibilityKey, label }) => (
		<Button
			key={visibilityKey}
			onClick={() =>
				setVisibility({
					...visibility,
					[visibilityKey]: !visibility[visibilityKey],
				})
			}
			variant='outlined'
			fullWidth
			size='small'
			sx={{ mt: 7, displayPrint: 'none' }}
		>
			{visibility[visibilityKey] ? `Hide ${label}` : `View ${label}`}
		</Button>
	);

	// handle printing
	const handlePrint = useReactToPrint({
		content: () => printRef.current,
	});

	const {
		showYoY,
		showByOrg,
		showByShift,
		showBySite,
		showBySiteAndShift,
		showByVS,
		showByVSShift,
		showByDept,
		showByDeptShift,
	} = visibility;

	const config = {
		...initConfig,
		...eConfig,
		dimensions: eConfig?.thresholdGroups.map(group => {
			return {
				id: group.label,
				value: group.id,
			};
		}),
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={2}></Grid>
			<Grid item xs={4}>
				<Autocomplete
					multiple
					options={years}
					value={filters.years}
					getOptionLabel={option => option}
					renderInput={params => <TextField {...params} label='Year(s)' />}
					onChange={(event, value) => setFilters({ ...filters, years: value })}
					size='small'
				/>
			</Grid>
			<Grid item xs={4}>
				<Autocomplete
					options={org}
					value={filters.org}
					getOptionLabel={option => {
						return '_'.repeat(option.depth) + option.label;
					}}
					renderInput={params => <TextField {...params} label='Org' />}
					onChange={(event, value) => setFilters({ ...filters, org: value })}
					size='small'
				/>
			</Grid>
			<Grid item xs={2}>
				<IconButton onClick={handlePrint} sx={{ displayPrint: 'none' }}>
					<Print />
				</IconButton>
			</Grid>
			<Grid container ref={printRef} m={3}>
				<Grid item xs={12} id='header'>
					<Typography variant='h4' align='center'>
						Organizational Engagement Rollup
					</Typography>
					<Typography variant='h6' align='center'>
						{filters.org ? filters.org.label : 'None Selected'}
					</Typography>
				</Grid>
				{filters.org && filters.surveyId && (
					<>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakAfter: showYoY ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showYoY'
								label='Year Over Year History'
							/>
							{showYoY && surveyHistory && (
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d => d.tagAttributeId === '*'
													)}
													lookupData={lookupData}
													label='Site History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d =>
															d.tagAttributeId ===
															bumpChartCfg?.shiftAttributeId
													)}
													lookupData={lookupData}
													label='Shift History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={6}>
										<Grid container spacing={2}>
											<Grid item xs={12}>
												<YoYWrapper
													data={surveyHistory.filter(
														d =>
															d.tagAttributeId ===
															bumpChartCfg?.orgAttributeId
													)}
													lookupData={lookupData}
													label='Department History'
													activeSeries={
														bumpChartCfg?.bumpChartActiveSerie
													}
												/>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							)}
						</Grid>

						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showByOrg'
								label='Engagement Average by Org'
							/>
							{showByOrg && (
								<OrgHierarchyWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									aggregate={true}
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showByShift'
								label='Engagement Average by Shift'
							/>
							{showByShift && (
								<AttributeWrapper
									data={eData['shift'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
								/>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showByDept ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showBySite'
								label='Engagement by Site'
							/>
							{showBySite && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={0} // this will determine which level to agg by
									alwaysShowLabels={true} // this will automatically show the levels label (E.g. always want to show site level to everyone)
									title='Engagement by Site Participation'
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showBySiteAndShift'
								label='Engagement by Site & Shift Participation'
							/>
							{showBySiteAndShift && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={0} // this will determine which level to agg
									alwaysShowLabels={true} // this will automatically show the levels label (E.g. always want to show site level to everyone)
									subgroup={true}
									title='Engagement by Site & Shift Participation'
								/>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showByVS ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showByVS'
								label='Engagement by Value Stream Participation'
							/>
							{showByVS && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={1} // this will determine which level to agg
									alwaysShowLabels={true} // this will automatically show the levels label (E.g. always want to show site level to everyone)
									title='Engagement by Value Stream Participation'
								/>
							)}
						</Grid>
						<Grid item xs={12}>
							<ToggleVisibilityButton
								visibilityKey='showByVSShift'
								label='Engagement by Value Stream & Shift Participation'
							/>
							{showByVSShift && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={1} // this will determine which level to agg
									alwaysShowLabels={true} // this will automatically show the levels label (E.g. always want to show site level to everyone)
									subgroup={true}
									title='Engagement by Value Stream & Shift Participation'
								/>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showByDept ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showByDept'
								label='Engagement by Department Participation'
							/>
							{showByDept && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={2} // this will determine which level to agg
									title='Engagement by Department Participation'
								/>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							sx={{
								pageBreakBefore: showByDept ? 'always' : 'auto',
							}}
						>
							<ToggleVisibilityButton
								visibilityKey='showByDeptShift'
								label='Engagement by Department & Shift Participation'
							/>
							{showByDeptShift && (
								<MarimekkoWrapper
									data={eData['organization'] || []}
									filters={filters}
									lookupData={lookupData}
									config={config}
									maxDepth={2} // this will determine which level to agg
									subgroup={true}
									title='Engagement by Department & Shift Participation'
								/>
							)}
						</Grid>
					</>
				)}
			</Grid>
		</Grid>
	);
};

export default OrgRollup;
