import React from 'react';
import { avgByCompetency } from '../../../helpers/surveyUtils';
import { Box, Container, Typography } from '@mui/material';
import MyBar from '../generics/Bar';
import { colorSchemes } from '@nivo/colors';

const overrides = {
	colors: { scheme: 'nivo' },
	minValue: 0,
	maxValue: 5,
	margin: { top: 60, bottom: 100, left: 60 },
	labelSkipWidth: 14,
	axisLeft: {
		tickSize: 1,
		tickPadding: 5,
		tickRotation: 0,
		// legend: 'Competency',
		// legendPosition: 'middle',
		// legendOffset: 32,
	},
	axisBottom: {
		tickSize: 5,
		tickPadding: 3,
		tickRotation: -30,
	},
	borderColor: {
		from: 'color',
		modifiers: [['darker', 1.6]],
	},
	legends: [
		{
			dataFrom: 'keys',
			anchor: 'top',
			direction: 'row',
			justify: false,
			translateX: 0,
			translateY: -20,
			itemsSpacing: 2,
			itemWidth: 100,
			itemHeight: 20,
			itemDirection: 'left-to-right',
			itemOpacity: 0.85,
			symbolSize: 20,
			effects: [
				{
					on: 'hover',
					style: {
						itemOpacity: 1,
					},
				},
			],
		},
	],
};

const initConfig = {
	keys: ['Leader', 'Site'], // keys to group by
	defs: [
		{
			id: 'dots',
			type: 'patternDots',
			background: 'inherit',
			color: 'inherit',
			size: 4,
			padding: 1,
			stagger: true,
		},
		{
			id: 'lines',
			type: 'patternLines',
			background: 'inherit',
			color: 'inherit',
			rotation: -45,
			lineWidth: 6,
			spacing: 10,
		},
	],
	fill: [
		{
			match: {
				id: 'Leader',
			},
			id: 'dots',
		},
		{
			match: {
				id: 'Site',
			},
			id: 'lines',
		},
	],
	indexBy: 'Competency',
	groupMode: 'grouped', // 'stacked' or 'grouped'
};

const Competencies = ({ data, filters, lookupData }) => {
	const [formattedData, setFormattedData] = React.useState([]);
	const [config, setConfig] = React.useState(initConfig);

	const colorScheme = colorSchemes.nivo;
	// console.log(colorScheme);
	React.useEffect(() => {
		const { surveys, attributes, hierarchies } = lookupData;
		const survey = surveys.find(survey => survey.uuid === filters.surveyId);
		const { competencies } = survey || {};
		if (competencies) {
			const selectedLeader = data.leader[filters.leader.value];
			const groupedByCompetency = avgByCompetency(selectedLeader, competencies);
			const plantCompetencies = avgByCompetency(data.year['2023'], competencies);
			const colors = colorScheme.flatMap(color => color);
			const colorCount = colors.length;

			var bd = [];
			competencies.forEach((c, i) => {
				const key = c.name;
				const leaderAvg = groupedByCompetency.find(gc => gc.name === c.name);
				const siteAvg = plantCompetencies.find(gc => gc.name === c.name);
				const colorIndex = i % colorCount; // wrap index to handle cases when there are more competencies than colors

				if (!isNaN(leaderAvg.avg) && !isNaN(siteAvg.avg)) {
					bd.push({
						Competency: key,
						Leader: isNaN(leaderAvg.avg) ? 0 : leaderAvg.avg.toFixed(1),
						LeaderColor: colorScheme[colorIndex],
						Site: isNaN(siteAvg.avg) ? 0 : siteAvg.avg.toFixed(1),
						SiteColor: colorScheme[colorIndex],
					});
				}
			});

			setConfig({ ...config });
			setFormattedData(bd);
		}

		return () => console.log('Competencies unmounted');
	}, [data, filters, lookupData]);

	// console.log(formattedData);
	// console.log(config);
	return (
		<Container maxWidth={false} disableGutters>
			<Typography variant='h6' align='center' py={2}>
				Competencies
			</Typography>

			{formattedData.length > 0 && (
				<Box height={'90%'} width={'100%'}>
					<MyBar data={formattedData} config={config} overrides={overrides} />
				</Box>
			)}
		</Container>
	);
};

export default Competencies;
