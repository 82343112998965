// simple landing page component that renders a textbox and button to start the survey
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { useFormik } from 'formik';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { callApiGWLambdaGet } from '../../apis/api-gw';
import { SharedContext } from '../../contexts/SharedContext';

const validationSchema = yup.object({
    code: yup
        .string()
        .required('Please enter a valid code')
        .length(8, 'Code must be 8 characters long')
        .test({
            name: 'code-api-validation',
            skipAbsent: true,
            async test(value, context) {
                if (value && value.length === 8) {
                    const result = await callApiGWLambdaGet(`codes/validate/${value}`);
                    if (result.statusCode === 200) {
                        return true;
                    } else {
                        return context.createError({ message: result.body || result.errorMessage });
                    }
                }
                return true;
            },
        }),
});

const TakeSurvey = () => {
    const context = useContext(SharedContext);
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            code: '',
        },
        validationSchema: validationSchema,
        handleChange: e => {
            formik.setFieldValue(e.target.name, e.target.value);
        },
        onSubmit: values => {
            navigate(`/survey/${values.code}`);
        },
    });

    React.useEffect(() => {
        // clear the code in session storage on page load
        sessionStorage.clear();
    }, []);

    const { shared } = context || {};
    const { tenant } = shared || {};
    const { customerManaged } = tenant || {};

    //Y6H5D5M8
    return (
        <Box display='flex' flexDirection='column' justifyContent='space-evenly'>
            <Box display='flex' justifyContent='center' flex='1 0 33vh'>
                {customerManaged ? (
                    <Box height='10rem' width='30rem' mt='3rem' display='flex' justifyContent='center'>
                        <img
                            src={(customerManaged || {}).logoUrl}
                            style={{ maxHeight: '100%', maxWidth: '100%' }}
                            alt='logo'
                        />
                    </Box>
                ) : (
                    <img src='https://via.placeholder.com/150' alt='logo' />
                )}
            </Box>
            <Box display='flex' justifyContent='center' flex='1 0 33vh'>
                <Dialog open={true}>
                    <DialogTitle sx={{ textAlign: 'center' }}>
                        <Typography element='span' variant='h4' gutterBottom>
                            Welcome to Survently!
                        </Typography>
                        <Typography variant='h5'>Please enter the code that was provided to you below</Typography>
                    </DialogTitle>
                    <DialogContent sx={{ textAlign: 'center' }}>
                        <Box
                            component='form'
                            sx={{
                                '& .MuiTextField-root': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete='off'
                        >
                            <TextField
                                fullWidth
                                id='code'
                                name='code'
                                label='Survey Code'
                                type='code'
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors.code)}
                                helperText={formik.errors.code}
                                disabled={formik.isSubmitting}
                                size='small'
								inputProps={{ style: { textTransform: 'uppercase' } }}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center' }}>
                        <Button
                            color='primary'
                            variant='contained'
                            fullWidth
                            onClick={formik.handleSubmit}
                            disabled={formik.isSubmitting || formik.errors.code || !formik.isValid}
                        >
                            Begin Survey
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default TakeSurvey;
