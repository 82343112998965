import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {
	Grid,
	Table,
	TableHeaderRow,
	TableColumnResizing,
} from '@devexpress/dx-react-grid-material-ui';
import Remove from '@mui/icons-material/Remove';
import Box from '@mui/material/Box';
import React from 'react';
import { getOptionByValue } from '../../../helpers/attributUtils';
import { scrubBrackets } from '../../../helpers/stringUtils';

const QRColorFormatter = ({ value }) => {
	const qrGenUrl = `https://api.qrserver.com/v1/create-qr-code/?color=${(
		value.color || '#000000'
	).replace('#', '')}&bgcolor=${(value.bgColor || '#FFFFFF').replace(
		'#',
		''
	)}&margin=3&size=75x75&data=sample`;
	return <img src={qrGenUrl} alt='QR Code' />;
};

const QRColorProvider = props => (
	<DataTypeProvider formatterComponent={QRColorFormatter} {...props} />
);

const TagFormatter = ({ value }) => {
	return (
		<Box>
			{value.map((tag, i) => (
				<Box
					key={`${tag.key}-${tag.value}-${i}`}
					sx={{
						fontWeight: tag.newAttribute ? 'bold' : 'normal',
						color: tag.newAttribute ? 'info.main' : 'black',
					}}
				>
					{scrubBrackets(tag.key)}:{' '}
					<Box
						component='span'
						sx={{
							fontWeight: tag.newOption ? 'bold' : 'normal',
							color: tag.newOption ? 'info.main' : 'black',
						}}
					>
						{tag.value}
					</Box>
				</Box>
			))}
		</Box>
	);
};

const TagProvider = props => <DataTypeProvider formatterComponent={TagFormatter} {...props} />;

const SurveyNameFormatter = ({ value }) => {
	return (
		<Box
			sx={{
				// borderColor: value.error ? 'error.main' : 'black',
				// borderWidth: value.error ? '2px' : '0px',
				// borderStyle: 'solid',
				color: value.error ? 'error.main' : 'black',
				fontWeight: value.error ? 'bold' : 'normal',
			}}
		>
			{value.name || 'No Survey Found'}
		</Box>
	);
};

const SurveyNameProvider = props => (
	<DataTypeProvider formatterComponent={SurveyNameFormatter} {...props} />
);

const CustomRow = ({ row, ...restProps }) => <Table.Row {...restProps} />;

const ImportTable = ({ data, errors, handleRemoveRow, attributes, surveys }) => {
	const [columns] = React.useState([
		{ name: 'remove', title: 'Remove' },
		{ name: 'survey', title: 'Survey' },
		{ name: 'tags', title: 'Tags' },
		{ name: 'qrSample', title: 'QR Sample' },
		{ name: 'numberOfCodes', title: '# of Codes' },
	]);

	const [columnWidths] = React.useState([
		{ columnName: 'remove', width: 100 },
		{ columnName: 'survey', width: 200 },
		{ columnName: 'tags', width: 400 },
		{ columnName: 'qrSample', width: 100 },
		{ columnName: 'numberOfCodes', width: 100 },
	]);
	const [rows, setRows] = React.useState([]);

	// set rows
	React.useEffect(() => {
		if (data) {
			const rows = data.map(code => {
				const tags = code.tags.map(tag => {
					const attribute = attributes.find(attribute => attribute.key === tag.key);
					const option = attribute && getOptionByValue(attribute, tag.value);
					return {
						...tag,
						newAttribute: !attribute,
						newOption: !option,
					};
				});

				const foundSurvey = surveys.find(
					survey => survey.metadata.name === code.surveyName
				);
				return {
					remove: (
						<Remove
							onClick={() => handleRemoveRow(code.rowId)}
							sx={{ cursor: 'pointer' }}
						/>
					),
					survey: {
						uuid: foundSurvey ? foundSurvey.uuid : null,
						name: foundSurvey ? foundSurvey.metadata.name : code.surveyName,
						error: !foundSurvey,
					},
					qrSample: {
						bgColor: code.bgColor,
						color: code.color,
					},
					numberOfCodes: code.numberOfCodes,
					tags: tags,
				};
			});
			setRows(rows);
		}

		return () => {
			setRows([]);
		};
	}, [data]);

	return (
		<>
			{rows && rows.length > 0 && (
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: 'info.main',
						fontWeight: 'bold',
					}}
				>
					Tags in this color indicate that they're new and will be created during import.
				</Box>
			)}
			<Grid rows={rows} columns={columns}>
				<SurveyNameProvider for={['survey']} />
				<QRColorProvider for={['qrSample']} />
				<TagProvider for={['tags']} />
				<Table rowComponent={CustomRow} />
				<TableColumnResizing columnWidths={columnWidths || []} />
				<TableHeaderRow />
			</Grid>
		</>
	);
};

export default ImportTable;
